import React from 'react';
import { Badge, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';

import styles from './CustomMenuItem.module.scss';
import { useNewLeadsSubscription } from '../../hooks/useNewLeadsSubscription';

export type BaseMenuItemType = {
	id: string;
	label?: string;
	icon?: JSX.Element;
	isVisible?: boolean;
	isDisabled?: boolean;
	isActive?: boolean;
	className?: string;
};

export type CustomMenuLinkItemType = BaseMenuItemType & {
	href: string;
};

export type CustomMenuButtonItemType = BaseMenuItemType & {
	onClick?: ( event?: React.MouseEvent<HTMLElement> ) => void;
};

export type CustomMenuItemType =
	| CustomMenuLinkItemType
	| CustomMenuButtonItemType;

const LinkWrap = ( {
	href,
	children,
}: Partial<Pick<CustomMenuLinkItemType, 'href'>> & {
	children: JSX.Element;
} ) => {
	return href ? (
		<Link href={ href } className={ styles.linkWrap }>
			{ children }
		</Link>
	) : (
		children
	);
};

export default function CustomMenuItemComponent( {
	menuItem: {
		isDisabled,
		isActive,
		label,
		icon,
		isVisible,
		id,
		className,
		...rest
	},
}: {
	menuItem: CustomMenuItemType;
} ) {
	const { badgeContent } = useNewLeadsSubscription( id !== 'inquiries' );

	if ( isVisible !== undefined && !isVisible ) return null;
	if ( isDisabled !== undefined && isDisabled ) return null;

	return (
		<LinkWrap href={ 'href' in rest ? rest.href : undefined }>
			<MenuItem
				data-cy={ `${ id }MenuItem` }
				onClick={ 'onClick' in rest ? rest.onClick : undefined }
				className={ classNames( styles.menuItem, className, {
					[ styles.menuItemActive ]: isActive,
				} ) }
			>
				<Badge
					badgeContent={ badgeContent }
					color='primary'
					className={ styles.badge }
					anchorOrigin={ { vertical: 'top', horizontal: 'left' } }
				>
					{ icon && <ListItemIcon>{ icon }</ListItemIcon> }
					<ListItemText>{ label }</ListItemText>
				</Badge>
			</MenuItem>
		</LinkWrap>
	);
}
