import { Country } from './customEnums';
import { State } from './customEnums';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  DateTime: { input: Date | string; output: Date | string; }
  /** Decimal custom scalar type */
  Decimal: { input: number; output: number; }
  Json: { input: any; output: any; }
  /** Long (long integer) variables are stored as signed 32-bit (4-byte) numbers ranging in value from -2,147,483,648 to 2,147,483,647. */
  Long: { input: number; output: number; }
};

export type AccountCapabilities = {
  __typename?: 'AccountCapabilities';
  card_issuing?: Maybe<Scalars['Boolean']['output']>;
  card_payments?: Maybe<Scalars['Boolean']['output']>;
  legacy_payments?: Maybe<Scalars['Boolean']['output']>;
  transfers?: Maybe<Scalars['Boolean']['output']>;
};

export type ActingUserForEvent = {
  __typename?: 'ActingUserForEvent';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  orgUser?: Maybe<OrgUserForActingUserOnEvent>;
};

export type ActivateContactCustomInput = {
  sendEmails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<State>;
};

export type AdminUsersForGetContractWhere = {
  __typename?: 'AdminUsersForGetContractWhere';
  id: Scalars['ID']['output'];
};

export type ApiVersionResponse = {
  __typename?: 'ApiVersionResponse';
  branch?: Maybe<Scalars['String']['output']>;
  commitId?: Maybe<Scalars['String']['output']>;
  env?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type AppSubscription = {
  __typename?: 'AppSubscription';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  status?: Maybe<SubscriptionStatus>;
  stripeSubscriptionID: Scalars['String']['output'];
  subscriptionPlan: Plan;
  updatedAt: Scalars['DateTime']['output'];
};

export type AppSubscriptionOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  stripeSubscriptionID?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type AppSubscriptionWhereInput = {
  AND?: InputMaybe<Array<AppSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<AppSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<AppSubscriptionWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organization?: InputMaybe<OrganizationWhereInput>;
  status?: InputMaybe<SubscriptionStatus>;
  stripeSubscriptionID?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlan?: InputMaybe<PlanWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String']['output'];
};

export type AuthedUser = {
  __typename?: 'AuthedUser';
  clientUser?: Maybe<ClientUser>;
  eventsLastViewedAt: Scalars['DateTime']['output'];
  groups: Array<TestingGroup>;
  id: Scalars['ID']['output'];
  impersonatingUser?: Maybe<ImpersonatingUser>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  orgUser?: Maybe<CalculatedOrgUser>;
  superAdmin?: Maybe<SuperAdminUser>;
  userType: Scalars['String']['output'];
};

export enum BankAccountObject {
  BankAccount = 'bank_account'
}

export type BaseUserForGetContractWhere = {
  __typename?: 'BaseUserForGetContractWhere';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userType: UserType;
};

export type BlockUserWhereInputType = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type BrandingSettings = {
  __typename?: 'BrandingSettings';
  icon?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  primary_color?: Maybe<Scalars['String']['output']>;
};

export type BrandingSettingsInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  primary_color?: InputMaybe<Scalars['String']['input']>;
};

export type BundleInput = {
  contactID: Scalars['String']['input'];
  contractBundleStatus: BundleStatusEnum;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceBundleStatus: BundleStatusEnum;
  proposalBundleStatus: BundleStatusEnum;
};

export enum BundleStatusEnum {
  Awaiting = 'Awaiting',
  NotAwaiting = 'NotAwaiting',
  Sent = 'Sent'
}

export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  mcc?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product_description?: Maybe<Scalars['String']['output']>;
  support_address?: Maybe<Address>;
  support_email?: Maybe<Scalars['String']['output']>;
  support_phone?: Maybe<Scalars['String']['output']>;
  support_url?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type BusinessProfileInput = {
  mcc?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_description?: InputMaybe<Scalars['String']['input']>;
  support_address?: InputMaybe<AddressInput>;
  support_email?: InputMaybe<Scalars['String']['input']>;
  support_phone?: InputMaybe<Scalars['String']['input']>;
  support_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CalculateQuickPaymentSubtotal = {
  __typename?: 'CalculateQuickPaymentSubtotal';
  payment: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
};

export type CalculateQuickPaymentSubtotalInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  isPayingByCard: Scalars['Boolean']['input'];
};

export type CalculatedOrgUser = {
  __typename?: 'CalculatedOrgUser';
  assignedClients?: Maybe<Array<ClientUser>>;
  contacts?: Maybe<Array<Contact>>;
  contractTemplates?: Maybe<Array<ContractTemplate>>;
  createdAt: Scalars['DateTime']['output'];
  events?: Maybe<Array<Event>>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  invitationID?: Maybe<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  originalImage?: Maybe<Scalars['String']['output']>;
  passWordLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  plannerEvents?: Maybe<Array<Event>>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type CalculatedOrgUserAssignedClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClientUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientUserWhereInput>;
};


export type CalculatedOrgUserContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type CalculatedOrgUserContractTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContractTemplateWhereInput>;
};


export type CalculatedOrgUserEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type CalculatedOrgUserPlannerEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type CardPaymentsSettings = {
  __typename?: 'CardPaymentsSettings';
  decline_on?: Maybe<DeclineOnSettings>;
  statement_descriptor_prefix?: Maybe<Scalars['String']['output']>;
};

export type CardPaymentsSettingsInput = {
  decline_on?: InputMaybe<DeclineOnSettingsInput>;
  statement_descriptor_prefix?: InputMaybe<Scalars['String']['input']>;
};

export type ClientDashboardStats = {
  __typename?: 'ClientDashboardStats';
  activeContacts: Scalars['Int']['output'];
  upcomingPayments: Scalars['Int']['output'];
};

export type ClientForOfflineHistory = {
  __typename?: 'ClientForOfflineHistory';
  firstNameOne?: Maybe<Scalars['String']['output']>;
  firstNameTwo?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastNameOne?: Maybe<Scalars['String']['output']>;
  lastNameTwo?: Maybe<Scalars['String']['output']>;
};

export type ClientUser = {
  __typename?: 'ClientUser';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  assignedPlanner?: Maybe<OrgUser>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  events: Array<Event>;
  facebook?: Maybe<Scalars['String']['output']>;
  firstNameOne: Scalars['String']['output'];
  firstNameTwo?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Int']['output']>;
  imagePositionY?: Maybe<Scalars['Int']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  lastNameOne?: Maybe<Scalars['String']['output']>;
  lastNameTwo?: Maybe<Scalars['String']['output']>;
  originalImage?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  pinterest?: Maybe<Scalars['String']['output']>;
  plannerOrgCanPayInvoices: Scalars['Boolean']['output'];
  plannerOrgCanSignContracts: Scalars['Boolean']['output'];
  plannerOrgHasConnectionPermissions: Scalars['Boolean']['output'];
  plannerOrgHasDocumentsPermissions: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<State>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  venue?: Maybe<Scalars['String']['output']>;
  weddingDate?: Maybe<Scalars['DateTime']['output']>;
};


export type ClientUserDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type ClientUserEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type ClientUserCreateInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Country;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  facebook?: InputMaybe<Scalars['String']['input']>;
  firstNameOne: Scalars['String']['input'];
  firstNameTwo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Float']['input']>;
  imagePositionY?: InputMaybe<Scalars['Float']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  invitationID?: InputMaybe<Scalars['String']['input']>;
  lastNameOne?: InputMaybe<Scalars['String']['input']>;
  lastNameTwo?: InputMaybe<Scalars['String']['input']>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pinterest?: InputMaybe<Scalars['String']['input']>;
  plannerOrgCanPayInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  plannerOrgCanSignContracts?: InputMaybe<Scalars['Boolean']['input']>;
  plannerOrgHasConnectionPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  plannerOrgHasDocumentsPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state: State;
  twitter?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
  verificationTokenID?: InputMaybe<Scalars['String']['input']>;
  weddingDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ClientUserForGetContractWhere = {
  __typename?: 'ClientUserForGetContractWhere';
  assignedPlanner?: Maybe<OrgUserForGetContractWhere>;
  id: Scalars['ID']['output'];
  plannerOrgCanPayInvoices: Scalars['Boolean']['output'];
  plannerOrgCanSignContracts: Scalars['Boolean']['output'];
  user: BaseUserForGetContractWhere;
};

export type ClientUserForSearchFilterInput = {
  OR?: InputMaybe<Array<ClientUserForSearchFilterInput>>;
  firstNameOne?: InputMaybe<Scalars['String']['input']>;
  firstNameOne_contains?: InputMaybe<Scalars['String']['input']>;
  firstNameTwo?: InputMaybe<Scalars['String']['input']>;
  firstNameTwo_contains?: InputMaybe<Scalars['String']['input']>;
  lastNameOne?: InputMaybe<Scalars['String']['input']>;
  lastNameOne_contains?: InputMaybe<Scalars['String']['input']>;
  lastNameTwo?: InputMaybe<Scalars['String']['input']>;
  lastNameTwo_contains?: InputMaybe<Scalars['String']['input']>;
};

export type ClientUserOrderByInput = {
  addressLine1?: InputMaybe<SortOrderType>;
  addressLine2?: InputMaybe<SortOrderType>;
  assignedPlanner?: InputMaybe<OrgUserOrderByInput>;
  city?: InputMaybe<SortOrderType>;
  country?: InputMaybe<SortOrderType>;
  createdAt?: InputMaybe<SortOrderType>;
  description?: InputMaybe<SortOrderType>;
  facebook?: InputMaybe<SortOrderType>;
  firstNameOne?: InputMaybe<SortOrderType>;
  firstNameTwo?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  image?: InputMaybe<SortOrderType>;
  imagePositionX?: InputMaybe<SortOrderType>;
  imagePositionY?: InputMaybe<SortOrderType>;
  imageRotation?: InputMaybe<SortOrderType>;
  imageScale?: InputMaybe<SortOrderType>;
  instagram?: InputMaybe<SortOrderType>;
  lastNameOne?: InputMaybe<SortOrderType>;
  lastNameTwo?: InputMaybe<SortOrderType>;
  originalImage?: InputMaybe<SortOrderType>;
  phone?: InputMaybe<SortOrderType>;
  pinterest?: InputMaybe<SortOrderType>;
  plannerOrgCanPayInvoices?: InputMaybe<SortOrderType>;
  plannerOrgCanSignContracts?: InputMaybe<SortOrderType>;
  plannerOrgHasConnectionPermissions?: InputMaybe<SortOrderType>;
  plannerOrgHasDocumentsPermissions?: InputMaybe<SortOrderType>;
  postalCode?: InputMaybe<SortOrderType>;
  state?: InputMaybe<SortOrderType>;
  twitter?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  venue?: InputMaybe<SortOrderType>;
  weddingDate?: InputMaybe<SortOrderType>;
};

export type ClientUserUpdateCustomInput = {
  acceptedTerms?: InputMaybe<Scalars['DateTime']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  firstNameOne?: InputMaybe<Scalars['String']['input']>;
  firstNameTwo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Int']['input']>;
  imagePositionY?: InputMaybe<Scalars['Int']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  lastNameOne?: InputMaybe<Scalars['String']['input']>;
  lastNameTwo?: InputMaybe<Scalars['String']['input']>;
  notificationPreference?: InputMaybe<NotificationsPreference>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<State>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutClientUserInput>;
  venue?: InputMaybe<Scalars['String']['input']>;
  weddingDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ClientUserWhereDocumentsWhereInput = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type ClientUserWhereEventsWhereInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type ClientUserWhereInput = {
  AND?: InputMaybe<Array<ClientUserWhereInput>>;
  NOT?: InputMaybe<Array<ClientUserWhereInput>>;
  OR?: InputMaybe<Array<ClientUserWhereInput>>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  assignedPlanner?: InputMaybe<OrgUserWhereInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_contains?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<ClientUserWhereDocumentsWhereInput>;
  events?: InputMaybe<ClientUserWhereEventsWhereInput>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  facebook_contains?: InputMaybe<Scalars['String']['input']>;
  firstNameOne?: InputMaybe<Scalars['String']['input']>;
  firstNameOne_contains?: InputMaybe<Scalars['String']['input']>;
  firstNameTwo?: InputMaybe<Scalars['String']['input']>;
  firstNameTwo_contains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Float']['input']>;
  imagePositionY?: InputMaybe<Scalars['Float']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  instagram_contains?: InputMaybe<Scalars['String']['input']>;
  is?: InputMaybe<ClientUserWhereInput>;
  lastNameOne?: InputMaybe<Scalars['String']['input']>;
  lastNameOne_contains?: InputMaybe<Scalars['String']['input']>;
  lastNameTwo?: InputMaybe<Scalars['String']['input']>;
  lastNameTwo_contains?: InputMaybe<Scalars['String']['input']>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  pinterest_contains?: InputMaybe<Scalars['String']['input']>;
  plannerOrgCanPayInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  plannerOrgCanSignContracts?: InputMaybe<Scalars['Boolean']['input']>;
  plannerOrgHasConnectionPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  plannerOrgHasDocumentsPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<State>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  twitter_contains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserWhereUniqueInput>;
  venue?: InputMaybe<Scalars['String']['input']>;
  venue_contains?: InputMaybe<Scalars['String']['input']>;
  weddingDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ClientUserWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type ClientUsers = {
  __typename?: 'ClientUsers';
  _count: Scalars['Int']['output'];
  clientUsers: Array<ClientUser>;
};

export type CompanyDetails = {
  __typename?: 'CompanyDetails';
  address?: Maybe<Address>;
  directors_provided?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owners_provided?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  tax_id_provided?: Maybe<Scalars['Boolean']['output']>;
  tax_id_registrar?: Maybe<Scalars['String']['output']>;
  vat_id_provided?: Maybe<Scalars['Boolean']['output']>;
  verification?: Maybe<CompanyVerification>;
};

export type CompanyDetailsInput = {
  address?: InputMaybe<AddressInput>;
  directors_provided?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owners_provided?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  tax_id?: InputMaybe<Scalars['String']['input']>;
  tax_id_registrar?: InputMaybe<Scalars['String']['input']>;
  vat_id?: InputMaybe<Scalars['String']['input']>;
  verification?: InputMaybe<CompanyVerificationInput>;
};

export type CompanyVerification = {
  __typename?: 'CompanyVerification';
  document?: Maybe<VerificationDocument>;
};

export type CompanyVerificationInput = {
  document?: InputMaybe<VerificationDocumentInput>;
};

export type ConnectToUniqueContactInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
};

export type Contact = {
  __typename?: 'Contact';
  assignedMember: OrgUser;
  createdAt: Scalars['DateTime']['output'];
  customer: User;
  customerSignatures?: Maybe<Array<CustomerSignature>>;
  documents?: Maybe<Array<Document>>;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  events?: Maybe<Array<Event>>;
  firstName: Scalars['String']['output'];
  hasCustomerIntent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invitation?: Maybe<Invitation>;
  invoices?: Maybe<Array<Invoice>>;
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  proposals?: Maybe<Array<Proposal>>;
  status: ContactStatus;
  updatedAt: Scalars['DateTime']['output'];
  vendor: Organization;
};


export type ContactDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type ContactEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type ContactInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type ContactContractsWhereInput = {
  every?: InputMaybe<ContractWhereInput>;
  none?: InputMaybe<ContractWhereInput>;
  some?: InputMaybe<ContractWhereInput>;
};

export type ContactCreateManyWithoutDocumentsInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
};

export type ContactCreateOneWithoutProposalsInput = {
  connect: ContactWhereUniqueInput;
};

export type ContactCustomerForGetQuickPayment = {
  __typename?: 'ContactCustomerForGetQuickPayment';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userType: UserType;
};

export type ContactDocumentsWhereInput = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type ContactEventsWhereInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type ContactForGetContractWhere = {
  __typename?: 'ContactForGetContractWhere';
  assignedMember: OrgUserForGetContractWhere;
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerForGetContractWhere;
  customerSignatures?: Maybe<Array<CustomerSignature>>;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  hasCustomerIntent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<InvoiceForGetContractWhere>>;
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  proposals?: Maybe<Array<Proposal>>;
  status: ContactStatus;
  updatedAt: Scalars['DateTime']['output'];
  vendor: OrganizationForGetContractWhere;
};


export type ContactForGetContractWhereInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInputForGetContractWhere>;
};

export type ContactForGetQuickPayment = {
  __typename?: 'ContactForGetQuickPayment';
  customer: ContactCustomerForGetQuickPayment;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type ContactForSearchFilter = {
  customer?: InputMaybe<UserWhereInputForGetInvoicesForCustomer>;
};

export type ContactInputForGetInvoicesForCustomer = {
  customer: UserWhereUniqueInput;
  vendor?: InputMaybe<OrganizationWhereInput>;
};

export type ContactInquiriesWhereInput = {
  every?: InputMaybe<InquiryWhereInput>;
  none?: InputMaybe<InquiryWhereInput>;
  some?: InputMaybe<InquiryWhereInput>;
};

export type ContactInvoicesWhereInput = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type ContactOrderByInput = {
  _count?: InputMaybe<SortOrderType>;
  assignedMember?: InputMaybe<OrgUserOrderByInput>;
  createdAt?: InputMaybe<SortOrderType>;
  customer?: InputMaybe<CustomerOrderByInput>;
  eventDate?: InputMaybe<SortOrderType>;
  firstName?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  lastName?: InputMaybe<SortOrderType>;
  note?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  vendor?: InputMaybe<OrganizationOrderByInput>;
};

export type ContactSignature = {
  __typename?: 'ContactSignature';
  contract: ContractForSignature;
  id: Scalars['ID']['output'];
  signer?: Maybe<UserForSignature>;
  signingDate?: Maybe<Scalars['DateTime']['output']>;
};

export enum ContactStatus {
  Active = 'Active',
  Budget = 'Budget',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Duplicate = 'Duplicate',
  Inactive = 'Inactive',
  NotAvailable = 'NotAvailable',
  Other = 'Other',
  Pending = 'Pending',
  Spam = 'Spam',
  Unresponsive = 'Unresponsive'
}

export type ContactUpdateManyWithoutDocumentsInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
};

export type ContactWhereForInvoiceSearch = {
  OR?: InputMaybe<Array<ContactInputForGetInvoicesForCustomer>>;
  customer?: InputMaybe<UserWhereInputForGetInvoicesForCustomer>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  invitation?: InputMaybe<InvitationWhereForGetInvoicesForCustomer>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<OrganizationWhereInput>;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  assignedMember?: InputMaybe<OrgUserWhereInput>;
  contracts?: InputMaybe<ContactContractsWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<UserWhereInput>;
  documents?: InputMaybe<ContactDocumentsWhereInput>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<ContactEventsWhereInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  hasCustomerIntent?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inquiries?: InputMaybe<ContactInquiriesWhereInput>;
  invitation?: InputMaybe<InvitationWhereInput>;
  invoices?: InputMaybe<ContactInvoicesWhereInput>;
  is?: InputMaybe<ContactWhereInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  some?: InputMaybe<ContactWhereInput>;
  status?: InputMaybe<ContactStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vendor?: InputMaybe<OrganizationWhereInput>;
};

export type ContactWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type Contacts = {
  __typename?: 'Contacts';
  _count: Scalars['Int']['output'];
  contacts: Array<Contact>;
};

export type ContractCreateCustomInput = {
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contractTemplate?: InputMaybe<ContractTemplateUpdateRelationshipInput>;
  dueDate: Scalars['DateTime']['input'];
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  signatures: SignaturesCreateManyInput;
  title: Scalars['String']['input'];
};

export type ContractEventsWhereInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type ContractForGetContractWhere = {
  __typename?: 'ContractForGetContractWhere';
  automatedRemindersEnabled: Scalars['Boolean']['output'];
  contractSourceFile?: Maybe<Scalars['String']['output']>;
  contractTemplate?: Maybe<ContractTemplateForGetContractWhere>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  executedContractUrl?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  hsSignatureRequestId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPastDue?: Maybe<Scalars['Boolean']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentByOrganizationName?: Maybe<Scalars['String']['output']>;
  sentByUser?: Maybe<OrgUserForGetContractWhere>;
  signatures?: Maybe<Array<SignatureForGetContractWhere>>;
  status?: Maybe<ContractStatus>;
  title?: Maybe<Scalars['String']['output']>;
  unsignedContractFileUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vendor: OrganizationForGetContractWhere;
};

export type ContractForSignature = {
  __typename?: 'ContractForSignature';
  id: Scalars['ID']['output'];
  status: ContractStatus;
};

export type ContractOrderByInput = {
  contact?: InputMaybe<ContactOrderByInput>;
  contractSourceFile?: InputMaybe<SortOrderType>;
  createdAt?: InputMaybe<SortOrderType>;
  dueDate?: InputMaybe<SortOrderType>;
  eventDate?: InputMaybe<SortOrderType>;
  executedContractUrl?: InputMaybe<SortOrderType>;
  hsSignatureRequestId?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  isPastDue?: InputMaybe<SortOrderType>;
  sentAt?: InputMaybe<SortOrderType>;
  sentByOrganizationName?: InputMaybe<SortOrderType>;
  sentByUser?: InputMaybe<OrgUserOrderByInput>;
  status?: InputMaybe<SortOrderType>;
  title?: InputMaybe<SortOrderType>;
  type?: InputMaybe<SortOrderType>;
  unsignedContractFileUrl?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  vendor?: InputMaybe<OrganizationOrderByInput>;
};

export type ContractResendInput = {
  customizedEmail?: InputMaybe<CustomizedEmailInput>;
};

export enum ContractStatus {
  Complete = 'Complete',
  Deleted = 'Deleted',
  Draft = 'Draft',
  PartiallySigned = 'PartiallySigned',
  ReadyToBeCountersigned = 'ReadyToBeCountersigned',
  Sent = 'Sent',
  Void = 'Void'
}

export type ContractTemplate = {
  __typename?: 'ContractTemplate';
  contracts?: Maybe<Array<CustomContract>>;
  countOfCustomerSigners?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: OrgUser;
  editUrl?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Event>>;
  helloSignTemplateID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  organization: Organization;
  sourceFileUrl?: Maybe<Scalars['String']['output']>;
  status: ContractTemplateStatus;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vendorWillSign: Scalars['Boolean']['output'];
};


export type ContractTemplateContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContractOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContractWhereInput>;
};


export type ContractTemplateEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type ContractTemplateContractsWhereInput = {
  every?: InputMaybe<ContractWhereInput>;
  none?: InputMaybe<ContractWhereInput>;
  some?: InputMaybe<ContractWhereInput>;
};

export type ContractTemplateCreateInput = {
  countOfCustomerSigners: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  vendorWillSign: Scalars['Boolean']['input'];
};

export type ContractTemplateCustomUpdateInput = {
  countOfCustomerSigners?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vendorWillSign?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContractTemplateEventsWhereInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type ContractTemplateForContractsForVendorInput = {
  id: Scalars['ID']['input'];
};

export type ContractTemplateForGetContractWhere = {
  __typename?: 'ContractTemplateForGetContractWhere';
  countOfCustomerSigners: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  sourceFileUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  vendorWillSign: Scalars['Boolean']['output'];
};

export type ContractTemplateMarkupUrl = {
  __typename?: 'ContractTemplateMarkupUrl';
  url: Scalars['String']['output'];
};

export type ContractTemplateOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  editUrl?: InputMaybe<SortOrderType>;
  helloSignTemplateID?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  sourceFileUrl?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  title?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export enum ContractTemplateStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export type ContractTemplateUpdateRelationshipInput = {
  connect?: InputMaybe<ContractTemplateWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContractTemplateWhereInput = {
  AND?: InputMaybe<Array<ContractTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ContractTemplateWhereInput>>;
  OR?: InputMaybe<Array<ContractTemplateWhereInput>>;
  contracts?: InputMaybe<ContractTemplateContractsWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<OrgUserWhereInput>;
  editUrl?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<ContractTemplateEventsWhereInput>;
  helloSignTemplateID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organization?: InputMaybe<OrganizationWhereInput>;
  sourceFileUrl?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContractTemplateStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vendorWillSign?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContractTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ContractTemplates = {
  __typename?: 'ContractTemplates';
  _count: Scalars['Int']['output'];
  contractTemplates: Array<ContractTemplate>;
};

export type ContractUpdateInput = {
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contractSourceFile?: InputMaybe<Scalars['String']['input']>;
  contractTemplate?: InputMaybe<ContractTemplateUpdateRelationshipInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  signatures?: InputMaybe<Array<ContractUpdateSignaturesCreateManyDataInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ContractUpdateSignaturesCreateManyDataInput = {
  signerId: Scalars['String']['input'];
  type: SignatureType;
};

export type ContractWhereInput = {
  AND?: InputMaybe<Array<ContractWhereInput>>;
  NOT?: InputMaybe<Array<ContractWhereInput>>;
  OR?: InputMaybe<Array<ContractWhereInput>>;
  contractSourceFile?: InputMaybe<Scalars['String']['input']>;
  contractTemplate?: InputMaybe<ContractTemplateWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<ContractEventsWhereInput>;
  executedContractUrl?: InputMaybe<Scalars['String']['input']>;
  hsSignatureRequestId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPastDue?: InputMaybe<Scalars['Boolean']['input']>;
  sentAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentByOrganizationName?: InputMaybe<Scalars['String']['input']>;
  sentByUser?: InputMaybe<OrgUserWhereInput>;
  signatures?: InputMaybe<SignaturesWhereInput>;
  status?: InputMaybe<ContractStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  unsignedContractFileUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vendor?: InputMaybe<OrganizationWhereInput>;
};

export type ContractWhereInputWithGuestToken = {
  contract: ContractWhereUniqueInput;
  guestToken?: InputMaybe<Scalars['String']['input']>;
};

export type ContractWhereInputWithRequiredGuestToken = {
  contract: ContractWhereUniqueInput;
  guestToken: Scalars['String']['input'];
};

export type ContractWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type ContractsForCustomer = {
  __typename?: 'ContractsForCustomer';
  _count: Scalars['Int']['output'];
  contracts: Array<CustomContract>;
};

export type ContractsForCustomerInput = {
  AND?: InputMaybe<Array<ContractsForCustomerInput>>;
  NOT?: InputMaybe<Array<ContractsForCustomerInput>>;
  OR?: InputMaybe<Array<ContractsForCustomerInput>>;
  archivedByVendor?: InputMaybe<Scalars['Boolean']['input']>;
  sentByUser?: InputMaybe<SentByUserInputForContracts>;
  signatures?: InputMaybe<SignaturesWhereInput>;
  status?: InputMaybe<ContractStatus>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<SortOrderInequalityInput>;
  vendor?: InputMaybe<VendorInputForGetContractsForCustomer>;
};

export type ContractsForVendor = {
  __typename?: 'ContractsForVendor';
  _count: Scalars['Int']['output'];
  contracts: Array<CustomContract>;
};

export type ContractsForVendorInput = {
  AND?: InputMaybe<Array<ContractsForVendorInput>>;
  NOT?: InputMaybe<Array<ContractsForVendorInput>>;
  OR?: InputMaybe<Array<ContractsForVendorInput>>;
  archivedByVendor?: InputMaybe<Scalars['Boolean']['input']>;
  contractTemplate?: InputMaybe<ContractTemplateForContractsForVendorInput>;
  isPastDue?: InputMaybe<Scalars['Boolean']['input']>;
  sentByUser?: InputMaybe<SentByUserInputForContracts>;
  signatures?: InputMaybe<SignaturesWhereInput>;
  status?: InputMaybe<ContractStatus>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<SortOrderInequalityInput>;
  vendor?: InputMaybe<VendorInputForGetContractsForVendor>;
};

export { Country };

export type Coupon = {
  __typename?: 'Coupon';
  active: Scalars['Boolean']['output'];
  couponInstances?: Maybe<Array<CouponInstance>>;
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizations?: Maybe<Array<Organization>>;
  stripeID: Scalars['String']['output'];
};


export type CouponCouponInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CouponInstanceWhereInput>;
};


export type CouponOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type CouponByNameInput = {
  name: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

export type CouponCreateReducedInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  stripeID: Scalars['String']['input'];
};

export type CouponInstance = {
  __typename?: 'CouponInstance';
  coupon: Coupon;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  live: Scalars['Boolean']['output'];
  organization: Organization;
};

export type CouponInstanceWhereInput = {
  AND?: InputMaybe<Array<CouponInstanceWhereInput>>;
  NOT?: InputMaybe<Array<CouponInstanceWhereInput>>;
  OR?: InputMaybe<Array<CouponInstanceWhereInput>>;
  coupon?: InputMaybe<CouponWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<OrganizationWhereInput>;
};

export type CouponWhereCouponInstancesInput = {
  every?: InputMaybe<CouponInstanceWhereInput>;
  none?: InputMaybe<CouponInstanceWhereInput>;
  some?: InputMaybe<CouponInstanceWhereInput>;
};

export type CouponWhereInput = {
  AND?: InputMaybe<Array<CouponWhereInput>>;
  NOT?: InputMaybe<Array<CouponWhereInput>>;
  OR?: InputMaybe<Array<CouponWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  couponInstances?: InputMaybe<CouponWhereCouponInstancesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<CouponWhereOrganizationsInput>;
  stripeID?: InputMaybe<Scalars['String']['input']>;
};

export type CouponWhereOrganizationsInput = {
  every?: InputMaybe<OrganizationWhereInput>;
  none?: InputMaybe<OrganizationWhereInput>;
  some?: InputMaybe<OrganizationWhereInput>;
};

export type CouponWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateContactDataInput = {
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContractSigningUrlInput = {
  contract: EmbeddedSigningContract;
  guestToken?: InputMaybe<Scalars['String']['input']>;
  signingAs: Scalars['ID']['input'];
};

export type CreateContractTemplateMarkupUrlInput = {
  sourceFileUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateContactForCustomerDataInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateOrUpdateContactForCustomerWhereInput = {
  email: Scalars['String']['input'];
  vendorId: Scalars['ID']['input'];
};

export type CreateVerificationTokenInput = {
  email: Scalars['String']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreatedAtGreaterThan = {
  gt: Scalars['DateTime']['input'];
};

export type CustomContact = {
  __typename?: 'CustomContact';
  guestUser: JustUserWithEmail;
  id: Scalars['ID']['output'];
};

export type CustomContactInput = {
  AND?: InputMaybe<Array<CustomContactInput>>;
  NOT?: InputMaybe<Array<CustomContactInput>>;
  OR?: InputMaybe<Array<CustomContactInput>>;
  assignedMember?: InputMaybe<OrgUserWhereInput>;
  customer?: InputMaybe<UserWhereInput>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  invitation?: InputMaybe<InvitationWhereInput>;
  invoices?: InputMaybe<InvoiceWhereInput>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContactStatus>;
  vendor?: InputMaybe<OrganizationWhereInput>;
};

export type CustomContactUpdateInput = {
  email: Scalars['String']['input'];
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomContract = {
  __typename?: 'CustomContract';
  automatedRemindersEnabled: Scalars['Boolean']['output'];
  contractSourceFile?: Maybe<Scalars['String']['output']>;
  contractTemplate?: Maybe<ContractTemplate>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  executedContractUrl?: Maybe<Scalars['String']['output']>;
  guestToken?: Maybe<Scalars['String']['output']>;
  hsSignatureRequestId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPastDue?: Maybe<Scalars['Boolean']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentByOrganizationName?: Maybe<Scalars['String']['output']>;
  sentByUser?: Maybe<OrgUser>;
  signatures?: Maybe<Array<Signature>>;
  status?: Maybe<ContractStatus>;
  title?: Maybe<Scalars['String']['output']>;
  unsignedContractFileUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vendor: Organization;
};

export type CustomContractTemplateWhereWithoutOrganizationInput = {
  AND?: InputMaybe<Array<CustomContractTemplateWhereWithoutOrganizationInput>>;
  NOT?: InputMaybe<Array<CustomContractTemplateWhereWithoutOrganizationInput>>;
  OR?: InputMaybe<Array<CustomContractTemplateWhereWithoutOrganizationInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<OrgUserWhereInput>;
  helloSignTemplateID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  sourceFileUrl?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContractTemplateStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomCustomerSignatureForContact = {
  __typename?: 'CustomCustomerSignatureForContact';
  id: Scalars['ID']['output'];
  signature: ContactSignature;
};

export type CustomDocumentWhereInput = {
  AND?: InputMaybe<Array<CustomDocumentWhereInput>>;
  NOT?: InputMaybe<Array<CustomDocumentWhereInput>>;
  OR?: InputMaybe<Array<CustomDocumentWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<UserWhereUniqueInput>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  fileSource?: InputMaybe<FileSourceType>;
  fileSourceID?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<FileType>;
  fileUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgUser?: InputMaybe<OrgUserWhereUniqueInput>;
  organization?: InputMaybe<OrganizationWhereUniqueInput>;
  ownerType?: InputMaybe<DocumentOwnerType>;
  parentFolder?: InputMaybe<FolderWhereUniqueInput>;
  sharedWithContacts?: InputMaybe<ContactWhereInput>;
  sharedWithCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<DocumentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomDocumentWithDownloadUrl = {
  __typename?: 'CustomDocumentWithDownloadUrl';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<User>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Event>>;
  fileSize: Scalars['Int']['output'];
  fileSource: FileSourceType;
  fileSourceID?: Maybe<Scalars['String']['output']>;
  fileType: FileType;
  fileUpdatedAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgUser?: Maybe<OrgUser>;
  organization?: Maybe<Organization>;
  ownerType: DocumentOwnerType;
  parentFolder?: Maybe<Folder>;
  sharedWithContacts?: Maybe<Array<Contact>>;
  sharedWithCustomer: Scalars['Boolean']['output'];
  status: DocumentStatus;
  type: DocumentType;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomDocumentWithDownloadUrlEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type CustomDocumentWithDownloadUrlSharedWithContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};

export type CustomDocumentWithUploadUrl = {
  __typename?: 'CustomDocumentWithUploadUrl';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<User>;
  events?: Maybe<Array<Event>>;
  fileSize: Scalars['Int']['output'];
  fileSource: FileSourceType;
  fileSourceID?: Maybe<Scalars['String']['output']>;
  fileType: FileType;
  fileUpdatedAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgUser?: Maybe<OrgUser>;
  organization?: Maybe<Organization>;
  ownerType: DocumentOwnerType;
  parentFolder?: Maybe<Folder>;
  sharedWithContacts?: Maybe<Array<Contact>>;
  sharedWithCustomer: Scalars['Boolean']['output'];
  status: DocumentStatus;
  type: DocumentType;
  updatedAt: Scalars['DateTime']['output'];
  uploadURL?: Maybe<Scalars['String']['output']>;
};


export type CustomDocumentWithUploadUrlEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type CustomDocumentWithUploadUrlSharedWithContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};

export type CustomFolder = {
  __typename?: 'CustomFolder';
  _childFoldersCount: Scalars['Int']['output'];
  _documentsCount: Scalars['Int']['output'];
  childFolders?: Maybe<Array<Maybe<CustomFolder>>>;
  createdAt: Scalars['DateTime']['output'];
  documents?: Maybe<Array<Maybe<CustomDocumentWithDownloadUrl>>>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  parentFolder?: Maybe<CustomFolder>;
  status?: Maybe<FolderStatus>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomResource = {
  __typename?: 'CustomResource';
  availableUntil?: Maybe<Scalars['DateTime']['output']>;
  category: ResourceCategory;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  fileType: FileType;
  id: Scalars['ID']['output'];
  previewDownloadUrl?: Maybe<Scalars['String']['output']>;
  previewUploadUrl?: Maybe<Scalars['String']['output']>;
  sponsor: CustomSponsor;
  status: ResourceStatus;
  updatedAt: Scalars['DateTime']['output'];
  uploadUrl?: Maybe<Scalars['String']['output']>;
};

export type CustomResources = {
  __typename?: 'CustomResources';
  _count: Scalars['Int']['output'];
  resources: Array<CustomResource>;
};

export type CustomShareDocumentInput = {
  sharedWithContacts?: InputMaybe<ContactUpdateManyWithoutDocumentsInput>;
  sharedWithCustomer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomSponsor = {
  __typename?: 'CustomSponsor';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  resources?: Maybe<Array<Resource>>;
  sponsorLink: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadUrl?: Maybe<Scalars['String']['output']>;
};


export type CustomSponsorResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};

export type CustomerBankAccountVerificationInput = {
  amounts: Array<Scalars['Int']['input']>;
  bankAccountID: Scalars['String']['input'];
};

export type CustomerForGetContractWhere = {
  __typename?: 'CustomerForGetContractWhere';
  clientUser?: Maybe<ClientUserForGetContractWhere>;
  email: Scalars['String']['output'];
  guestUser?: Maybe<GuestUserForGetContractWhere>;
  id: Scalars['ID']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  userType: UserType;
};

export type CustomerOrderByInput = {
  clientUser?: InputMaybe<ClientUserOrderByInput>;
};

export type CustomerSignature = {
  __typename?: 'CustomerSignature';
  approved: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  contact: CustomerSignatureContact;
  id: Scalars['ID']['output'];
  signature: Signature;
};

export type CustomerSignatureContact = {
  __typename?: 'CustomerSignatureContact';
  assignedMember: CustomerSignatureOrgUser;
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerSignatureContactCustomer;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  hasCustomerIntent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  status: ContactStatus;
  updatedAt: Scalars['DateTime']['output'];
  vendor: CustomerSignatureContactOrganization;
};

export type CustomerSignatureContactCustomer = {
  __typename?: 'CustomerSignatureContactCustomer';
  clientUser?: Maybe<CustomerSignatureContactCustomerClientUser>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userType: UserType;
};

export type CustomerSignatureContactCustomerClientUser = {
  __typename?: 'CustomerSignatureContactCustomerClientUser';
  assignedPlanner?: Maybe<CustomerSignatureContactCustomerClientUserAssignedPlanner>;
  id: Scalars['ID']['output'];
  plannerOrgCanSignContracts: Scalars['Boolean']['output'];
};

export type CustomerSignatureContactCustomerClientUserAssignedPlanner = {
  __typename?: 'CustomerSignatureContactCustomerClientUserAssignedPlanner';
  id: Scalars['ID']['output'];
  organization: CustomerSignatureContactOrganization;
};

export type CustomerSignatureContactCustomerWhereInput = {
  email_contains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomerSignatureContactOrganization = {
  __typename?: 'CustomerSignatureContactOrganization';
  adminUsers?: Maybe<Array<CustomerSignatureContactOrganizationAdminUsers>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerSignatureContactOrganizationAdminUsers = {
  __typename?: 'CustomerSignatureContactOrganizationAdminUsers';
  id: Scalars['ID']['output'];
};

export type CustomerSignatureContactVendorWhereInput = {
  id: Scalars['ID']['input'];
};

export type CustomerSignatureContactWhereInput = {
  AND?: InputMaybe<Array<CustomerSignatureContactWhereInput>>;
  NOT?: InputMaybe<Array<CustomerSignatureContactWhereInput>>;
  OR?: InputMaybe<Array<CustomerSignatureContactWhereInput>>;
  customer?: InputMaybe<CustomerSignatureContactCustomerWhereInput>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContactStatus>;
  vendor?: InputMaybe<CustomerSignatureContactVendorWhereInput>;
};

export type CustomerSignatureForGetContractWhere = {
  __typename?: 'CustomerSignatureForGetContractWhere';
  approved: Scalars['Boolean']['output'];
  contact: ContactForGetContractWhere;
  id: Scalars['ID']['output'];
};

export type CustomerSignatureOrgUser = {
  __typename?: 'CustomerSignatureOrgUser';
  id: Scalars['ID']['output'];
  organization: CustomerSignatureContactOrganization;
  user: CustomerSignatureOrgUserUser;
};

export type CustomerSignatureOrgUserUser = {
  __typename?: 'CustomerSignatureOrgUserUser';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  organization: CustomerSignatureContactOrganization;
};

export type CustomerSignatureOrganizationAdminUsers = {
  id: Scalars['ID']['input'];
};

export type CustomerSignatureOrganizationWhereInput = {
  adminUsers?: InputMaybe<Array<CustomerSignatureOrganizationAdminUsers>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Float']['input']>;
  imagePositionY?: InputMaybe<Scalars['Float']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerSignatureWhereInput = {
  contact?: InputMaybe<CustomerSignatureContactWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Float']['input']>;
  imagePositionY?: InputMaybe<Scalars['Float']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<CustomerSignatureOrganizationWhereInput>;
};

export type CustomerStripeCardUpdateInput = {
  cardUpdates: StripeCardUpdateInfoInput;
  setToDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomizedEmailInput = {
  intro?: InputMaybe<Scalars['String']['input']>;
  outro?: InputMaybe<Scalars['String']['input']>;
};

export type Dob = {
  __typename?: 'DOB';
  day?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type DobInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type DashboardSettings = {
  __typename?: 'DashboardSettings';
  display_name?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type DashboardSettingsInput = {
  display_name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type DeclineOnSettings = {
  __typename?: 'DeclineOnSettings';
  avs_failure?: Maybe<Scalars['Boolean']['output']>;
  cvc_failure?: Maybe<Scalars['Boolean']['output']>;
};

export type DeclineOnSettingsInput = {
  avs_failure?: InputMaybe<Scalars['Boolean']['input']>;
  cvc_failure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Discount = {
  __typename?: 'Discount';
  coupon?: Maybe<StripeCoupon>;
  customer?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<Scalars['String']['output']>;
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<User>;
  deletionHash?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Event>>;
  fileSize: Scalars['Int']['output'];
  fileSource: FileSourceType;
  fileSourceID?: Maybe<Scalars['String']['output']>;
  fileType: FileType;
  fileUpdatedAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgUser?: Maybe<OrgUser>;
  organization?: Maybe<Organization>;
  ownerType: DocumentOwnerType;
  parentFolder?: Maybe<Folder>;
  sharedWithContacts?: Maybe<Array<Contact>>;
  sharedWithCustomer: Scalars['Boolean']['output'];
  status: DocumentStatus;
  type: DocumentType;
  updatedAt: Scalars['DateTime']['output'];
};


export type DocumentEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type DocumentSharedWithContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};

export type DocumentCreateCustomInput = {
  contact?: InputMaybe<ContactWhereUniqueInput>;
  fileSize: Scalars['Int']['input'];
  fileSource: FileSourceType;
  fileSourceID?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentFolder?: InputMaybe<FolderCreateOneWithoutDocumentsInput>;
  sharedWithContacts?: InputMaybe<ContactCreateManyWithoutDocumentsInput>;
  sharedWithCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  type: DocumentType;
};

export type DocumentEventsWhereInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type DocumentOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  deletionHash?: InputMaybe<SortOrderType>;
  fileSize?: InputMaybe<SortOrderType>;
  fileSource?: InputMaybe<SortOrderType>;
  fileSourceID?: InputMaybe<SortOrderType>;
  fileType?: InputMaybe<SortOrderType>;
  fileUpdatedAt?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  location?: InputMaybe<SortOrderType>;
  name?: InputMaybe<SortOrderType>;
  organization?: InputMaybe<OrganizationOrderByInput>;
  ownerName?: InputMaybe<SortOrderType>;
  ownerType?: InputMaybe<SortOrderType>;
  sharedWithContacts?: InputMaybe<ContactOrderByInput>;
  sharedWithCustomer?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  type?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export enum DocumentOwnerType {
  Customer = 'Customer',
  Organization = 'Organization'
}

export type DocumentSharedWithContactsWhereInput = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export enum DocumentStatus {
  Archived = 'Archived',
  Available = 'Available',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export enum DocumentType {
  Internal = 'Internal',
  Sharable = 'Sharable'
}

export type DocumentWhereInput = {
  AND?: InputMaybe<Array<DocumentWhereInput>>;
  NOT?: InputMaybe<Array<DocumentWhereInput>>;
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  clientUser?: InputMaybe<ClientUserWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletionHash?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<DocumentEventsWhereInput>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  fileSource?: InputMaybe<FileSourceType>;
  fileSourceID?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<FileType>;
  fileUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgUser?: InputMaybe<OrgUserWhereInput>;
  organization?: InputMaybe<OrganizationWhereInput>;
  ownerType?: InputMaybe<DocumentOwnerType>;
  parentFolder?: InputMaybe<FolderWhereInput>;
  sharedWithContacts?: InputMaybe<DocumentSharedWithContactsWhereInput>;
  sharedWithCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<DocumentStatus>;
  type?: InputMaybe<DocumentType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DocumentWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type Documents = {
  __typename?: 'Documents';
  _count: Scalars['Int']['output'];
  documents: Array<Document>;
};

export type DocumentsWhereCustomIdInput = {
  in: Scalars['String']['input'];
};

export type DocumentsWhereCustomInput = {
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  id?: InputMaybe<DocumentsWhereCustomIdInput>;
};

export type EmailAddressInput = {
  currentEmailAddress: Scalars['String']['input'];
};

export type EmailAddressUpdateInput = {
  newEmailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type EmbeddedSigningContract = {
  id: Scalars['String']['input'];
};

export type Event = {
  __typename?: 'Event';
  actingUser?: Maybe<ActingUserForEvent>;
  category: EventCategory;
  clientUser?: Maybe<ClientUser>;
  clientUserEmailProcessed?: Maybe<Scalars['Boolean']['output']>;
  consumingUsers?: Maybe<Array<User>>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<Array<Contact>>;
  contract?: Maybe<CustomContract>;
  contractTemplate?: Maybe<ContractTemplate>;
  createdAt: Scalars['DateTime']['output'];
  dismissingUsers?: Maybe<Array<User>>;
  document?: Maybe<Document>;
  guestUser?: Maybe<GuestUser>;
  id: Scalars['ID']['output'];
  inquiry?: Maybe<Inquiry>;
  invitation?: Maybe<Invitation>;
  invoice?: Maybe<Invoice>;
  message?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['Json']['output']>;
  orgUser?: Maybe<OrgUser>;
  orgUserEmailProcessed?: Maybe<Scalars['Boolean']['output']>;
  organizationEmailProcessed?: Maybe<Scalars['Boolean']['output']>;
  paymentInstallment?: Maybe<PaymentInstallment>;
  paymentInstallments?: Maybe<Array<PaymentInstallment>>;
  plannerUser?: Maybe<OrgUser>;
  plannerUserEmailProcessed?: Maybe<Scalars['Boolean']['output']>;
  proposal?: Maybe<Proposal>;
  referenceID?: Maybe<Scalars['String']['output']>;
  resolvedBy?: Maybe<Event>;
  updatedAt: Scalars['DateTime']['output'];
  vendor?: Maybe<Organization>;
};


export type EventConsumingUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type EventDismissingUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type EventPaymentInstallmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentInstallmentWhereInput>;
};

export enum EventCategory {
  AdminFinishedOnBoardingWithoutPayFrom = 'AdminFinishedOnBoardingWithoutPayFrom',
  AdminFinishedOnBoardingWithoutPayTo = 'AdminFinishedOnBoardingWithoutPayTo',
  BalanceAvailable = 'BalanceAvailable',
  ClientAddedNewPaymentMethod = 'ClientAddedNewPaymentMethod',
  ClientAssignedPlannerPermissions = 'ClientAssignedPlannerPermissions',
  ClientFinishedOnBoardingWithoutPaymentMethod = 'ClientFinishedOnBoardingWithoutPaymentMethod',
  ClientGrantedPlannerConnectionsPermission = 'ClientGrantedPlannerConnectionsPermission',
  ClientGrantedPlannerPayingPermission = 'ClientGrantedPlannerPayingPermission',
  ClientGrantedPlannerSigningPermission = 'ClientGrantedPlannerSigningPermission',
  ClientRemovedPlannerPermissions = 'ClientRemovedPlannerPermissions',
  ClientRevokedPlannerConnectionsPermission = 'ClientRevokedPlannerConnectionsPermission',
  ClientRevokedPlannerPayingPermission = 'ClientRevokedPlannerPayingPermission',
  ClientRevokedPlannerSigningPermission = 'ClientRevokedPlannerSigningPermission',
  ClientUpdatedPlannerPermissions = 'ClientUpdatedPlannerPermissions',
  ClientUserEventDateChangeRequested = 'ClientUserEventDateChangeRequested',
  ClientUserEventDateChanged = 'ClientUserEventDateChanged',
  ConnectAccountRequestsVerification = 'ConnectAccountRequestsVerification',
  ConnectAccountUpdated = 'ConnectAccountUpdated',
  ConnectionCancelledByClient = 'ConnectionCancelledByClient',
  ConnectionCancelledByVendor = 'ConnectionCancelledByVendor',
  ConnectionPendingFromInviteByClient = 'ConnectionPendingFromInviteByClient',
  ConnectionPendingFromInviteByVendor = 'ConnectionPendingFromInviteByVendor',
  ConnectionRequestApprovedByClient = 'ConnectionRequestApprovedByClient',
  ConnectionRequestApprovedByPlannerOnBehalfOfClient = 'ConnectionRequestApprovedByPlannerOnBehalfOfClient',
  ConnectionRequestApprovedByVendor = 'ConnectionRequestApprovedByVendor',
  ConnectionRequestCancelledByPlannerOnBehalfOfClient = 'ConnectionRequestCancelledByPlannerOnBehalfOfClient',
  ConnectionRequestDeclinedByClient = 'ConnectionRequestDeclinedByClient',
  ConnectionRequestDeclinedByPlannerOnBehalfOfClient = 'ConnectionRequestDeclinedByPlannerOnBehalfOfClient',
  ConnectionRequestDeclinedByVendor = 'ConnectionRequestDeclinedByVendor',
  ConnectionRequestedByClient = 'ConnectionRequestedByClient',
  ConnectionRequestedByPlannerOnBehalfOfClient = 'ConnectionRequestedByPlannerOnBehalfOfClient',
  ConnectionRequestedByVendor = 'ConnectionRequestedByVendor',
  ContactCreatedByClient = 'ContactCreatedByClient',
  ContactCreatedByPlannerOboClient = 'ContactCreatedByPlannerOBOClient',
  ContactCreatedByVendor = 'ContactCreatedByVendor',
  ContactUpdated = 'ContactUpdated',
  ContractApproved = 'ContractApproved',
  ContractDeleted = 'ContractDeleted',
  ContractDownloadFailed = 'ContractDownloadFailed',
  ContractDueSoon = 'ContractDueSoon',
  ContractExecuted = 'ContractExecuted',
  ContractPastDue = 'ContractPastDue',
  ContractPastDueForCountersigning = 'ContractPastDueForCountersigning',
  ContractReadyForCountersigning = 'ContractReadyForCountersigning',
  ContractReadyForVendorSignature = 'ContractReadyForVendorSignature',
  ContractResent = 'ContractResent',
  ContractResentToCountersigner = 'ContractResentToCountersigner',
  ContractSent = 'ContractSent',
  ContractSentAwaiting = 'ContractSentAwaiting',
  ContractSignedByClient = 'ContractSignedByClient',
  ContractSignedByCountersigner = 'ContractSignedByCountersigner',
  ContractSignedByCustomer = 'ContractSignedByCustomer',
  ContractSignedByGuest = 'ContractSignedByGuest',
  ContractSignedByPlanner = 'ContractSignedByPlanner',
  ContractTemplateCompleted = 'ContractTemplateCompleted',
  ContractTokenRefreshed = 'ContractTokenRefreshed',
  ContractUpdated = 'ContractUpdated',
  ContractViewed = 'ContractViewed',
  ContractVoided = 'ContractVoided',
  CustomerAddedNewPaymentMethod = 'CustomerAddedNewPaymentMethod',
  DocumentAdded = 'DocumentAdded',
  DocumentDeleted = 'DocumentDeleted',
  DocumentFileReplaced = 'DocumentFileReplaced',
  DocumentRenamed = 'DocumentRenamed',
  DocumentSharedWithCustomer = 'DocumentSharedWithCustomer',
  DocumentSharedWithOrg = 'DocumentSharedWithOrg',
  DocumentSharedWithOrgByPlannerObo = 'DocumentSharedWithOrgByPlannerOBO',
  DocumentUnsharedWithCustomer = 'DocumentUnsharedWithCustomer',
  DocumentUnsharedWithOrg = 'DocumentUnsharedWithOrg',
  DocumentUnsharedWithOrgByPlannerObo = 'DocumentUnsharedWithOrgByPlannerOBO',
  DocumentViewed = 'DocumentViewed',
  EmailAddressUpdated = 'EmailAddressUpdated',
  EmailVerificationEmailCreated = 'EmailVerificationEmailCreated',
  EventResolvedBySuperAdmin = 'EventResolvedBySuperAdmin',
  InquiryArchived = 'InquiryArchived',
  InquiryConvertedToContact = 'InquiryConvertedToContact',
  InquiryCreated = 'InquiryCreated',
  InquiryDeleted = 'InquiryDeleted',
  InquiryReceived = 'InquiryReceived',
  InquiryUnArchived = 'InquiryUnArchived',
  InquiryUpdated = 'InquiryUpdated',
  InquiryViewed = 'InquiryViewed',
  InstallmentPaymentAutopaidInThreeDays = 'InstallmentPaymentAutopaidInThreeDays',
  InstallmentPaymentDueInThreeDays = 'InstallmentPaymentDueInThreeDays',
  InstallmentPaymentDueSoon = 'InstallmentPaymentDueSoon',
  InstallmentPaymentFailed = 'InstallmentPaymentFailed',
  InstallmentPaymentRecordedOfflineByVendor = 'InstallmentPaymentRecordedOfflineByVendor',
  InstallmentPaymentRequestedByClient = 'InstallmentPaymentRequestedByClient',
  InstallmentPaymentRequestedByGuest = 'InstallmentPaymentRequestedByGuest',
  InstallmentPaymentRequestedByPlanner = 'InstallmentPaymentRequestedByPlanner',
  InstallmentPaymentRequestedBySchedule = 'InstallmentPaymentRequestedBySchedule',
  InstallmentPaymentRescheduled = 'InstallmentPaymentRescheduled',
  InstallmentPaymentSucceeded = 'InstallmentPaymentSucceeded',
  InstallmentPaymentUpdatedOfflineByVendor = 'InstallmentPaymentUpdatedOfflineByVendor',
  InstallmentRefundAwaitingReversal = 'InstallmentRefundAwaitingReversal',
  InstallmentRefundCompleted = 'InstallmentRefundCompleted',
  InstallmentRefundFailed = 'InstallmentRefundFailed',
  InstallmentRefundRecordedOfflineByVendor = 'InstallmentRefundRecordedOfflineByVendor',
  InstallmentRefundRequested = 'InstallmentRefundRequested',
  InstallmentRefundReversalUnavailable = 'InstallmentRefundReversalUnavailable',
  InstallmentRefundUpdatedOfflineByVendor = 'InstallmentRefundUpdatedOfflineByVendor',
  InstallmentScheduledPaymentFailed = 'InstallmentScheduledPaymentFailed',
  InvitationSentByUser = 'InvitationSentByUser',
  InvoiceApproved = 'InvoiceApproved',
  InvoiceCourtesyEmailCreated = 'InvoiceCourtesyEmailCreated',
  InvoiceDeleted = 'InvoiceDeleted',
  InvoiceFullyPaid = 'InvoiceFullyPaid',
  InvoicePastDue = 'InvoicePastDue',
  InvoicePaymentsDescheduledByBlocked = 'InvoicePaymentsDescheduledByBlocked',
  InvoicePaymentsDescheduledByClient = 'InvoicePaymentsDescheduledByClient',
  InvoicePaymentsDescheduledByFailure = 'InvoicePaymentsDescheduledByFailure',
  InvoicePaymentsDescheduledByPlanner = 'InvoicePaymentsDescheduledByPlanner',
  InvoicePaymentsScheduledByClient = 'InvoicePaymentsScheduledByClient',
  InvoicePaymentsScheduledByPlanner = 'InvoicePaymentsScheduledByPlanner',
  InvoiceResent = 'InvoiceResent',
  InvoiceSent = 'InvoiceSent',
  InvoiceSentAwaiting = 'InvoiceSentAwaiting',
  InvoiceUpdated = 'InvoiceUpdated',
  InvoiceViewed = 'InvoiceViewed',
  InvoiceVoided = 'InvoiceVoided',
  MemberAssignedContact = 'MemberAssignedContact',
  NewGuestContractTokenRequested = 'NewGuestContractTokenRequested',
  OnboardingEmailVerificationEmailCreated = 'OnboardingEmailVerificationEmailCreated',
  OrgMemberDeactivated = 'OrgMemberDeactivated',
  OrgMemberReactivated = 'OrgMemberReactivated',
  OrganizationAddedNewPayFromAccount = 'OrganizationAddedNewPayFromAccount',
  OrganizationAddedNewPayToAccount = 'OrganizationAddedNewPayToAccount',
  OrganizationCreated = 'OrganizationCreated',
  PasswordResetEmailCreated = 'PasswordResetEmailCreated',
  PaymentBlockedByStripeRequirements = 'PaymentBlockedByStripeRequirements',
  PayoutFailed = 'PayoutFailed',
  PayoutWithdrawalFailed = 'PayoutWithdrawalFailed',
  PlannerUserUpdatesProfile = 'PlannerUserUpdatesProfile',
  ProposalAcceptedByCustomer = 'ProposalAcceptedByCustomer',
  ProposalAcceptedByPlanner = 'ProposalAcceptedByPlanner',
  ProposalCreated = 'ProposalCreated',
  ProposalDeleted = 'ProposalDeleted',
  ProposalDueSoon = 'ProposalDueSoon',
  ProposalPastDue = 'ProposalPastDue',
  ProposalResent = 'ProposalResent',
  ProposalSent = 'ProposalSent',
  ProposalSentAwaiting = 'ProposalSentAwaiting',
  ProposalUpdated = 'ProposalUpdated',
  ProposalViewed = 'ProposalViewed',
  ProposalVoided = 'ProposalVoided',
  QuickPaymentLinkCreatedOrCopied = 'QuickPaymentLinkCreatedOrCopied',
  QuickPaymentPaid = 'QuickPaymentPaid',
  SubscriptionActivated = 'SubscriptionActivated',
  SubscriptionCanceled = 'SubscriptionCanceled',
  SubscriptionChanged = 'SubscriptionChanged',
  SubscriptionPaymentFailed = 'SubscriptionPaymentFailed',
  SubscriptionPaymentSucceeded = 'SubscriptionPaymentSucceeded',
  SubscriptionRenewalFirstReminder = 'SubscriptionRenewalFirstReminder',
  SubscriptionRenewalPaid = 'SubscriptionRenewalPaid',
  SubscriptionRenewalSecondReminder = 'SubscriptionRenewalSecondReminder',
  UserInvitedAsClient = 'UserInvitedAsClient',
  UserInvitedAsOrgMember = 'UserInvitedAsOrgMember',
  UserInvitedAsOrganization = 'UserInvitedAsOrganization',
  UserInvitedAsOrganizationByPlannerOnBehalfOfClient = 'UserInvitedAsOrganizationByPlannerOnBehalfOfClient',
  UserJoinedOrganization = 'UserJoinedOrganization',
  UserRequestedMfaToken = 'UserRequestedMFAToken',
  VendorDisputeOpened = 'VendorDisputeOpened',
  VendorDisputeRefundCompleted = 'VendorDisputeRefundCompleted',
  VendorDisputeRefundFailed = 'VendorDisputeRefundFailed',
  VendorDisputeWonByClient = 'VendorDisputeWonByClient',
  VendorDisputeWonByVendor = 'VendorDisputeWonByVendor',
  VendorRequestedContactActivation = 'VendorRequestedContactActivation',
  VendorRequestedPlannerPermissions = 'VendorRequestedPlannerPermissions'
}

export type EventOrderByInput = {
  category?: InputMaybe<SortOrderType>;
  clientUserEmailProcessed?: InputMaybe<SortOrderType>;
  createdAt?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  message?: InputMaybe<SortOrderType>;
  metadata?: InputMaybe<SortOrderType>;
  orgUserEmailProcessed?: InputMaybe<SortOrderType>;
  organizationEmailProcessed?: InputMaybe<SortOrderType>;
  plannerUserEmailProcessed?: InputMaybe<SortOrderType>;
  referenceID?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type EventWhereCategoryInput = {
  in?: InputMaybe<Array<EventCategory>>;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  category?: InputMaybe<EventWhereCategoryInput>;
  clientUser?: InputMaybe<ClientUserWhereInput>;
  clientUserEmailProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  consumingUsers?: InputMaybe<UserWhereInput>;
  contact?: InputMaybe<ContactWhereInput>;
  contacts?: InputMaybe<ContactWhereInput>;
  contract?: InputMaybe<ContractWhereInput>;
  contractTemplate?: InputMaybe<ContractTemplateWhereInput>;
  createdAt?: InputMaybe<CreatedAtGreaterThan>;
  dismissingUsers?: InputMaybe<UserWhereInput>;
  document?: InputMaybe<DocumentWhereInput>;
  guestUser?: InputMaybe<GuestUserWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invitation?: InputMaybe<InvitationWhereInput>;
  invoice?: InputMaybe<InvoiceWhereInput>;
  message?: InputMaybe<Scalars['String']['input']>;
  orgUser?: InputMaybe<OrgUserWhereInput>;
  orgUserEmailProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  organizationEmailProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentInstallment?: InputMaybe<PaymentInstallmentWhereInput>;
  paymentInstallments?: InputMaybe<EventWherePaymentInstallmentsInput>;
  plannerUser?: InputMaybe<OrgUserWhereInput>;
  plannerUserEmailProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  proposal?: InputMaybe<ProposalWhereInput>;
  referenceID?: InputMaybe<Scalars['String']['input']>;
  resolvedBy?: InputMaybe<EventWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vendor?: InputMaybe<OrganizationWhereInput>;
};

export type EventWherePaymentInstallmentsInput = {
  every?: InputMaybe<PaymentInstallmentWhereInput>;
  none?: InputMaybe<PaymentInstallmentWhereInput>;
  some?: InputMaybe<PaymentInstallmentWhereInput>;
};

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ExportHistoryInput = {
  end: Scalars['DateTime']['input'];
  fileType: Scalars['String']['input'];
  paymentSourceID?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type ExportInquiriesDataWhereInput = {
  endDate: Scalars['DateTime']['input'];
  include: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  timezone: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type FServiceWhereOrganizationsInput = {
  every?: InputMaybe<OrganizationWhereInput>;
  none?: InputMaybe<OrganizationWhereInput>;
  some?: InputMaybe<OrganizationWhereInput>;
};

export enum FeatureCategory {
  CrmBetaLeadTrackingV1 = 'CRMBetaLeadTrackingV1',
  DropboxSignApp = 'DropboxSignApp',
  GoogleDrive = 'GoogleDrive',
  MuiWallet = 'MUIWallet',
  TgIntegrationV1 = 'TGIntegrationV1',
  TestFeature = 'TestFeature'
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  active: Scalars['Boolean']['output'];
  category: FeatureCategory;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  testingGroups?: Maybe<Array<TestingGroup>>;
  type: FeatureFlagType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum FeatureFlagType {
  Release = 'Release',
  TestingGroup = 'TestingGroup'
}

export type FeatureFlagWhereGroupsInput = {
  every?: InputMaybe<TestingGroupWhereInput>;
  none?: InputMaybe<TestingGroupWhereInput>;
  some?: InputMaybe<TestingGroupWhereInput>;
};

export type FeatureFlagWhereInput = {
  AND?: InputMaybe<Array<FeatureFlagWhereInput>>;
  NOT?: InputMaybe<Array<FeatureFlagWhereInput>>;
  OR?: InputMaybe<Array<FeatureFlagWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<FeatureCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<FeatureFlagWhereGroupsInput>;
  type?: InputMaybe<FeatureFlagType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum FileSourceType {
  Google = 'Google',
  LocalStorage = 'LocalStorage'
}

export enum FileType {
  Avi = 'AVI',
  Bmp = 'BMP',
  Csv = 'CSV',
  Doc = 'DOC',
  Docx = 'DOCX',
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Mp3 = 'MP3',
  Mp4 = 'MP4',
  Mpeg = 'MPEG',
  Odp = 'ODP',
  Ods = 'ODS',
  Pdf = 'PDF',
  Png = 'PNG',
  Ppt = 'PPT',
  Pptx = 'PPTX',
  Svg = 'SVG',
  Tif = 'TIF',
  Webm = 'WEBM',
  Xls = 'XLS',
  Xlsx = 'XLSX'
}

export type Folder = {
  __typename?: 'Folder';
  childFolders?: Maybe<Array<Folder>>;
  createdAt: Scalars['DateTime']['output'];
  deletionHash?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  folderType: FolderType;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  parentFolder?: Maybe<Folder>;
  status: FolderStatus;
  updatedAt: Scalars['DateTime']['output'];
};


export type FolderChildFoldersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FolderOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FolderWhereInput>;
};


export type FolderDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};

export type FolderContentsOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  name?: InputMaybe<SortOrderType>;
};

export type FolderCreateCustomInput = {
  name: Scalars['String']['input'];
  parentFolder?: InputMaybe<FolderCreateOneWithoutParentFolderInput>;
};

export type FolderCreateOneWithoutDocumentsInput = {
  connect?: InputMaybe<FolderWhereUniqueInput>;
};

export type FolderCreateOneWithoutParentFolderInput = {
  connect?: InputMaybe<FolderWhereUniqueInput>;
};

export type FolderOrderByInput = {
  childFolders?: InputMaybe<FolderOrderByInput>;
  createdAt?: InputMaybe<SortOrderType>;
  deletionHash?: InputMaybe<SortOrderType>;
  documents?: InputMaybe<DocumentOrderByInput>;
  folderType?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  metadata?: InputMaybe<SortOrderType>;
  name?: InputMaybe<SortOrderType>;
  orderBy?: InputMaybe<FolderOrderByInput>;
  status?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export enum FolderStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum FolderType {
  Internal = 'Internal'
}

export type FolderWhereChildFoldersInput = {
  every?: InputMaybe<FolderWhereInput>;
  none?: InputMaybe<FolderWhereInput>;
  some?: InputMaybe<FolderWhereInput>;
};

export type FolderWhereDocumentsInput = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type FolderWhereInput = {
  AND?: InputMaybe<Array<FolderWhereInput>>;
  NOT?: InputMaybe<Array<FolderWhereInput>>;
  OR?: InputMaybe<Array<FolderWhereInput>>;
  childFolders?: InputMaybe<FolderWhereChildFoldersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletionHash?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<FolderWhereDocumentsInput>;
  folderType?: InputMaybe<FolderType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationWhereInput>;
  parentFolder?: InputMaybe<FolderWhereInput>;
  status?: InputMaybe<FolderStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FolderWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type FoldersWhereCustomInput = {
  OR?: InputMaybe<Array<FoldersWhereCustomInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type FormattedGlobalVariables = {
  __typename?: 'FormattedGlobalVariables';
  AutomatedNotificationsSupportUrl?: Maybe<Scalars['String']['output']>;
  HelpUrl?: Maybe<Scalars['String']['output']>;
  InquiryFormSupportUrl?: Maybe<Scalars['String']['output']>;
  MaxFoldersPerLayer?: Maybe<Scalars['Int']['output']>;
  PlannerPermissionsHelpUrl?: Maybe<Scalars['String']['output']>;
  QuickPaySupportUrl?: Maybe<Scalars['String']['output']>;
  StripeLegalUrl?: Maybe<Scalars['String']['output']>;
  TGIntegrationURL?: Maybe<Scalars['String']['output']>;
  TGLogo?: Maybe<Scalars['String']['output']>;
  TermsOfServiceUrl?: Maybe<Scalars['String']['output']>;
};

export type GetInvoicesForCustomerInput = {
  OR?: InputMaybe<Array<InputMaybe<QueryFieldsForInvoiceSearch>>>;
  contact?: InputMaybe<ContactInputForGetInvoicesForCustomer>;
};

export type GuestUser = {
  __typename?: 'GuestUser';
  createdAt: Scalars['DateTime']['output'];
  events?: Maybe<Array<Event>>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type GuestUserEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type GuestUserForGetContractWhere = {
  __typename?: 'GuestUserForGetContractWhere';
  id: Scalars['ID']['output'];
  user: BaseUserForGetContractWhere;
};

export type GuestUserWhereEventsInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type GuestUserWhereInput = {
  AND?: InputMaybe<Array<GuestUserWhereInput>>;
  NOT?: InputMaybe<Array<GuestUserWhereInput>>;
  OR?: InputMaybe<Array<GuestUserWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<GuestUserWhereEventsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserWhereInput>;
};

export type ImpersonatingUser = {
  __typename?: 'ImpersonatingUser';
  id: Scalars['ID']['output'];
  superAdminCapabilities?: Maybe<Array<Maybe<SuperAdminCapability>>>;
};

export type InquiriesForOrganization = {
  __typename?: 'InquiriesForOrganization';
  _count: Scalars['Int']['output'];
  inquiries: Array<Inquiry>;
};

export type InquiriesForOrganizationWhereInput = {
  AND?: InputMaybe<Array<InquiriesForOrganizationWhereInput>>;
  NOT?: InputMaybe<Array<InquiriesForOrganizationWhereInput>>;
  OR?: InputMaybe<Array<InquiriesForOrganizationWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  contact?: InputMaybe<ContactWhereUniqueInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InquiryStatusInput>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Inquiry = {
  __typename?: 'Inquiry';
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  status: InquiryStatus;
  updatedAt: Scalars['DateTime']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
};

export type InquiryCreateInput = {
  email: Scalars['String']['input'];
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type InquiryForm = {
  __typename?: 'InquiryForm';
  apiKey: Scalars['String']['output'];
  fields?: Maybe<Array<InquiryFormField>>;
  instructions?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  styles?: Maybe<Array<InquiryFormStyle>>;
};

export type InquiryFormDataFieldInput = {
  label: Scalars['String']['input'];
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  order: Scalars['Int']['input'];
  required: Scalars['Boolean']['input'];
  services?: InputMaybe<Scalars['Json']['input']>;
  type: InquiryFormFieldType;
};

export type InquiryFormDataInput = {
  apiKey: Scalars['String']['input'];
  fields?: InputMaybe<Array<InquiryFormDataFieldInput>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  styles?: InputMaybe<Array<InquiryFormDataStyleInput>>;
};

export type InquiryFormDataStyleInput = {
  type: InquiryFormStyleType;
  value: Scalars['String']['input'];
};

export type InquiryFormField = {
  __typename?: 'InquiryFormField';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  order: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  services?: Maybe<Scalars['Json']['output']>;
  type: InquiryFormFieldType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum InquiryFormFieldType {
  AdditionalCustomText = 'additionalCustomText',
  Budget = 'budget',
  CustomText = 'customText',
  Email = 'email',
  EventDate = 'eventDate',
  FirstNameOne = 'firstNameOne',
  FirstNameTwo = 'firstNameTwo',
  GuestCount = 'guestCount',
  LastNameOne = 'lastNameOne',
  LastNameTwo = 'lastNameTwo',
  Phone = 'phone',
  Services = 'services'
}

export type InquiryFormStyle = {
  __typename?: 'InquiryFormStyle';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: InquiryFormStyleType;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export enum InquiryFormStyleType {
  ButtonFill = 'buttonFill',
  ButtonText = 'buttonText',
  Corners = 'corners',
  FieldInputText = 'fieldInputText',
  FieldLabel = 'fieldLabel',
  FieldOutline = 'fieldOutline',
  FontFamily = 'fontFamily',
  FontSize = 'fontSize',
  Instructions = 'instructions'
}

export type InquiryMergeInput = {
  contactId: Scalars['String']['input'];
  inquiryId: Scalars['String']['input'];
};

export type InquiryOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  email?: InputMaybe<SortOrderType>;
  eventDate?: InputMaybe<SortOrderType>;
  firstName?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  lastName?: InputMaybe<SortOrderType>;
  notes?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
};

export enum InquiryStatus {
  Budget = 'Budget',
  Converted = 'Converted',
  Duplicate = 'Duplicate',
  New = 'New',
  NotAvailable = 'NotAvailable',
  Other = 'Other',
  Spam = 'Spam',
  Unresponsive = 'Unresponsive',
  Viewed = 'Viewed'
}

export type InquiryStatusInput = {
  in?: InputMaybe<Array<InquiryStatus>>;
};

export type InquirySubmissionInput = {
  email: Scalars['String']['input'];
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type InquiryUpdateDataInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type InquiryWhereInput = {
  every?: InputMaybe<InquiryWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  none?: InputMaybe<InquiryWhereInput>;
  some?: InputMaybe<InquiryWhereInput>;
};

export type InquiryWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export enum InstallmentStatus {
  Disputed = 'Disputed',
  FullyPaid = 'FullyPaid',
  FullyPaidPartiallyRefunded = 'FullyPaidPartiallyRefunded',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Refunding = 'Refunding',
  Unpaid = 'Unpaid'
}

export type Invitation = {
  __typename?: 'Invitation';
  contact?: Maybe<CustomContact>;
  createdAt: Scalars['DateTime']['output'];
  emailAddress: Scalars['String']['output'];
  events?: Maybe<Array<Event>>;
  id: Scalars['ID']['output'];
  invitationType: InviteEnum;
  inviter: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  recipientFirstName: Scalars['String']['output'];
  recipientLastName?: Maybe<Scalars['String']['output']>;
  sendWithContract: Scalars['Boolean']['output'];
  sendWithInvoice: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type InvitationEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type InvitationCustomCreateInput = {
  emailAddress: Scalars['String']['input'];
  invitationType: InviteEnum;
  onBehalfOfClient?: InputMaybe<ClientUserWhereUniqueInput>;
  recipientFirstName: Scalars['String']['input'];
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  sendWithContract: Scalars['Boolean']['input'];
  sendWithInvoice: Scalars['Boolean']['input'];
};

export type InvitationWhereCustomInput = {
  id: Scalars['ID']['input'];
};

export type InvitationWhereEventsInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type InvitationWhereForGetInvoicesForCustomer = {
  OR?: InputMaybe<Array<InputMaybe<InvitationWhereForGetInvoicesForCustomer>>>;
  contact?: InputMaybe<ContactForSearchFilter>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress_contains?: InputMaybe<Scalars['String']['input']>;
  recipientFirstName?: InputMaybe<Scalars['String']['input']>;
  recipientFirstName_contains?: InputMaybe<Scalars['String']['input']>;
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  recipientLastName_contains?: InputMaybe<Scalars['String']['input']>;
};

export type InvitationWhereInput = {
  AND?: InputMaybe<Array<InvitationWhereInput>>;
  NOT?: InputMaybe<Array<InvitationWhereInput>>;
  OR?: InputMaybe<Array<InvitationWhereInput>>;
  contact?: InputMaybe<ContactWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress_contains?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<InvitationWhereEventsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invitationType?: InputMaybe<InviteEnum>;
  inviter?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationWhereInput>;
  recipientFirstName?: InputMaybe<Scalars['String']['input']>;
  recipientFirstName_contains?: InputMaybe<Scalars['String']['input']>;
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  recipientLastName_contains?: InputMaybe<Scalars['String']['input']>;
  sendWithContract?: InputMaybe<Scalars['Boolean']['input']>;
  sendWithInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvitationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type InvitationsWhereCustomInput = {
  invitationType?: InputMaybe<InviteEnum>;
  inviter: Scalars['String']['input'];
  pendingInvitations?: InputMaybe<InvitationWhereInput>;
};

export enum InviteEnum {
  NewClient = 'NewClient',
  NewOrg = 'NewOrg',
  NewOrgMember = 'NewOrgMember'
}

export type Invoice = {
  __typename?: 'Invoice';
  allowsGratuity: Scalars['Boolean']['output'];
  amountOutstanding?: Maybe<Scalars['Int']['output']>;
  approvedByPlanner?: Maybe<Scalars['Boolean']['output']>;
  archivedByClient?: Maybe<Scalars['Boolean']['output']>;
  archivedByVendor?: Maybe<Scalars['Boolean']['output']>;
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  automatedRemindersEnabled: Scalars['Boolean']['output'];
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  discountAmount?: Maybe<Scalars['Int']['output']>;
  discountIsPercentage?: Maybe<Scalars['Boolean']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  events?: Maybe<Array<Event>>;
  gratuityAmount: Scalars['Int']['output'];
  guestToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceTemplate?: Maybe<InvoiceTemplate>;
  isPastDue: Scalars['Boolean']['output'];
  lineItems?: Maybe<Array<LineItem>>;
  notes?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  paymentInstallments?: Maybe<Array<PaymentInstallment>>;
  quickPayment?: Maybe<QuickPayment>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentByUser?: Maybe<OrgUser>;
  status: InvoiceStatus;
  title: Scalars['String']['output'];
  totalAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadAttachment?: Maybe<Scalars['String']['output']>;
  viewedByClient?: Maybe<Scalars['Boolean']['output']>;
};


export type InvoiceEventsArgs = {
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type InvoiceLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LineItemWhereInput>;
};


export type InvoicePaymentInstallmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentInstallmentWhereInput>;
};

export type InvoiceAutopayUpdateCustomInput = {
  autopay: Scalars['Boolean']['input'];
  scheduledPaymentMethod?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCreateInput = {
  allowsGratuity?: InputMaybe<Scalars['Boolean']['input']>;
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contact?: InputMaybe<ContactWhereUniqueInput>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceTemplate?: InputMaybe<InvoiceTemplateConnectOneInput>;
  lineItems?: InputMaybe<Array<LineItemDataInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentInstallments?: InputMaybe<Array<PaymentInstallmentDataInput>>;
  title: Scalars['String']['input'];
  uploadAttachment?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceExportInput = {
  timeZone: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceForGetContractWhere = {
  __typename?: 'InvoiceForGetContractWhere';
  amountOutstanding?: Maybe<Scalars['Int']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  guestToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentInstallments?: Maybe<Array<PaymentInstallmentsForGetContractWhere>>;
  status: InvoiceStatus;
  title: Scalars['String']['output'];
};


export type InvoiceForGetContractWherePaymentInstallmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PaymentInstallmentOrderByInputForGetContractWhere>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentInstallmentWhereInputForGetContractWhere>;
};

export type InvoiceForOfflinePaymentHistory = {
  __typename?: 'InvoiceForOfflinePaymentHistory';
  clientUser?: Maybe<ClientForOfflineHistory>;
  gratuityAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type InvoiceForPaymentHistoryItem = {
  __typename?: 'InvoiceForPaymentHistoryItem';
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InvoiceOrOrganizationInput = {
  invoice?: InputMaybe<InvoiceWhereUniqueInput>;
  organization?: InputMaybe<OrganizationWhereUniqueInput>;
};

export type InvoiceOrRedirect = Invoice | RedirectUrl;

export type InvoiceOrStripeUser = Invoice | StripeUser;

export type InvoiceOrderByInput = {
  approvedByPlanner?: InputMaybe<SortOrderType>;
  archivedByClient?: InputMaybe<SortOrderType>;
  archivedByVendor?: InputMaybe<SortOrderType>;
  contact?: InputMaybe<ContactOrderByInput>;
  createdAt?: InputMaybe<SortOrderType>;
  discountAmount?: InputMaybe<SortOrderType>;
  discountIsPercentage?: InputMaybe<SortOrderType>;
  discountPercentage?: InputMaybe<SortOrderType>;
  dueDate?: InputMaybe<SortOrderType>;
  eventDate?: InputMaybe<SortOrderType>;
  gratuityAmount?: InputMaybe<SortOrderType>;
  guestToken?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  isPastDue?: InputMaybe<SortOrderType>;
  quickPayment?: InputMaybe<QuickPaymentOrderByInput>;
  sentAt?: InputMaybe<SortOrderType>;
  sentByUser?: InputMaybe<OrgUserOrderByInput>;
  status?: InputMaybe<SortOrderType>;
  title?: InputMaybe<SortOrderType>;
  totalAmount?: InputMaybe<SortOrderType>;
  type?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  uploadAttachment?: InputMaybe<SortOrderType>;
  viewedByClient?: InputMaybe<SortOrderType>;
};

export enum InvoiceStatus {
  Disputed = 'Disputed',
  Draft = 'Draft',
  FullyPaid = 'FullyPaid',
  FullyPaidPartiallyRefunded = 'FullyPaidPartiallyRefunded',
  FullyRefunded = 'FullyRefunded',
  Hidden = 'Hidden',
  PartiallyPaid = 'PartiallyPaid',
  Sent = 'Sent',
  Void = 'Void'
}

export type InvoiceTemplate = {
  __typename?: 'InvoiceTemplate';
  allowsGratuity: Scalars['Boolean']['output'];
  countOfConnectedInvoices: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  discountAmount: Scalars['Int']['output'];
  discountIsPercentage: Scalars['Boolean']['output'];
  discountPercentage: Scalars['Float']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Invoice>>;
  lineItems: Array<InvoiceTemplateLineItem>;
  notes?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  paymentInstallments: Array<InvoiceTemplatePaymentInstallment>;
  status: InvoiceTemplateStatus;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceTemplateConnectOneInput = {
  connect: InvoiceTemplateWhereUniqueInput;
};

export type InvoiceTemplateCreateInput = {
  allowsGratuity: Scalars['Boolean']['input'];
  discountAmount: Scalars['Int']['input'];
  discountIsPercentage: Scalars['Boolean']['input'];
  discountPercentage: Scalars['Float']['input'];
  lineItems: Array<LineItemDataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentInstallments: Array<PaymentInstallmentDataInput>;
  title: Scalars['String']['input'];
};

export type InvoiceTemplateLineItem = {
  __typename?: 'InvoiceTemplateLineItem';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invoiceTemplate: InvoiceTemplate;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rate: Scalars['Int']['output'];
  taxPercentage: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceTemplateOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type InvoiceTemplatePaymentInstallment = {
  __typename?: 'InvoiceTemplatePaymentInstallment';
  amountDue: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  enteredAsPercentageAmount: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceTemplate: InvoiceTemplate;
  name?: Maybe<Scalars['String']['output']>;
  percentageDue: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum InvoiceTemplateStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type InvoiceTemplateUpdateInput = {
  allowsGratuity: Scalars['Boolean']['input'];
  deleteAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  discountAmount: Scalars['Int']['input'];
  discountIsPercentage: Scalars['Boolean']['input'];
  discountPercentage: Scalars['Float']['input'];
  lineItems: Array<LineItemDataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentInstallments: Array<PaymentInstallmentDataInput>;
  title: Scalars['String']['input'];
};

export type InvoiceTemplateWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type InvoiceTemplates = {
  __typename?: 'InvoiceTemplates';
  _count: Scalars['Int']['output'];
  invoiceTemplates: Array<InvoiceTemplate>;
};

export type InvoiceTokenInput = {
  token: Scalars['String']['input'];
};

export type InvoiceUpdateInput = {
  allowsGratuity?: InputMaybe<Scalars['Boolean']['input']>;
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contact?: InputMaybe<ContactWhereUniqueInput>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceTemplate?: InputMaybe<InvoiceTemplateConnectOneInput>;
  lineItems?: InputMaybe<Array<LineItemDataInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentInstallments?: InputMaybe<Array<PaymentInstallmentDataInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploadAttachment?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceUrl = {
  __typename?: 'InvoiceUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type InvoiceWhereEventsInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  approvedByPlanner?: InputMaybe<Scalars['Boolean']['input']>;
  archivedByClient?: InputMaybe<Scalars['Boolean']['input']>;
  contact?: InputMaybe<ContactWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<InvoiceWhereEventsInput>;
  gratuityAmount?: InputMaybe<Scalars['Int']['input']>;
  guestToken?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPastDue?: InputMaybe<Scalars['Boolean']['input']>;
  lineItems?: InputMaybe<InvoiceWhereLineItemsInput>;
  organization?: InputMaybe<OrganizationWhereInput>;
  paymentInstallments?: InputMaybe<InvoiceWherePaymentInstallmentsInput>;
  sentAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentByUser?: InputMaybe<OrgUserWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
  status?: InputMaybe<InvoiceStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploadAttachment?: InputMaybe<Scalars['String']['input']>;
  uploadAttachment_contains?: InputMaybe<Scalars['String']['input']>;
  viewedByClient?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceWhereInputForGetContractWhere = {
  status?: InputMaybe<InvoiceStatus>;
};

export type InvoiceWhereLineItemsInput = {
  every?: InputMaybe<LineItemWhereInput>;
  none?: InputMaybe<LineItemWhereInput>;
  some?: InputMaybe<LineItemWhereInput>;
};

export type InvoiceWherePaymentInstallmentsInput = {
  every?: InputMaybe<PaymentInstallmentWhereInput>;
  none?: InputMaybe<PaymentInstallmentWhereInput>;
  some?: InputMaybe<PaymentInstallmentWhereInput>;
};

export type InvoiceWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type Invoices = {
  __typename?: 'Invoices';
  _count: Scalars['Int']['output'];
  invoices: Array<Invoice>;
};

export type InvoicesForCustomer = {
  __typename?: 'InvoicesForCustomer';
  _count: Scalars['Int']['output'];
  invoices: Array<Invoice>;
};

export type JustEmail = {
  __typename?: 'JustEmail';
  email: Scalars['String']['output'];
};

export type JustUserWithEmail = {
  __typename?: 'JustUserWithEmail';
  user: JustEmail;
};

export type LineItem = {
  __typename?: 'LineItem';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invoice: Invoice;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rate: Scalars['Int']['output'];
  taxPercentage: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LineItemDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
  rate: Scalars['Int']['input'];
  taxPercentage: Scalars['Float']['input'];
};

export type LineItemWhereInput = {
  AND?: InputMaybe<Array<LineItemWhereInput>>;
  NOT?: InputMaybe<Array<LineItemWhereInput>>;
  OR?: InputMaybe<Array<LineItemWhereInput>>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoice?: InputMaybe<InvoiceWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  taxPercentage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MarkupUrl = {
  __typename?: 'MarkupUrl';
  url: Scalars['String']['output'];
};

export type MoveFolderCustomInput = {
  parentFolder: FolderCreateOneWithoutParentFolderInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptProposal: Proposal;
  activateContact: Contact;
  activateProposalTemplate: ProposalTemplate;
  addOrganizationToGroup: TestingGroup;
  addUserToGroup: TestingGroup;
  addUserToOrgAdmins: Organization;
  approveContract: CustomContract;
  approveInvoice: Invoice;
  assignMemberToContact: Contact;
  assignPlannerPermissions: Contact;
  blockUser: Scalars['Boolean']['output'];
  cancelContact: Contact;
  cancelVendorStripeSubscription: SubscriptionObject;
  changePassword: Scalars['Boolean']['output'];
  changeVendorSubscription: SubscriptionObjectOrRedirect;
  convertInquiry: Inquiry;
  copyDocumentFromCompanyFolderToCustomerFolder: CustomDocumentWithDownloadUrl;
  createClientUser: ClientUser;
  createContactAsClient: Contact;
  createContactAsVendor: Contact;
  createContract: CustomContract;
  createContractMarkupUrl: MarkupUrl;
  createContractSigningUrl: SigningUrl;
  createContractTemplate: ContractTemplate;
  createContractTemplateMarkupUrl: ContractTemplateMarkupUrl;
  createCoupon: Coupon;
  createCustomerPlaidBankAccount: Source;
  createCustomerStripeBankAccount: Source;
  createCustomerStripeCard: SourceOrRedirect;
  createDocument: CustomDocumentWithUploadUrl;
  createDraftInvoice: Invoice;
  createExternalAccount?: Maybe<Source>;
  createFolder?: Maybe<CustomFolder>;
  createInquiry: Inquiry;
  createInquiryForm: InquiryForm;
  createInvitation: Invitation;
  createInvoiceTemplate: InvoiceTemplate;
  createMfaToken: Scalars['Boolean']['output'];
  createOrGetQuickPaymentLink: QuickPaymentLink;
  createOrUpdateContactForCustomer: Contact;
  createOrUpdateInstallmentPaymentIntent: PaymentIntent;
  createOrgUser: OrgUser;
  createOrganization: Organization;
  createProposal: Proposal;
  createProposalTemplate: ProposalTemplate;
  createResource: CustomResource;
  createService: Service;
  createSponsor: CustomSponsor;
  createStripeConnectAccount?: Maybe<StripeConnectAccount>;
  createStripeConnectAccountPerson: StripePerson;
  createStripePlan: StripePlan;
  createVendorStripeCard: SourceOrRedirect;
  createVendorStripeSubscription: SubscriptionObjectOrRedirect;
  createVerificationToken?: Maybe<Scalars['Boolean']['output']>;
  deactivateProposalTemplate: ProposalTemplate;
  declineContact: Contact;
  deleteContact: Scalars['Boolean']['output'];
  deleteContractTemplate: ContractTemplate;
  deleteCustomerStripePaymentSource: StripeUser;
  deleteDocument: Scalars['Boolean']['output'];
  deleteFolders: Scalars['Boolean']['output'];
  deleteInquiry: Scalars['Boolean']['output'];
  deleteInvoiceTemplate: InvoiceTemplate;
  deleteProposalTemplate: ProposalTemplate;
  deleteResource: Scalars['Boolean']['output'];
  deleteService?: Maybe<Service>;
  deleteSponsor: Scalars['Boolean']['output'];
  disableOrgMember: Organization;
  dismissEvent: Scalars['Boolean']['output'];
  duplicateContractTemplate: ContractTemplate;
  forceEmailVerification?: Maybe<User>;
  generatePlaidLinkToken: Scalars['String']['output'];
  generatePlaidLinkTokenForQuickPayment: Scalars['String']['output'];
  markInvoiceVoid: Invoice;
  markOrganizationFraudulent: Scalars['Boolean']['output'];
  markUserOnboardingComplete: Scalars['Boolean']['output'];
  mergeInquiryIntoContact: Contact;
  moveDocuments: Array<Document>;
  moveFolders: Array<CustomFolder>;
  payQuickPayment: InvoiceOrRedirect;
  privilegedCancelVendorStripeSubscription: SubscriptionObject;
  privilegedChangeVendorStripeSubscription: SubscriptionObjectOrRedirect;
  privilegedCreateVendorStripeSubscription: SubscriptionObjectOrRedirect;
  privilegedUpdateOrganization?: Maybe<Scalars['Boolean']['output']>;
  reactivateOrgMember: Organization;
  recordDocumentUploaded: CustomDocumentWithDownloadUrl;
  recordOfflinePayment: Invoice;
  refreshContractGuestToken: Scalars['Boolean']['output'];
  refundInstallment: Invoice;
  refundOfflinePayment: Invoice;
  registerCouponUsage: Coupon;
  removeAllPlannerPermissions: Scalars['Boolean']['output'];
  removeOrganizationFromGroup: TestingGroup;
  removeUserFromGroup: TestingGroup;
  removeUserFromOrgAdmins: Organization;
  renameFolder?: Maybe<CustomFolder>;
  reportContractMarkup: CustomContract;
  reportContractSigning: CustomContract;
  reportTemplateMarkup: ContractTemplate;
  requestClientEventDateChange: Scalars['Boolean']['output'];
  requestContactActivation: Contact;
  requestInvoiceTemplateAttachmentUploadUrl: UploadUrl;
  requestPlannerPermissions: Scalars['Boolean']['output'];
  requestProposalFileUploadUrl: UploadUrl;
  requestProposalTemplateFileUploadUrl: UploadUrl;
  requestReset: Scalars['Boolean']['output'];
  resendContract: Scalars['Boolean']['output'];
  resendVerificationEmail?: Maybe<Scalars['Boolean']['output']>;
  resendVerificationEmailToUser?: Maybe<Scalars['Boolean']['output']>;
  resetPassword: Scalars['Boolean']['output'];
  sendOrResendInvoice: Invoice;
  sendProposal: Proposal;
  shareDocument: CustomDocumentWithDownloadUrl;
  stripe3DSecureChallengeComplete: InvoiceOrStripeUser;
  submitInquiry: Scalars['Boolean']['output'];
  submitInvoicePayment: InvoiceOrRedirect;
  toggleContractArchiveState: CustomContract;
  unblockUser: Scalars['Boolean']['output'];
  updateClientUser: ClientUser;
  updateContact: Contact;
  updateContactStatus: Contact;
  updateContract: CustomContract;
  updateContractTemplate: ContractTemplate;
  updateCustomerStripeBankAccount: StripeUser;
  updateCustomerStripeCard: StripeUser;
  updateDocumentName?: Maybe<CustomDocumentWithDownloadUrl>;
  updateEmailAddress: Scalars['Boolean']['output'];
  updateEmailAddressForUser?: Maybe<User>;
  updateExternalAccount?: Maybe<Source>;
  updateInquiry: Inquiry;
  updateInquiryForm: InquiryForm;
  updateInvoice?: Maybe<Invoice>;
  updateInvoiceAutopay: InvoiceOrRedirect;
  updateInvoiceTemplate: InvoiceTemplate;
  updateNoteOnContact?: Maybe<Contact>;
  updateOrgUser?: Maybe<OrgUser>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationPaymentCapacity?: Maybe<Organization>;
  updatePaidInstallment: Invoice;
  updateProposal: Proposal;
  updateProposalTemplate: ProposalTemplate;
  updateRefundedInstallment: Invoice;
  updateResource: CustomResource;
  updateSelf: User;
  updateSponsor: CustomSponsor;
  updateStripeConnectAccount?: Maybe<StripeConnectAccount>;
  updateStripeConnectAccountPerson: StripePerson;
  updateVendorFeeStructure: Organization;
  updateVendorStripeCard: StripeUser;
  verifyCustomerStripeBankAccount: Scalars['Boolean']['output'];
  verifyEmailAccount: Scalars['Boolean']['output'];
  verifyResourcePreview: Resource;
  verifySponsorLogo: Sponsor;
  viewDocument: CustomDocumentWithDownloadUrl;
  voidAndPortContract: CustomContract;
  voidContract: CustomContract;
  voidProposal: Proposal;
};


export type MutationAcceptProposalArgs = {
  where: ProposalWhereInput;
};


export type MutationActivateContactArgs = {
  data?: InputMaybe<ActivateContactCustomInput>;
  where: ContactWhereUniqueInput;
};


export type MutationActivateProposalTemplateArgs = {
  where: ProposalTemplateWhereUniqueInput;
};


export type MutationAddOrganizationToGroupArgs = {
  data: OrganizationWhereUniqueInput;
  where: TestingGroupWhereUniqueInput;
};


export type MutationAddUserToGroupArgs = {
  data: UserWhereUniqueInput;
  where: TestingGroupWhereUniqueInput;
};


export type MutationAddUserToOrgAdminsArgs = {
  where: OrgUserWhereUniqueInput;
};


export type MutationApproveContractArgs = {
  where: ContractWhereUniqueInput;
};


export type MutationApproveInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationAssignMemberToContactArgs = {
  data: OrgUserWhereUniqueInput;
  where: ContactWhereUniqueInput;
};


export type MutationAssignPlannerPermissionsArgs = {
  data: PermissionInput;
  where: ContactWhereUniqueInput;
};


export type MutationBlockUserArgs = {
  where: BlockUserWhereInputType;
};


export type MutationCancelContactArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationChangePasswordArgs = {
  data: PasswordChangeInput;
};


export type MutationChangeVendorSubscriptionArgs = {
  data: StripeVendorSubscriptionChangeInput;
};


export type MutationConvertInquiryArgs = {
  where: InquiryWhereUniqueInput;
};


export type MutationCopyDocumentFromCompanyFolderToCustomerFolderArgs = {
  data: ContactWhereUniqueInput;
  where: DocumentWhereUniqueInput;
};


export type MutationCreateClientUserArgs = {
  data: ClientUserCreateInput;
};


export type MutationCreateContactAsClientArgs = {
  data: OrganizationWhereUniqueInput;
  where: ClientUserWhereUniqueInput;
};


export type MutationCreateContactAsVendorArgs = {
  data: CreateContactDataInput;
  where: UserByEmailUniqueInput;
};


export type MutationCreateContractArgs = {
  data: ContractCreateCustomInput;
};


export type MutationCreateContractMarkupUrlArgs = {
  where: ContractWhereUniqueInput;
};


export type MutationCreateContractSigningUrlArgs = {
  where: CreateContractSigningUrlInput;
};


export type MutationCreateContractTemplateArgs = {
  data: ContractTemplateCreateInput;
};


export type MutationCreateContractTemplateMarkupUrlArgs = {
  data: CreateContractTemplateMarkupUrlInput;
  where: ContractTemplateWhereUniqueInput;
};


export type MutationCreateCouponArgs = {
  data: CouponCreateReducedInput;
};


export type MutationCreateCustomerPlaidBankAccountArgs = {
  data: PlaidInput;
  where: UserWhereUniqueInput;
};


export type MutationCreateCustomerStripeBankAccountArgs = {
  data: StripeBankAccountCreationInput;
  where: UserWhereUniqueInput;
};


export type MutationCreateCustomerStripeCardArgs = {
  data: StripeCardCreationInput;
};


export type MutationCreateDocumentArgs = {
  data: DocumentCreateCustomInput;
};


export type MutationCreateDraftInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationCreateExternalAccountArgs = {
  data: StripeBankAccountSourceInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationCreateFolderArgs = {
  data: FolderCreateCustomInput;
};


export type MutationCreateInquiryArgs = {
  data: InquiryCreateInput;
};


export type MutationCreateInquiryFormArgs = {
  data: InquiryFormDataInput;
};


export type MutationCreateInvitationArgs = {
  data: InvitationCustomCreateInput;
};


export type MutationCreateInvoiceTemplateArgs = {
  data: InvoiceTemplateCreateInput;
};


export type MutationCreateMfaTokenArgs = {
  data: UserLoginInput;
};


export type MutationCreateOrGetQuickPaymentLinkArgs = {
  data: QuickPaymentLinkCreateInput;
};


export type MutationCreateOrUpdateContactForCustomerArgs = {
  data: CreateOrUpdateContactForCustomerDataInput;
  where: CreateOrUpdateContactForCustomerWhereInput;
};


export type MutationCreateOrUpdateInstallmentPaymentIntentArgs = {
  data: PaymentIntentCreateOrUpdateDataInput;
  where: Array<PaymentIntentCreateOrUpdateWhereInput>;
};


export type MutationCreateOrgUserArgs = {
  data: OrgUserCreationInput;
};


export type MutationCreateOrganizationArgs = {
  data: OrganizationCustomCreateInput;
};


export type MutationCreateProposalArgs = {
  data: ProposalCreateInput;
};


export type MutationCreateProposalTemplateArgs = {
  data: ProposalTemplateCreateInput;
};


export type MutationCreateResourceArgs = {
  data: RetoolResourceCreateInput;
};


export type MutationCreateServiceArgs = {
  data: ServiceCreateInput;
};


export type MutationCreateSponsorArgs = {
  data: RetoolSponsorCreateInput;
};


export type MutationCreateStripeConnectAccountArgs = {
  data: StripeConnectAccountCreationInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationCreateStripeConnectAccountPersonArgs = {
  data: StripePersonInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationCreateStripePlanArgs = {
  data: StripePlanCreationInput;
};


export type MutationCreateVendorStripeCardArgs = {
  data: StripeVendorCardCreationInput;
};


export type MutationCreateVendorStripeSubscriptionArgs = {
  data: StripeVendorSubscriptionCreationInput;
};


export type MutationCreateVerificationTokenArgs = {
  data: CreateVerificationTokenInput;
};


export type MutationDeactivateProposalTemplateArgs = {
  where: ProposalTemplateWhereUniqueInput;
};


export type MutationDeclineContactArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationDeleteContactArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationDeleteContractTemplateArgs = {
  where: ContractTemplateWhereUniqueInput;
};


export type MutationDeleteCustomerStripePaymentSourceArgs = {
  where: StripeBankAccountDeletionInput;
};


export type MutationDeleteDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type MutationDeleteFoldersArgs = {
  where: FoldersWhereCustomInput;
};


export type MutationDeleteInquiryArgs = {
  where: InquiryWhereUniqueInput;
};


export type MutationDeleteInvoiceTemplateArgs = {
  where: InvoiceTemplateWhereUniqueInput;
};


export type MutationDeleteProposalTemplateArgs = {
  where: ProposalTemplateWhereUniqueInput;
};


export type MutationDeleteResourceArgs = {
  where: ResourceWhereUniqueInput;
};


export type MutationDeleteServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteSponsorArgs = {
  where: SponsorWhereUniqueInput;
};


export type MutationDisableOrgMemberArgs = {
  where: OrgUserWhereUniqueInput;
};


export type MutationDismissEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationDuplicateContractTemplateArgs = {
  where: ContractTemplateWhereUniqueInput;
};


export type MutationForceEmailVerificationArgs = {
  email: Scalars['String']['input'];
};


export type MutationGeneratePlaidLinkTokenArgs = {
  data?: InputMaybe<PlaidLinkTokenRedirectInput>;
  where?: InputMaybe<PlaidLinkTokenInput>;
};


export type MutationGeneratePlaidLinkTokenForQuickPaymentArgs = {
  where: UserWhereUniqueInput;
};


export type MutationMarkInvoiceVoidArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationMarkOrganizationFraudulentArgs = {
  where: OrganizationWhereUniqueInput;
};


export type MutationMergeInquiryIntoContactArgs = {
  where: InquiryMergeInput;
};


export type MutationMoveDocumentsArgs = {
  data: FolderWhereUniqueInput;
  where: DocumentsWhereCustomInput;
};


export type MutationMoveFoldersArgs = {
  data?: InputMaybe<MoveFolderCustomInput>;
  where: FoldersWhereCustomInput;
};


export type MutationPayQuickPaymentArgs = {
  data: PayQuickPaymentInput;
};


export type MutationPrivilegedCancelVendorStripeSubscriptionArgs = {
  where: OrganizationWhereUniqueInput;
};


export type MutationPrivilegedChangeVendorStripeSubscriptionArgs = {
  data: StripeVendorSubscriptionChangeInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationPrivilegedCreateVendorStripeSubscriptionArgs = {
  data: StripeVendorSubscriptionCreationInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationPrivilegedUpdateOrganizationArgs = {
  data: OrganizationPrivilegedUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationReactivateOrgMemberArgs = {
  where: OrgUserWhereUniqueInput;
};


export type MutationRecordDocumentUploadedArgs = {
  where: DocumentWhereUniqueInput;
};


export type MutationRecordOfflinePaymentArgs = {
  data: RecordOfflinePaymentInput;
  where: PaymentInstallmentWhereUniqueInput;
};


export type MutationRefreshContractGuestTokenArgs = {
  where: ContractWhereInputWithRequiredGuestToken;
};


export type MutationRefundInstallmentArgs = {
  data: RefundInstallmentInput;
};


export type MutationRefundOfflinePaymentArgs = {
  data: RecordOfflineRefundInput;
  where: PaymentInstallmentWhereUniqueInput;
};


export type MutationRegisterCouponUsageArgs = {
  data: RegisterCouponUsageWhere;
  where: OrganizationWhereUniqueInput;
};


export type MutationRemoveAllPlannerPermissionsArgs = {
  where: ClientUserWhereUniqueInput;
};


export type MutationRemoveOrganizationFromGroupArgs = {
  data: OrganizationWhereUniqueInput;
  where: TestingGroupWhereUniqueInput;
};


export type MutationRemoveUserFromGroupArgs = {
  data: UserWhereUniqueInput;
  where: TestingGroupWhereUniqueInput;
};


export type MutationRemoveUserFromOrgAdminsArgs = {
  where: OrgUserWhereUniqueInput;
};


export type MutationRenameFolderArgs = {
  data: UpdateFolderNameInput;
  where: FolderWhereUniqueInput;
};


export type MutationReportContractMarkupArgs = {
  data: ReportInput;
  where: ContractWhereUniqueInput;
};


export type MutationReportContractSigningArgs = {
  data: ReportContractSigningInput;
  where: ContractWhereInputWithGuestToken;
};


export type MutationReportTemplateMarkupArgs = {
  where: ContractTemplateWhereUniqueInput;
};


export type MutationRequestClientEventDateChangeArgs = {
  where: ClientUserWhereUniqueInput;
};


export type MutationRequestContactActivationArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationRequestInvoiceTemplateAttachmentUploadUrlArgs = {
  data: RequestAttachmentUploadUrlInput;
  where: InvoiceTemplateWhereUniqueInput;
};


export type MutationRequestPlannerPermissionsArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationRequestProposalFileUploadUrlArgs = {
  data: RequestAttachmentUploadUrlInput;
  where: ProposalWhereUniqueInput;
};


export type MutationRequestProposalTemplateFileUploadUrlArgs = {
  data: RequestAttachmentUploadUrlInput;
  where: ProposalTemplateWhereUniqueInput;
};


export type MutationRequestResetArgs = {
  data: PasswordResetRequestInput;
};


export type MutationResendContractArgs = {
  data?: InputMaybe<ContractResendInput>;
  where: ContractWhereUniqueInput;
};


export type MutationResendVerificationEmailToUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  data: PasswordResetInput;
};


export type MutationSendOrResendInvoiceArgs = {
  data?: InputMaybe<SendOrResendInvoiceInput>;
  where: InvoiceWhereUniqueInput;
};


export type MutationSendProposalArgs = {
  data?: InputMaybe<SendProposalInput>;
  where: ProposalWhereUniqueInput;
};


export type MutationShareDocumentArgs = {
  data: CustomShareDocumentInput;
  where: DocumentWhereUniqueInput;
};


export type MutationStripe3DSecureChallengeCompleteArgs = {
  data: StripePaymentOrSetupIntentStatus;
  where?: InputMaybe<InvoiceOrOrganizationInput>;
};


export type MutationSubmitInquiryArgs = {
  data: InquirySubmissionInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationSubmitInvoicePaymentArgs = {
  data: SubmitInvoicePaymentInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationToggleContractArchiveStateArgs = {
  where: ContractWhereUniqueInput;
};


export type MutationUnblockUserArgs = {
  where: BlockUserWhereInputType;
};


export type MutationUpdateClientUserArgs = {
  data: ClientUserUpdateCustomInput;
  where: ClientUserWhereUniqueInput;
};


export type MutationUpdateContactArgs = {
  data: CustomContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateContactStatusArgs = {
  data: UpdateContactStatusDataInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateContractArgs = {
  data: ContractUpdateInput;
  where: ContractWhereUniqueInput;
};


export type MutationUpdateContractTemplateArgs = {
  data: ContractTemplateCustomUpdateInput;
  where: ContractTemplateWhereUniqueInput;
};


export type MutationUpdateCustomerStripeBankAccountArgs = {
  data: StripeBankAccountUpdateInput;
  where: StripeBankAccountDeletionInput;
};


export type MutationUpdateCustomerStripeCardArgs = {
  data: CustomerStripeCardUpdateInput;
  where: UpdateCustomerStripeCardWhereInput;
};


export type MutationUpdateDocumentNameArgs = {
  data: UpdateDocumentNameInput;
  where: DocumentWhereUniqueInput;
};


export type MutationUpdateEmailAddressArgs = {
  data: EmailAddressUpdateInput;
  where: EmailAddressInput;
};


export type MutationUpdateEmailAddressForUserArgs = {
  newEmail: Scalars['String']['input'];
  oldEmail: Scalars['String']['input'];
};


export type MutationUpdateExternalAccountArgs = {
  data: StripeExternalAccountUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateInquiryArgs = {
  data: InquiryUpdateDataInput;
  where: InquiryWhereUniqueInput;
};


export type MutationUpdateInquiryFormArgs = {
  data: InquiryFormDataInput;
};


export type MutationUpdateInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateInvoiceAutopayArgs = {
  data: InvoiceAutopayUpdateCustomInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateInvoiceTemplateArgs = {
  data: InvoiceTemplateUpdateInput;
  where: InvoiceTemplateWhereUniqueInput;
};


export type MutationUpdateNoteOnContactArgs = {
  data: NoteInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateOrgUserArgs = {
  data: OrgUserUpdateCustomInput;
  where: OrgUserWhereUniqueInput;
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationCustomUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateOrganizationPaymentCapacityArgs = {
  data: OrganizationPaymentCapacityUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdatePaidInstallmentArgs = {
  data: UpdatePaidInstallmentInput;
  where: PaymentInstallmentWhereUniqueInput;
};


export type MutationUpdateProposalArgs = {
  data: ProposalUpdateInput;
  where: ProposalWhereUniqueInput;
};


export type MutationUpdateProposalTemplateArgs = {
  data: ProposalTemplateUpdateInput;
  where: ProposalTemplateWhereUniqueInput;
};


export type MutationUpdateRefundedInstallmentArgs = {
  data: UpdateRefundedInstallmentInput;
  where: PaymentInstallmentWhereUniqueInput;
};


export type MutationUpdateResourceArgs = {
  data: RetoolResourceUpdateInput;
  where: ResourceWhereUniqueInput;
};


export type MutationUpdateSelfArgs = {
  data: UserUpdateSelfInput;
};


export type MutationUpdateSponsorArgs = {
  data: RetoolSponsorUpdateInput;
  where: SponsorWhereUniqueInput;
};


export type MutationUpdateStripeConnectAccountArgs = {
  data: StripeConnectAccountUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateStripeConnectAccountPersonArgs = {
  data: StripePersonInput;
  where: OrganizationAndPersonInput;
};


export type MutationUpdateVendorFeeStructureArgs = {
  data: VendorFeeStructureInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateVendorStripeCardArgs = {
  data: OrganizationStripeCardUpdateInput;
};


export type MutationVerifyCustomerStripeBankAccountArgs = {
  data: CustomerBankAccountVerificationInput;
  where: UserWhereUniqueInput;
};


export type MutationVerifyEmailAccountArgs = {
  where: VerificationTokenWhereUniqueInput;
};


export type MutationVerifyResourcePreviewArgs = {
  update?: InputMaybe<Scalars['Boolean']['input']>;
  where: ResourceWhereUniqueInput;
};


export type MutationVerifySponsorLogoArgs = {
  update?: InputMaybe<Scalars['Boolean']['input']>;
  where: SponsorWhereUniqueInput;
};


export type MutationViewDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type MutationVoidAndPortContractArgs = {
  where: ContractWhereUniqueInput;
};


export type MutationVoidContractArgs = {
  where: ContractWhereUniqueInput;
};


export type MutationVoidProposalArgs = {
  where: ProposalWhereUniqueInput;
};

export type NewExternalAccountUpdateInput = {
  account_number: Scalars['String']['input'];
  country: Country;
  routing_number: Scalars['String']['input'];
};

export type NoteInput = {
  note: Scalars['String']['input'];
};

export enum NotificationsPreference {
  Daily = 'Daily',
  Instant = 'Instant',
  Monthly = 'Monthly',
  None = 'None',
  Weekly = 'Weekly'
}

export type OfflinePaymentHistory = {
  __typename?: 'OfflinePaymentHistory';
  offlinePaymentHistoryItems: Array<Maybe<OfflinePaymentHistoryItem>>;
};

export type OfflinePaymentHistoryAfterInput = {
  paymentAfter?: InputMaybe<Scalars['ID']['input']>;
  refundAfter?: InputMaybe<Scalars['ID']['input']>;
};

export type OfflinePaymentHistoryItem = {
  __typename?: 'OfflinePaymentHistoryItem';
  amount?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoice: InvoiceForOfflinePaymentHistory;
  name?: Maybe<Scalars['String']['output']>;
  offlinePaymentType?: Maybe<OfflinePaymentType>;
  type: OfflinePaymentHistoryItemType;
};

export enum OfflinePaymentHistoryItemType {
  Payment = 'Payment',
  Refund = 'Refund'
}

export enum OfflinePaymentType {
  Cash = 'Cash',
  Check = 'Check',
  Other = 'Other'
}

export type OrgDashboardStats = {
  __typename?: 'OrgDashboardStats';
  clientContacts: Scalars['Int']['output'];
  completedPaymentsAmount: Scalars['Int']['output'];
  outstandingPaymentsAmount: Scalars['Int']['output'];
  signedContracts: Scalars['Int']['output'];
};

export enum OrgType {
  Planner = 'Planner',
  Vendor = 'Vendor'
}

export type OrgUser = {
  __typename?: 'OrgUser';
  assignedClients?: Maybe<Array<ClientUser>>;
  contacts?: Maybe<Array<Contact>>;
  contractTemplates?: Maybe<Array<ContractTemplate>>;
  createdAt: Scalars['DateTime']['output'];
  documents?: Maybe<Array<Document>>;
  events?: Maybe<Array<Event>>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  invitationID?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  originalImage?: Maybe<Scalars['String']['output']>;
  passWordLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  plannerEvents?: Maybe<Array<Event>>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type OrgUserAssignedClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClientUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientUserWhereInput>;
};


export type OrgUserContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type OrgUserContractTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContractTemplateWhereInput>;
};


export type OrgUserDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type OrgUserEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type OrgUserPlannerEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type OrgUserCreationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Int']['input']>;
  imagePositionY?: InputMaybe<Scalars['Int']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  invitationID?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  notificationPreference?: InputMaybe<NotificationsPreference>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  passWordLastUpdated?: InputMaybe<Scalars['DateTime']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  verificationTokenID?: InputMaybe<Scalars['String']['input']>;
};

export type OrgUserForActingUserOnEvent = {
  __typename?: 'OrgUserForActingUserOnEvent';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  organization?: Maybe<OrganizationForActingUser>;
  user?: Maybe<ActingUserForEvent>;
};

export type OrgUserForGetContractWhere = {
  __typename?: 'OrgUserForGetContractWhere';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  lastName: Scalars['String']['output'];
  organization: OrganizationForGetContractWhere;
  originalImage?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: BaseUserForGetContractWhere;
};

export type OrgUserOrderByInput = {
  contacts?: InputMaybe<ContactOrderByInput>;
  createdAt?: InputMaybe<SortOrderType>;
  firstName?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  image?: InputMaybe<SortOrderType>;
  imagePositionX?: InputMaybe<SortOrderType>;
  imagePositionY?: InputMaybe<SortOrderType>;
  imageRotation?: InputMaybe<SortOrderType>;
  imageScale?: InputMaybe<SortOrderType>;
  invitationID?: InputMaybe<SortOrderType>;
  lastName?: InputMaybe<SortOrderType>;
  orderBy?: InputMaybe<OrgUserOrderByInput>;
  organization?: InputMaybe<OrganizationOrderByInput>;
  originalImage?: InputMaybe<SortOrderType>;
  passWordLastUpdated?: InputMaybe<SortOrderType>;
  phone?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type OrgUserSignerForGetContractWhere = {
  __typename?: 'OrgUserSignerForGetContractWhere';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type OrgUserUpdateCustomInput = {
  acceptedTerms?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Int']['input']>;
  imagePositionY?: InputMaybe<Scalars['Int']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notificationPreference?: InputMaybe<NotificationsPreference>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutUsersInput>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  passWordLastUpdated?: InputMaybe<Scalars['DateTime']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  referral?: InputMaybe<ReferralCreateWithoutUserInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrgUserInput>;
};

export type OrgUserWhereAssignedClientsInput = {
  every?: InputMaybe<ClientUserWhereInput>;
  none?: InputMaybe<ClientUserWhereInput>;
  some?: InputMaybe<ClientUserWhereInput>;
};

export type OrgUserWhereContactsInput = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type OrgUserWhereContractTemplates = {
  every?: InputMaybe<ContractTemplateWhereInput>;
  none?: InputMaybe<ContractTemplateWhereInput>;
  some?: InputMaybe<ContractTemplateWhereInput>;
};

export type OrgUserWhereDocumentsInput = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type OrgUserWhereEventsInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type OrgUserWhereInput = {
  AND?: InputMaybe<Array<OrgUserWhereInput>>;
  NOT?: InputMaybe<Array<OrgUserWhereInput>>;
  OR?: InputMaybe<Array<OrgUserWhereInput>>;
  assignedClients?: InputMaybe<OrgUserWhereAssignedClientsInput>;
  contacts?: InputMaybe<OrgUserWhereContactsInput>;
  contractTemplates?: InputMaybe<OrgUserWhereContractTemplates>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  documents?: InputMaybe<OrgUserWhereDocumentsInput>;
  events?: InputMaybe<OrgUserWhereEventsInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Float']['input']>;
  imagePositionY?: InputMaybe<Scalars['Float']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  invitationID?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationWhereInput>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  passWordLastUpdated?: InputMaybe<Scalars['DateTime']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  plannerEvents?: InputMaybe<OrgUserWherePlannerEventsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserWhereInput>;
};

export type OrgUserWherePlannerEventsInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type OrgUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OrgUsers = {
  __typename?: 'OrgUsers';
  _count: Scalars['Int']['output'];
  orgUsers: Array<OrgUser>;
};

export type Organization = {
  __typename?: 'Organization';
  achAppFeePercentage: Scalars['Decimal']['output'];
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  adminUsers?: Maybe<Array<OrgUser>>;
  appSubscriptions?: Maybe<Array<Maybe<AppSubscription>>>;
  automatedRemindersEnabled: Scalars['Boolean']['output'];
  brandedEmails?: Maybe<Scalars['Boolean']['output']>;
  canReceivePayments: Scalars['Boolean']['output'];
  cardAppFeePercentage: Scalars['Decimal']['output'];
  cardConvenienceFeePercentage: Scalars['Decimal']['output'];
  city?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Contact>>;
  contractTemplates?: Maybe<Array<ContractTemplate>>;
  country?: Maybe<Country>;
  coupon?: Maybe<Coupon>;
  couponInstances?: Maybe<Array<Maybe<CouponInstance>>>;
  createdAt: Scalars['DateTime']['output'];
  defaultOrgUser: OrgUser;
  description?: Maybe<Scalars['String']['output']>;
  disabledMembers?: Maybe<Array<OrgUser>>;
  documents?: Maybe<Array<Document>>;
  events?: Maybe<Array<Event>>;
  facebook?: Maybe<Scalars['String']['output']>;
  groups: Array<TestingGroup>;
  hasStripeVerificationNeeds: Scalars['Boolean']['output'];
  helloSignID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  inquiries: Array<Inquiry>;
  instagram?: Maybe<Scalars['String']['output']>;
  invoicesAllowGratuity: Scalars['Boolean']['output'];
  lastSuccessfulSettlement?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  orgType?: Maybe<OrgType>;
  originalImage?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pinterest?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  rootFolder?: Maybe<Folder>;
  services?: Maybe<Array<Service>>;
  state?: Maybe<State>;
  stripeID?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionCustomerID?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<AppSubscription>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users?: Maybe<Array<OrgUser>>;
  website?: Maybe<Scalars['String']['output']>;
};


export type OrganizationAdminUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};


export type OrganizationAppSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AppSubscriptionOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppSubscriptionWhereInput>;
};


export type OrganizationContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type OrganizationContractTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContractTemplateWhereInput>;
};


export type OrganizationCouponInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CouponInstanceWhereInput>;
};


export type OrganizationDisabledMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};


export type OrganizationDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type OrganizationEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type OrganizationServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type OrganizationUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};

export type OrganizationAndPersonInput = {
  organization: OrganizationWhereUniqueInput;
  person: PersonInput;
};

export type OrganizationConnectServicesInput = {
  connect?: InputMaybe<Array<InputMaybe<ServiceWhereUniqueInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ServiceWhereUniqueInput>>>;
};

export type OrganizationCreateManyWithoutServicesInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
};

export type OrganizationCustomCreateInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  allowsGratuity?: InputMaybe<Scalars['Boolean']['input']>;
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  country: Country;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Int']['input']>;
  imagePositionY?: InputMaybe<Scalars['Int']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  invitationID?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgType?: InputMaybe<OrgType>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  services: OrganizationConnectServicesInput;
  state?: InputMaybe<State>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationCustomUpdateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  brandedEmails?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Int']['input']>;
  imagePositionY?: InputMaybe<Scalars['Int']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  invoicesAllowGratuity?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<OrganizationConnectServicesInput>;
  state?: InputMaybe<State>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updateStripe?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationForActingUser = {
  __typename?: 'OrganizationForActingUser';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  services?: Maybe<ServicesForActingUser>;
};

export type OrganizationForGetContractWhere = {
  __typename?: 'OrganizationForGetContractWhere';
  adminUsers?: Maybe<Array<AdminUsersForGetContractWhere>>;
  helloSignID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  services: Array<Maybe<ServicesForGetContractWhere>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationForGetQuickPayment = {
  __typename?: 'OrganizationForGetQuickPayment';
  cardConvenienceFeePercentage: Scalars['Decimal']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  services: Array<Maybe<ServicesForGetQuickPayment>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationForPaymentHistoryItem = {
  __typename?: 'OrganizationForPaymentHistoryItem';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OrganizationOrderByInput = {
  addressLine1?: InputMaybe<SortOrderType>;
  addressLine2?: InputMaybe<SortOrderType>;
  brandedEmails?: InputMaybe<SortOrderType>;
  city?: InputMaybe<SortOrderType>;
  contactEmail?: InputMaybe<SortOrderType>;
  contractNumber?: InputMaybe<SortOrderType>;
  country?: InputMaybe<SortOrderType>;
  createdAt?: InputMaybe<SortOrderType>;
  description?: InputMaybe<SortOrderType>;
  facebook?: InputMaybe<SortOrderType>;
  hasStripeVerificationNeeds?: InputMaybe<SortOrderType>;
  helloSignID?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  image?: InputMaybe<SortOrderType>;
  imagePositionX?: InputMaybe<SortOrderType>;
  imagePositionY?: InputMaybe<SortOrderType>;
  imageRotation?: InputMaybe<SortOrderType>;
  imageScale?: InputMaybe<SortOrderType>;
  instagram?: InputMaybe<SortOrderType>;
  lastSuccessfulSettlement?: InputMaybe<SortOrderType>;
  name?: InputMaybe<SortOrderType>;
  orgType?: InputMaybe<SortOrderType>;
  originalImage?: InputMaybe<SortOrderType>;
  phone?: InputMaybe<SortOrderType>;
  pinterest?: InputMaybe<SortOrderType>;
  postalCode?: InputMaybe<SortOrderType>;
  state?: InputMaybe<SortOrderType>;
  stripeID?: InputMaybe<SortOrderType>;
  stripeSubscriptionCustomerID?: InputMaybe<SortOrderType>;
  twitter?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  website?: InputMaybe<SortOrderType>;
};

export type OrganizationPaymentCapacityUpdateInput = {
  canReceivePayments: Scalars['Boolean']['input'];
};

export type OrganizationPrivilegedUpdateInput = {
  achAppFeePercentage?: InputMaybe<Scalars['Decimal']['input']>;
  canReceivePayments?: InputMaybe<Scalars['Boolean']['input']>;
  cardAppFeePercentage?: InputMaybe<Scalars['Decimal']['input']>;
  cardConvenienceFeePercentage?: InputMaybe<Scalars['Decimal']['input']>;
  fraudulent?: InputMaybe<Scalars['Boolean']['input']>;
  hasStripeVerificationNeeds?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationStripeCardUpdateInput = {
  cardToBeUpdated: Scalars['String']['input'];
  cardUpdates: StripeCardUpdateInfoInput;
};

export type OrganizationUpdateOneWithoutUsersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationWhereAdminUsersInput = {
  every?: InputMaybe<OrgUserWhereInput>;
  none?: InputMaybe<OrgUserWhereInput>;
  some?: InputMaybe<OrgUserWhereInput>;
};

export type OrganizationWhereAppSubscriptionsInput = {
  every?: InputMaybe<AppSubscriptionWhereInput>;
  none?: InputMaybe<AppSubscriptionWhereInput>;
  some?: InputMaybe<AppSubscriptionWhereInput>;
};

export type OrganizationWhereContactsInput = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type OrganizationWhereContractTemplateInput = {
  every?: InputMaybe<ContractTemplateWhereInput>;
  none?: InputMaybe<ContractTemplateWhereInput>;
  some?: InputMaybe<ContractTemplateWhereInput>;
};

export type OrganizationWhereCouponInstancesInput = {
  every?: InputMaybe<CouponInstanceWhereInput>;
  none?: InputMaybe<CouponInstanceWhereInput>;
  some?: InputMaybe<CouponInstanceWhereInput>;
};

export type OrganizationWhereDocumentsInput = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type OrganizationWhereEventsInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type OrganizationWhereInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  adminUsers?: InputMaybe<OrganizationWhereAdminUsersInput>;
  appSubscriptions?: InputMaybe<OrganizationWhereAppSubscriptionsInput>;
  brandedEmails?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactEmail_contains?: InputMaybe<Scalars['String']['input']>;
  contacts?: InputMaybe<OrganizationWhereContactsInput>;
  contractTemplates?: InputMaybe<OrganizationWhereContractTemplateInput>;
  country?: InputMaybe<Country>;
  coupon?: InputMaybe<CouponWhereInput>;
  couponInstances?: InputMaybe<OrganizationWhereCouponInstancesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  defaultOrgUser?: InputMaybe<OrgUserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<OrganizationWhereDocumentsInput>;
  events?: InputMaybe<OrganizationWhereEventsInput>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  hasStripeVerificationNeeds?: InputMaybe<Scalars['Boolean']['input']>;
  helloSignID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imagePositionX?: InputMaybe<Scalars['Float']['input']>;
  imagePositionY?: InputMaybe<Scalars['Float']['input']>;
  imageRotation?: InputMaybe<Scalars['Int']['input']>;
  imageScale?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  lastSuccessfulSettlement?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  orgType?: InputMaybe<OrgType>;
  originalImage?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  rootFolder?: InputMaybe<FolderWhereInput>;
  services?: InputMaybe<OrganizationWhereServicesInput>;
  state?: InputMaybe<State>;
  stripeID?: InputMaybe<Scalars['String']['input']>;
  stripeSubscriptionCustomerID?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<AppSubscriptionWhereInput>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<OrganizationWhereUsersInput>;
  website?: InputMaybe<Scalars['String']['input']>;
  website_contains?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationWhereServicesInput = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type OrganizationWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type OrganizationWhereUsersInput = {
  every?: InputMaybe<OrgUserWhereInput>;
  none?: InputMaybe<OrgUserWhereInput>;
  some?: InputMaybe<OrgUserWhereInput>;
};

export type OrganizationWithCount = {
  __typename?: 'OrganizationWithCount';
  _usersCount: Scalars['Int']['output'];
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  adminUsers?: Maybe<Array<OrgUser>>;
  appSubscriptions?: Maybe<Array<Maybe<AppSubscription>>>;
  brandedEmails?: Maybe<Scalars['Boolean']['output']>;
  cardConvenienceFeePercentage?: Maybe<Scalars['Decimal']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Contact>>;
  contractNumber?: Maybe<Scalars['Int']['output']>;
  contractTemplates?: Maybe<Array<ContractTemplate>>;
  country?: Maybe<Country>;
  coupon?: Maybe<Coupon>;
  couponInstances?: Maybe<Array<Maybe<CouponInstance>>>;
  createdAt: Scalars['DateTime']['output'];
  defaultOrgUser: OrgUser;
  description?: Maybe<Scalars['String']['output']>;
  disabledMembers?: Maybe<Array<OrgUser>>;
  documents?: Maybe<Array<Document>>;
  events?: Maybe<Array<Event>>;
  facebook?: Maybe<Scalars['String']['output']>;
  hasStripeVerificationNeeds: Scalars['Boolean']['output'];
  helloSignID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imagePositionX?: Maybe<Scalars['Float']['output']>;
  imagePositionY?: Maybe<Scalars['Float']['output']>;
  imageRotation?: Maybe<Scalars['Int']['output']>;
  imageScale?: Maybe<Scalars['Int']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  lastSuccessfulSettlement?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  orgType?: Maybe<OrgType>;
  originalImage?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pinterest?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  rootFolder?: Maybe<Folder>;
  services?: Maybe<Array<Service>>;
  state?: Maybe<State>;
  stripeID?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionCustomerID?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<AppSubscription>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users?: Maybe<Array<OrgUser>>;
  website?: Maybe<Scalars['String']['output']>;
};


export type OrganizationWithCountAdminUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};


export type OrganizationWithCountAppSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AppSubscriptionOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppSubscriptionWhereInput>;
};


export type OrganizationWithCountContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type OrganizationWithCountContractTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContractTemplateWhereInput>;
};


export type OrganizationWithCountCouponInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CouponInstanceWhereInput>;
};


export type OrganizationWithCountDisabledMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};


export type OrganizationWithCountDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type OrganizationWithCountEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type OrganizationWithCountServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type OrganizationWithCountUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};

export type Organizations = {
  __typename?: 'Organizations';
  _count: Scalars['Int']['output'];
  organizations: Array<Organization>;
};

export type PasswordChangeInput = {
  confirmedPassword: Scalars['String']['input'];
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type PasswordResetInput = {
  confirmedPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordResetToken: Scalars['String']['input'];
};

export type PasswordResetRequestInput = {
  email: Scalars['String']['input'];
};

export type PasswordResetToken = {
  __typename?: 'PasswordResetToken';
  passwordResetToken: Scalars['String']['output'];
};

export type PayQuickPaymentInput = {
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodToken?: InputMaybe<Scalars['String']['input']>;
  quickPayment: QuickPaymentForPayQuickPaymentInput;
  receiptEmails?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentHistoryItem = {
  __typename?: 'PaymentHistoryItem';
  amount: Scalars['Int']['output'];
  date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoice?: Maybe<InvoiceForPaymentHistoryItem>;
  organization?: Maybe<OrganizationForPaymentHistoryItem>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PaymentInstallment = {
  __typename?: 'PaymentInstallment';
  amountDue?: Maybe<Scalars['Int']['output']>;
  amountPaid?: Maybe<Scalars['Int']['output']>;
  amountRefunded?: Maybe<Scalars['Int']['output']>;
  bookkeepingFeeStripeInvoiceID?: Maybe<Scalars['String']['output']>;
  convenienceFee?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  enteredAsPercentageAmount?: Maybe<Scalars['Boolean']['output']>;
  events?: Maybe<Array<Event>>;
  gratuityPaid?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  name?: Maybe<Scalars['String']['output']>;
  offlinePaymentType?: Maybe<OfflinePaymentType>;
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentNote?: Maybe<Scalars['String']['output']>;
  percentageDue?: Maybe<Scalars['Float']['output']>;
  processor?: Maybe<PaymentProcessor>;
  receiptEmails: Array<Scalars['String']['output']>;
  refundDate?: Maybe<Scalars['DateTime']['output']>;
  refundNote?: Maybe<Scalars['String']['output']>;
  scheduledPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  scheduledPaymentFailureCount?: Maybe<Scalars['Int']['output']>;
  scheduledPaymentMethod?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InstallmentStatus>;
  stripeChargeID?: Maybe<Scalars['String']['output']>;
  stripePaymentMethod?: Maybe<Scalars['String']['output']>;
  stripeRefundID?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type PaymentInstallmentEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type PaymentInstallmentDataInput = {
  amountDue: Scalars['Long']['input'];
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  enteredAsPercentageAmount: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  percentageDue: Scalars['Float']['input'];
};

export type PaymentInstallmentDetails = {
  __typename?: 'PaymentInstallmentDetails';
  last4?: Maybe<Scalars['String']['output']>;
};

export type PaymentInstallmentEventsWhereInput = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type PaymentInstallmentOrderByInputForGetContractWhere = {
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentInstallmentWhereInput = {
  AND?: InputMaybe<Array<PaymentInstallmentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentInstallmentWhereInput>>;
  OR?: InputMaybe<Array<PaymentInstallmentWhereInput>>;
  amountDue?: InputMaybe<Scalars['Int']['input']>;
  amountPaid?: InputMaybe<Scalars['Int']['input']>;
  amountRefunded?: InputMaybe<Scalars['Int']['input']>;
  bookkeepingFeeStripeInvoiceID?: InputMaybe<Scalars['String']['input']>;
  convenienceFee?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<PaymentInstallmentEventsWhereInput>;
  gratuityPaid?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoice?: InputMaybe<InvoiceWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offlinePaymentType?: InputMaybe<OfflinePaymentType>;
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentNote?: InputMaybe<Scalars['String']['input']>;
  processor?: InputMaybe<PaymentProcessor>;
  refundDate?: InputMaybe<Scalars['DateTime']['input']>;
  refundNote?: InputMaybe<Scalars['String']['input']>;
  scheduledPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledPaymentFailureCount?: InputMaybe<Scalars['Int']['input']>;
  scheduledPaymentMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InstallmentStatus>;
  stripeChargeID?: InputMaybe<Scalars['String']['input']>;
  stripePaymentMethod?: InputMaybe<Scalars['String']['input']>;
  stripeRefundID?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentInstallmentWhereInputForGetContractWhere = {
  status?: InputMaybe<InstallmentStatus>;
};

export type PaymentInstallmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentInstallmentsForGetContractWhere = {
  __typename?: 'PaymentInstallmentsForGetContractWhere';
  amountDue?: Maybe<Scalars['Int']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<InstallmentStatus>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type PaymentIntentCreateOrUpdateDataInput = {
  isPayingWithCard: Scalars['Boolean']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentIntentCreateOrUpdateWhereInput = {
  id: Scalars['String']['input'];
};

export type PaymentMethodWhereInput = {
  stripePaymentMethod: Scalars['String']['input'];
};

export enum PaymentProcessor {
  Offline = 'Offline',
  Stripe = 'Stripe'
}

export type PaymentsSettings = {
  __typename?: 'PaymentsSettings';
  statement_descriptor?: Maybe<Scalars['String']['output']>;
};

export type PaymentsSettingsInput = {
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
};

export type PayoutsSchedule = {
  __typename?: 'PayoutsSchedule';
  delay_days?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  monthly_anchor?: Maybe<Scalars['Int']['output']>;
  weekly_anchor?: Maybe<Scalars['String']['output']>;
};

export type PayoutsScheduleInput = {
  delay_days?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  monthly_anchor?: InputMaybe<Scalars['Int']['input']>;
  weekly_anchor?: InputMaybe<Scalars['String']['input']>;
};

export type PayoutsSettings = {
  __typename?: 'PayoutsSettings';
  debit_negative_balances?: Maybe<Scalars['Boolean']['output']>;
  schedule?: Maybe<PayoutsSchedule>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
};

export type PayoutsSettingsInput = {
  debit_negative_balances?: InputMaybe<Scalars['Boolean']['input']>;
  schedule?: InputMaybe<PayoutsScheduleInput>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
};

export type PendingInvitations = {
  __typename?: 'PendingInvitations';
  _count: Scalars['Int']['output'];
  pendingInvitations: Array<Invitation>;
};

export type PermissionInput = {
  plannerOrgCanPayInvoices: Scalars['Boolean']['input'];
  plannerOrgCanSignContracts: Scalars['Boolean']['input'];
  plannerOrgHasConnectionPermissions: Scalars['Boolean']['input'];
  plannerOrgHasDocumentsPermissions: Scalars['Boolean']['input'];
};

export type PersonInput = {
  id: Scalars['String']['input'];
};

export type PersonVerificationDetails = {
  __typename?: 'PersonVerificationDetails';
  additional_document?: Maybe<VerificationDocument>;
  details?: Maybe<Scalars['String']['output']>;
  details_code?: Maybe<Scalars['String']['output']>;
  document?: Maybe<VerificationDocument>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PersonVerificationDetailsInput = {
  additional_document?: InputMaybe<VerificationDocumentInput>;
  document?: InputMaybe<VerificationDocumentInput>;
};

export type PlaidInput = {
  accountID: Scalars['String']['input'];
  plaidToken: Scalars['String']['input'];
};

export type PlaidLinkTokenInput = {
  invoice?: InputMaybe<InvoiceWhereUniqueInput>;
  invoiceToken?: InputMaybe<Scalars['String']['input']>;
};

export type PlaidLinkTokenRedirectInput = {
  redirect: PlaidRedirectLocation;
};

export enum PlaidRedirectLocation {
  GuestInvoice = 'GuestInvoice',
  Invoice = 'Invoice',
  Onboarding = 'Onboarding',
  QuickPayment = 'QuickPayment',
  Wallet = 'Wallet'
}

export type Plan = {
  __typename?: 'Plan';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organizations?: Maybe<Array<Organization>>;
  period?: Maybe<PlanPeriod>;
  stripePlanID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PlanOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationWhereInput>;
};

export enum PlanPeriod {
  Annual = 'Annual',
  Daily = 'Daily',
  Monthly = 'Monthly'
}

export type PlanProduct = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Array<InputMaybe<StripeMetaData>>>;
  name: Scalars['String']['input'];
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
};

export type PlanWhereInput = {
  AND?: InputMaybe<Array<PlanWhereInput>>;
  NOT?: InputMaybe<Array<PlanWhereInput>>;
  OR?: InputMaybe<Array<PlanWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizations?: InputMaybe<PlanWhereOrganizationsInput>;
  period?: InputMaybe<PlanPeriod>;
  stripePlanID?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlanWhereOrganizationsInput = {
  every?: InputMaybe<OrganizationWhereInput>;
  none?: InputMaybe<OrganizationWhereInput>;
  some?: InputMaybe<OrganizationWhereInput>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID']['output'];
};

export type Proposal = {
  __typename?: 'Proposal';
  automatedRemindersEnabled: Scalars['Boolean']['output'];
  contact: Contact;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['DateTime']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  guestToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isPastDue: Scalars['Boolean']['output'];
  proposalTemplate?: Maybe<ProposalTemplate>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentByUser?: Maybe<OrgUser>;
  sentByUsername?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['Json']['output']>;
  status: ProposalStatus;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProposalCreateInput = {
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contact: ContactCreateOneWithoutProposalsInput;
  dueDate: Scalars['DateTime']['input'];
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  proposalTemplate?: InputMaybe<ProposalTemplateCreateOneWithoutProposalsInput>;
  source?: InputMaybe<Scalars['Json']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum ProposalStatus {
  Accepted = 'Accepted',
  Deleted = 'Deleted',
  Draft = 'Draft',
  Sent = 'Sent',
  Void = 'Void'
}

export type ProposalTemplate = {
  __typename?: 'ProposalTemplate';
  createdAt: Scalars['DateTime']['output'];
  createdBy: OrgUser;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  proposals?: Maybe<Array<Proposal>>;
  source?: Maybe<Scalars['Json']['output']>;
  status: ProposalTemplateStatus;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProposalTemplateProposalsArgs = {
  where?: InputMaybe<ProposalWhereInput>;
};

export type ProposalTemplateCreateInput = {
  copyImageFrom?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['Json']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ProposalTemplateCreateOneWithoutProposalsInput = {
  connect: ProposalTemplateWhereUniqueInput;
};

export enum ProposalTemplateStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Inactive = 'Inactive'
}

export type ProposalTemplateUpdateInput = {
  copyImageFrom?: InputMaybe<Scalars['String']['input']>;
  deleteImage?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['Json']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ProposalTemplateWhereOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  title?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type ProposalTemplateWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type ProposalTemplates = {
  __typename?: 'ProposalTemplates';
  _count: Scalars['Int']['output'];
  proposalTemplates: Array<ProposalTemplate>;
};

export type ProposalTemplatesWhereInput = {
  hasSource?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalUpdateInput = {
  automatedRemindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contact?: InputMaybe<ContactCreateOneWithoutProposalsInput>;
  deleteImage?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  proposalTemplate?: InputMaybe<ProposalTemplateCreateOneWithoutProposalsInput>;
  source?: InputMaybe<Scalars['Json']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ProposalWhereInput = {
  guestToken?: InputMaybe<Scalars['String']['input']>;
  proposal: ProposalWhereUniqueInput;
};

export type ProposalWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type Proposals = {
  __typename?: 'Proposals';
  _count: Scalars['Int']['output'];
  proposals: Array<Proposal>;
};

export type ProposalsOrderByInput = {
  contact?: InputMaybe<ContactOrderByInput>;
  createdAt?: InputMaybe<SortOrderType>;
  dueDate?: InputMaybe<SortOrderType>;
  eventDate?: InputMaybe<SortOrderType>;
  isPastDue?: InputMaybe<SortOrderType>;
  sentAt?: InputMaybe<SortOrderType>;
  sentByUser?: InputMaybe<OrgUserOrderByInput>;
  sentByUsername?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  title?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type ProposalsWhereInput = {
  AND?: InputMaybe<Array<ProposalsWhereInput>>;
  NOT?: InputMaybe<Array<ProposalsWhereInput>>;
  OR?: InputMaybe<Array<ProposalsWhereInput>>;
  contact?: InputMaybe<CustomContactInput>;
  guestToken?: InputMaybe<Scalars['String']['input']>;
  isPastDue?: InputMaybe<Scalars['Boolean']['input']>;
  proposal?: InputMaybe<ProposalWhereUniqueInput>;
  status?: InputMaybe<ProposalStatus>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<SortOrderInequalityInput>;
};

export type Query = {
  __typename?: 'Query';
  calculateQuickPaymentSubtotal: CalculateQuickPaymentSubtotal;
  downloadResourceWhere: CustomResource;
  exportCustomerOfflineTransactionHistory: TransactionHistoryUrl;
  exportCustomerTransactionHistory: TransactionHistoryUrl;
  exportInquiryData: Scalars['String']['output'];
  exportInvoice?: Maybe<InvoiceUrl>;
  exportOrgOfflineTransactionHistory: TransactionHistoryUrl;
  exportOrgTransactionHistory: Scalars['Boolean']['output'];
  getApiVersion: ApiVersionResponse;
  getAuthedUser?: Maybe<AuthedUser>;
  getClientUserByEmail?: Maybe<ClientUser>;
  getClientUserWhere?: Maybe<ClientUser>;
  getClientUsersWhere: ClientUsers;
  getConnectOnboardingUrl?: Maybe<StripeOnboardingUrl>;
  getContactWhere?: Maybe<Contact>;
  getContactsWhere: Contacts;
  getContractWhere: ResponseObjectForGetContractWhere;
  getContractsForCustomer: ContractsForCustomer;
  getContractsForVendor: ContractsForVendor;
  getCouponDetails?: Maybe<ReducedCoupon>;
  getCouponForSubscription: ReducedCoupon;
  getCustomerOfflineTransactionHistory?: Maybe<OfflinePaymentHistory>;
  getDashboardStatsForClient?: Maybe<ClientDashboardStats>;
  getDashboardStatsForOrg?: Maybe<OrgDashboardStats>;
  getDocumentWhere: CustomDocumentWithDownloadUrl;
  getDocumentsWhere: Documents;
  getEventsWhere?: Maybe<Array<Event>>;
  getFeatureFlags: Array<ReducedFeatureFlag>;
  getFolderContents?: Maybe<CustomFolder>;
  getGlobalVariables: FormattedGlobalVariables;
  getGroupsForOrganization?: Maybe<Array<TestingGroup>>;
  getGroupsForUser?: Maybe<Array<TestingGroup>>;
  getHistoryForPaymentMethod: Array<PaymentHistoryItem>;
  getInquiriesForOrganization: InquiriesForOrganization;
  getInquiryForm?: Maybe<InquiryForm>;
  getInquiryFormApiKey: Scalars['String']['output'];
  getInquiryWhere: Inquiry;
  getInvitationWhere?: Maybe<Invitation>;
  getInvoiceTemplateWhere?: Maybe<InvoiceTemplate>;
  getInvoiceTemplatesWhere?: Maybe<InvoiceTemplates>;
  getInvoiceWhere?: Maybe<Invoice>;
  getInvoicesForCustomer: InvoicesForCustomer;
  getInvoicesWhere: Invoices;
  getNextStripeInvoice?: Maybe<StripeInvoice>;
  getOrgOfflineTransactionHistory?: Maybe<OfflinePaymentHistory>;
  getOrgTransactionHistory: TransactionHistory;
  getOrgUserByEmail?: Maybe<OrgUser>;
  getOrgUserWhere?: Maybe<OrgUser>;
  getOrgUsersWhere: OrgUsers;
  getOrganizationContractTemplate?: Maybe<ContractTemplate>;
  getOrganizationContractTemplates: ContractTemplates;
  getOrganizationFromInvitation?: Maybe<Organization>;
  getOrganizationWhere?: Maybe<OrganizationWithCount>;
  getOrganizationsWhere: Organizations;
  getPasswordResetTokenByEmail: PasswordResetToken;
  getPaymentInstallmentDetails: PaymentInstallmentDetails;
  getPaymentSourcesForCustomer: SourcesWithDefaultSource;
  getPendingInvitations: PendingInvitations;
  getProjectsForOrganization?: Maybe<Array<Project>>;
  getProposalTemplateWhere?: Maybe<ProposalTemplate>;
  getProposalTemplatesWhere: ProposalTemplates;
  getProposalWhere: Proposal;
  getProposalsWhere: Proposals;
  getQuickPayment: QuickPaymentForGetQuickPayment;
  getQuickPayments: Array<Maybe<QuickPaymentForGetQuickPayment>>;
  getResourceWhere: CustomResource;
  getResourcesWhere: CustomResources;
  getServicesWhere: Array<Service>;
  getSharableGuestInvoiceLink: InvoiceUrl;
  getStripeConnectAccount?: Maybe<StripeConnectAccount>;
  getStripeCustomer?: Maybe<StripeUser>;
  getStripeInvoicesForVendorCustomer: StripeInvoicesList;
  getStripePlan: StripePlan;
  getStripePlans: Array<StripePlan>;
  getStripeTokenFromPlaidToken: Scalars['String']['output'];
  getStripeVendorCustomer: StripeUser;
  getStripeVendorSubscription?: Maybe<SubscriptionObject>;
  getTestingGroups?: Maybe<Array<TestingGroup>>;
  getTotalForSource: Scalars['Int']['output'];
  getUserWhere?: Maybe<User>;
  getVerificationToken: VerificationToken;
  getVerificationTokenByEmail: VerificationToken;
  isEmailAvailable: Scalars['Boolean']['output'];
  listResources: Array<Maybe<CustomResource>>;
  listSponsors: Array<Maybe<CustomSponsor>>;
  login?: Maybe<AuthPayload>;
  privilegedGetStripeVendorSubscription?: Maybe<SubscriptionObject>;
  requestContractFileImageUploadUrl: UploadUrl;
  requestContractTemplateFileImageUploadUrl: UploadUrl;
  requestInvoiceAttachmentUploadUrl: UploadUrl;
  requestOrgProfileImageUploadUrl: UploadUrl;
  requestUserProfileImageUploadUrl: UploadUrl;
};


export type QueryCalculateQuickPaymentSubtotalArgs = {
  data?: InputMaybe<CalculateQuickPaymentSubtotalInput>;
  where: QuickPaymentUniqueInput;
};


export type QueryDownloadResourceWhereArgs = {
  where: ResourceWhereUniqueInput;
};


export type QueryExportCustomerOfflineTransactionHistoryArgs = {
  data: ExportHistoryInput;
  where: UserWhereUniqueInput;
};


export type QueryExportCustomerTransactionHistoryArgs = {
  data: ExportHistoryInput;
  where: UserWhereUniqueInput;
};


export type QueryExportInquiryDataArgs = {
  where: ExportInquiriesDataWhereInput;
};


export type QueryExportInvoiceArgs = {
  data?: InputMaybe<InvoiceExportInput>;
  where: InvoiceWhereUniqueInput;
};


export type QueryExportOrgOfflineTransactionHistoryArgs = {
  data: ExportHistoryInput;
  where: OrganizationWhereUniqueInput;
};


export type QueryExportOrgTransactionHistoryArgs = {
  data: ExportHistoryInput;
  where: OrganizationWhereUniqueInput;
};


export type QueryGetAuthedUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetClientUserByEmailArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetClientUserWhereArgs = {
  where: ClientUserWhereUniqueInput;
};


export type QueryGetClientUsersWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ClientUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientUserWhereInput>;
};


export type QueryGetConnectOnboardingUrlArgs = {
  data: StripeOnboardingInput;
};


export type QueryGetContactWhereArgs = {
  where: ContactWhereInput;
};


export type QueryGetContactsWhereArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: ContactWhereInput;
};


export type QueryGetContractWhereArgs = {
  where: ContractWhereInputWithGuestToken;
};


export type QueryGetContractsForCustomerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContractOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: ContractsForCustomerInput;
};


export type QueryGetContractsForVendorArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContractOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: ContractsForVendorInput;
};


export type QueryGetCouponDetailsArgs = {
  where: CouponByNameInput;
};


export type QueryGetCouponForSubscriptionArgs = {
  where: CouponWhereUniqueInput;
};


export type QueryGetCustomerOfflineTransactionHistoryArgs = {
  after?: InputMaybe<OfflinePaymentHistoryAfterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: UserWhereUniqueInput;
};


export type QueryGetDashboardStatsForClientArgs = {
  where: ClientUserWhereUniqueInput;
};


export type QueryGetDashboardStatsForOrgArgs = {
  where: OrgUserWhereUniqueInput;
};


export type QueryGetDocumentWhereArgs = {
  where: DocumentWhereUniqueInput;
};


export type QueryGetDocumentsWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: CustomDocumentWhereInput;
};


export type QueryGetEventsWhereArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryGetFolderContentsArgs = {
  batchSort?: InputMaybe<Scalars['Boolean']['input']>;
  documentTakeOverride?: InputMaybe<Scalars['Int']['input']>;
  folderTakeOverride?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FolderContentsOrderByInput>;
  skipDocument?: InputMaybe<Scalars['Int']['input']>;
  skipFolder?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: FolderWhereUniqueInput;
};


export type QueryGetGroupsForOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryGetGroupsForUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetHistoryForPaymentMethodArgs = {
  where: PaymentMethodWhereInput;
};


export type QueryGetInquiriesForOrganizationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiriesForOrganizationWhereInput>;
};


export type QueryGetInquiryFormArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryGetInquiryWhereArgs = {
  where?: InputMaybe<InquiryWhereUniqueInput>;
};


export type QueryGetInvitationWhereArgs = {
  where: InvitationWhereCustomInput;
};


export type QueryGetInvoiceTemplateWhereArgs = {
  where: InvoiceTemplateWhereUniqueInput;
};


export type QueryGetInvoiceTemplatesWhereArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<InvoiceTemplateOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetInvoiceWhereArgs = {
  data?: InputMaybe<InvoiceTokenInput>;
  where: InvoiceWhereUniqueInput;
};


export type QueryGetInvoicesForCustomerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<InvoiceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GetInvoicesForCustomerInput>;
};


export type QueryGetInvoicesWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<InvoiceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryGetOrgOfflineTransactionHistoryArgs = {
  after?: InputMaybe<OfflinePaymentHistoryAfterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: OrganizationWhereUniqueInput;
};


export type QueryGetOrgTransactionHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  where: OrganizationWhereUniqueInput;
};


export type QueryGetOrgUserByEmailArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetOrgUserWhereArgs = {
  where: OrgUserWhereUniqueInput;
};


export type QueryGetOrgUsersWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrgUserOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgUserWhereInput>;
};


export type QueryGetOrganizationContractTemplateArgs = {
  where?: InputMaybe<ContractTemplateWhereUniqueInput>;
};


export type QueryGetOrganizationContractTemplatesArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<ContractTemplateOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomContractTemplateWhereWithoutOrganizationInput>;
};


export type QueryGetOrganizationFromInvitationArgs = {
  where?: InputMaybe<InvitationWhereUniqueInput>;
};


export type QueryGetOrganizationWhereArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryGetOrganizationsWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganizationOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryGetPasswordResetTokenByEmailArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetPaymentInstallmentDetailsArgs = {
  where: PaymentInstallmentWhereUniqueInput;
};


export type QueryGetPaymentSourcesForCustomerArgs = {
  where: UserByIdUniqueInput;
};


export type QueryGetPendingInvitationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvitationsWhereCustomInput>;
};


export type QueryGetProposalTemplateWhereArgs = {
  where: ProposalTemplateWhereUniqueInput;
};


export type QueryGetProposalTemplatesWhereArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<ProposalTemplateWhereOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProposalTemplatesWhereInput>;
};


export type QueryGetProposalWhereArgs = {
  where: ProposalWhereInput;
};


export type QueryGetProposalsWhereArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<ProposalsOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProposalsWhereInput>;
};


export type QueryGetQuickPaymentArgs = {
  where: QuickPaymentUniqueInput;
};


export type QueryGetQuickPaymentsArgs = {
  where: OrganizationWhereInput;
};


export type QueryGetResourceWhereArgs = {
  where: ResourceWhereUniqueInput;
};


export type QueryGetResourcesWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ResourceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryGetServicesWhereArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ServiceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QueryGetSharableGuestInvoiceLinkArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryGetStripeConnectAccountArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryGetStripeCustomerArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetStripePlanArgs = {
  where: StripePlanRetrievalInput;
};


export type QueryGetStripeTokenFromPlaidTokenArgs = {
  data: PlaidInput;
};


export type QueryGetStripeVendorCustomerArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryGetTestingGroupsArgs = {
  where?: InputMaybe<TestingGroupWhereInput>;
};


export type QueryGetTotalForSourceArgs = {
  where: PaymentMethodWhereInput;
};


export type QueryGetUserWhereArgs = {
  where: UserWhereInput;
};


export type QueryGetVerificationTokenArgs = {
  where: VerificationTokenWhereUniqueInput;
};


export type QueryGetVerificationTokenByEmailArgs = {
  where: VerificationTokenWhereInput;
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String']['input'];
};


export type QueryListResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ResourceOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};


export type QueryListSponsorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SponsorOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SponsorWhereInput>;
};


export type QueryLoginArgs = {
  data: UserLoginInput;
};


export type QueryPrivilegedGetStripeVendorSubscriptionArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryRequestContractFileImageUploadUrlArgs = {
  data: RequestImageUploadUrlInput;
  where: ContractWhereUniqueInput;
};


export type QueryRequestContractTemplateFileImageUploadUrlArgs = {
  data: RequestImageUploadUrlInput;
  where: ContractTemplateWhereUniqueInput;
};


export type QueryRequestInvoiceAttachmentUploadUrlArgs = {
  data: RequestAttachmentUploadUrlInput;
  where: InvoiceWhereUniqueInput;
};


export type QueryRequestOrgProfileImageUploadUrlArgs = {
  data: RequestImageUploadUrlInput;
};


export type QueryRequestUserProfileImageUploadUrlArgs = {
  data: RequestImageUploadUrlInput;
};

export type QueryFieldsForInvoiceSearch = {
  OR?: InputMaybe<Array<InputMaybe<GetInvoicesForCustomerInput>>>;
  contact?: InputMaybe<ContactWhereForInvoiceSearch>;
  status?: InputMaybe<InvoiceStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  uploadAttachment?: InputMaybe<Scalars['String']['input']>;
  uploadAttachment_contains?: InputMaybe<Scalars['String']['input']>;
};

export type QuickPayment = {
  __typename?: 'QuickPayment';
  id?: Maybe<Scalars['ID']['output']>;
};

export type QuickPaymentForGetQuickPayment = {
  __typename?: 'QuickPaymentForGetQuickPayment';
  amount?: Maybe<Scalars['Int']['output']>;
  contact?: Maybe<ContactForGetQuickPayment>;
  id: Scalars['ID']['output'];
  organization: OrganizationForGetQuickPayment;
  reason?: Maybe<Scalars['String']['output']>;
};

export type QuickPaymentLink = {
  __typename?: 'QuickPaymentLink';
  url?: Maybe<Scalars['String']['output']>;
};

export type QuickPaymentLinkCreateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  contact?: InputMaybe<ConnectToUniqueContactInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type QuickPaymentOrderByInput = {
  id?: InputMaybe<SortOrderType>;
};

export type QuickPaymentUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RecordOfflinePaymentInput = {
  offlinePaymentType: OfflinePaymentType;
  paymentDate: Scalars['DateTime']['input'];
  paymentNote?: InputMaybe<Scalars['String']['input']>;
};

export type RecordOfflineRefundInput = {
  amountRefunded: Scalars['Int']['input'];
  refundDate: Scalars['DateTime']['input'];
  refundNote?: InputMaybe<Scalars['String']['input']>;
};

export type RedirectUrl = {
  __typename?: 'RedirectUrl';
  guestToken?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  redirect: Scalars['String']['output'];
};

export type ReducedClientUser = {
  __typename?: 'ReducedClientUser';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  firstNameOne?: Maybe<Scalars['String']['output']>;
  firstNameTwo?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  lastNameOne?: Maybe<Scalars['String']['output']>;
  lastNameTwo?: Maybe<Scalars['String']['output']>;
  originalImage?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pinterest?: Maybe<Scalars['String']['output']>;
  plannerOrgCanPayInvoices?: Maybe<Scalars['Boolean']['output']>;
  plannerOrgCanSignContracts?: Maybe<Scalars['Boolean']['output']>;
  plannerOrgHasConnectionPermissions?: Maybe<Scalars['Boolean']['output']>;
  plannerOrgHasDocumentsPermissions?: Maybe<Scalars['Boolean']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<State>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: ReducedUserWithEmail;
  venue?: Maybe<Scalars['String']['output']>;
  weddingDate?: Maybe<Scalars['DateTime']['output']>;
};

export type ReducedContact = {
  __typename?: 'ReducedContact';
  customer: ReducedCustomer;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  status: ContactStatus;
};

export type ReducedCoupon = {
  __typename?: 'ReducedCoupon';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  duration_in_months?: Maybe<Scalars['Int']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  percent_off: Scalars['Int']['output'];
  stripeID: Scalars['String']['output'];
};

export type ReducedCustomer = {
  __typename?: 'ReducedCustomer';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ReducedFeatureFlag = {
  __typename?: 'ReducedFeatureFlag';
  active: Scalars['Boolean']['output'];
  category: FeatureCategory;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReducedGuestUser = {
  __typename?: 'ReducedGuestUser';
  id: Scalars['String']['output'];
  user: ReducedUserWithEmail;
};

export type ReducedInvoice = {
  __typename?: 'ReducedInvoice';
  gratuityAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReducedPaymentInstallment = {
  __typename?: 'ReducedPaymentInstallment';
  amountDue: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ReducedUserWithEmail = {
  __typename?: 'ReducedUserWithEmail';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Referral = {
  __typename?: 'Referral';
  category: ReferralCategory;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  other?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export enum ReferralCategory {
  Blog = 'Blog',
  Colleague = 'Colleague',
  Conference = 'Conference',
  Facebook = 'Facebook',
  Google = 'Google',
  Instagram = 'Instagram',
  Other = 'Other'
}

export type ReferralCreateWithoutUserInput = {
  category: ReferralCategory;
  id?: InputMaybe<Scalars['ID']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
};

export type ReferralWhereInput = {
  AND?: InputMaybe<Array<ReferralWhereInput>>;
  NOT?: InputMaybe<Array<ReferralWhereInput>>;
  OR?: InputMaybe<Array<ReferralWhereInput>>;
  category?: InputMaybe<ReferralCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserWhereInput>;
};

export type RefundInstallmentInput = {
  amount: Scalars['Int']['input'];
  installmentID: Scalars['String']['input'];
  refundNote?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCouponUsageWhere = {
  coupon: CouponWhereUniqueInput;
  timeZone: Scalars['String']['input'];
};

export type Relationship = {
  __typename?: 'Relationship';
  director?: Maybe<Scalars['Boolean']['output']>;
  executive?: Maybe<Scalars['Boolean']['output']>;
  owner?: Maybe<Scalars['Boolean']['output']>;
  percent_ownership?: Maybe<Scalars['String']['output']>;
  representative?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RelationshipInput = {
  director?: InputMaybe<Scalars['Boolean']['input']>;
  executive?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  percent_ownership?: InputMaybe<Scalars['String']['input']>;
  representative?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ReportContractSigningInput = {
  signingAs: Scalars['ID']['input'];
};

export type ReportInput = {
  bundle?: InputMaybe<BundleInput>;
  customizedEmail?: InputMaybe<CustomizedEmailInput>;
  page: Scalars['String']['input'];
};

export type RequestAttachmentUploadUrlInput = {
  fileType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type RequestImageUploadUrlInput = {
  fileType: Scalars['String']['input'];
};

export type RequirementError = {
  __typename?: 'RequirementError';
  code: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  requirement: Scalars['String']['output'];
};

export type RequirementsDetails = {
  __typename?: 'RequirementsDetails';
  current_deadline?: Maybe<Scalars['Int']['output']>;
  currently_due: Array<Scalars['String']['output']>;
  disabled_reason?: Maybe<Scalars['String']['output']>;
  errors: Array<RequirementError>;
  eventually_due: Array<Scalars['String']['output']>;
  past_due: Array<Scalars['String']['output']>;
  pending_verification: Array<Scalars['String']['output']>;
};

export type Resource = {
  __typename?: 'Resource';
  availableUntil?: Maybe<Scalars['DateTime']['output']>;
  category: ResourceCategory;
  categoryName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  fileType: FileType;
  id: Scalars['ID']['output'];
  sponsor: Sponsor;
  sponsorName: Scalars['String']['output'];
  status: ResourceStatus;
  updatedAt: Scalars['DateTime']['output'];
  verifiedPreview: Scalars['Boolean']['output'];
};

export enum ResourceCategory {
  All = 'All',
  Catering = 'Catering',
  Contract = 'Contract',
  Entertainment = 'Entertainment',
  Florist = 'Florist',
  Music = 'Music',
  Other = 'Other',
  Photographer = 'Photographer',
  Planners = 'Planners',
  Stationery = 'Stationery',
  Venue = 'Venue',
  Videographer = 'Videographer'
}

export type ResourceOrderByInput = {
  availableUntil?: InputMaybe<SortOrderType>;
  category?: InputMaybe<SortOrderType>;
  categoryName?: InputMaybe<SortOrderType>;
  createdAt?: InputMaybe<SortOrderType>;
  description?: InputMaybe<SortOrderType>;
  fileType?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  sponsorName?: InputMaybe<SortOrderType>;
  status?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  verifiedPreview?: InputMaybe<SortOrderType>;
};

export enum ResourceStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type ResourceWhereInput = {
  AND?: InputMaybe<Array<ResourceWhereInput>>;
  NOT?: InputMaybe<Array<ResourceWhereInput>>;
  OR?: InputMaybe<Array<ResourceWhereInput>>;
  availableUntil?: InputMaybe<Scalars['DateTime']['input']>;
  category?: InputMaybe<ResourceCategory>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
  categoryName_contains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<FileType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sponsor?: InputMaybe<SponsorWhereInput>;
  sponsorName?: InputMaybe<Scalars['String']['input']>;
  sponsorName_contains?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ResourceStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verifiedPreview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ResponseObjectForGetContractWhere = {
  __typename?: 'ResponseObjectForGetContractWhere';
  contract: ContractForGetContractWhere;
  requestingUserBaseId: Scalars['ID']['output'];
};

export type RetoolResourceCreateInput = {
  availableUntil: Scalars['DateTime']['input'];
  category: ResourceCategory;
  description: Scalars['String']['input'];
  fileType: FileType;
  previewFilename: Scalars['String']['input'];
  sponsor: RetoolSponsorConnectInput;
  status: ResourceStatus;
};

export type RetoolResourceUpdateInput = {
  availableUntil?: InputMaybe<Scalars['DateTime']['input']>;
  category?: InputMaybe<ResourceCategory>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<FileType>;
  previewFilename?: InputMaybe<Scalars['String']['input']>;
  sponsor?: InputMaybe<RetoolSponsorConnectInput>;
  status?: InputMaybe<ResourceStatus>;
};

export type RetoolSponsorConnectInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
};

export type RetoolSponsorCreateInput = {
  description: Scalars['String']['input'];
  logoFilename: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sponsorLink: Scalars['String']['input'];
};

export type RetoolSponsorUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  logoFilename?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sponsorLink?: InputMaybe<Scalars['String']['input']>;
};

export type ReturningMetaData = {
  __typename?: 'ReturningMetaData';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ReturningStripeMetadata = {
  __typename?: 'ReturningStripeMetadata';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SendOrResendInvoiceInput = {
  bundle?: InputMaybe<BundleInput>;
  customizedEmail?: InputMaybe<CustomizedEmailInput>;
};

export type SendProposalInput = {
  bundle?: InputMaybe<BundleInput>;
  customizedEmail?: InputMaybe<CustomizedEmailInput>;
};

export type SentByUserInputForContracts = {
  AND?: InputMaybe<Array<SentByUserInputForContracts>>;
  NOT?: InputMaybe<Array<SentByUserInputForContracts>>;
  OR?: InputMaybe<Array<SentByUserInputForContracts>>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
};

export type Service = {
  __typename?: 'Service';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizations?: Maybe<Array<Organization>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ServiceOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrganizationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type ServiceCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizations?: InputMaybe<OrganizationCreateManyWithoutServicesInput>;
};

export type ServiceOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  description?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  name?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<FServiceWhereOrganizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServicesForActingUser = {
  __typename?: 'ServicesForActingUser';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ServicesForGetContractWhere = {
  __typename?: 'ServicesForGetContractWhere';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ServicesForGetQuickPayment = {
  __typename?: 'ServicesForGetQuickPayment';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SettingsDetails = {
  __typename?: 'SettingsDetails';
  branding?: Maybe<BrandingSettings>;
  card_payments?: Maybe<CardPaymentsSettings>;
  dashboard?: Maybe<DashboardSettings>;
  payments?: Maybe<PaymentsSettings>;
  payouts?: Maybe<PayoutsSettings>;
};

export type SettingsDetailsInput = {
  branding?: InputMaybe<BrandingSettingsInput>;
  card_payments?: InputMaybe<CardPaymentsSettingsInput>;
  dashboard?: InputMaybe<DashboardSettingsInput>;
  payments?: InputMaybe<PaymentsSettingsInput>;
  payouts?: InputMaybe<PayoutsSettingsInput>;
};

export type Signature = {
  __typename?: 'Signature';
  contract?: Maybe<SignatureContract>;
  createdAt: Scalars['DateTime']['output'];
  customerSignature?: Maybe<CustomerSignature>;
  hellosignSignatureId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  signer?: Maybe<SignatureUser>;
  signingDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<SignatureType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SignatureContract = {
  __typename?: 'SignatureContract';
  guestToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: ContractStatus;
  title: Scalars['String']['output'];
};

export type SignatureContractWhereInput = {
  id: Scalars['ID']['input'];
};

export type SignatureCounterSigner = {
  __typename?: 'SignatureCounterSigner';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  user: SignatureCounterSignerUser;
};

export type SignatureCounterSignerUser = {
  __typename?: 'SignatureCounterSignerUser';
  id: Scalars['ID']['output'];
};

export type SignatureCreateInput = {
  signerId: Scalars['String']['input'];
  type: SignatureType;
};

export type SignatureCreateManyDataInput = {
  data?: InputMaybe<Array<SignatureCreateInput>>;
};

export type SignatureForGetContractWhere = {
  __typename?: 'SignatureForGetContractWhere';
  contract?: Maybe<ContractForGetContractWhere>;
  createdAt: Scalars['DateTime']['output'];
  customerSignature?: Maybe<CustomerSignatureForGetContractWhere>;
  hellosignSignatureId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  signer?: Maybe<SignatureUserForGetContractWhere>;
  signingDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<SignatureType>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum SignatureType {
  CounterSigner = 'CounterSigner',
  Customer = 'Customer'
}

export type SignatureUser = {
  __typename?: 'SignatureUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orgUser?: Maybe<SignatureCounterSigner>;
  userType: UserType;
};

export type SignatureUserForGetContractWhere = {
  __typename?: 'SignatureUserForGetContractWhere';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orgUser?: Maybe<OrgUserSignerForGetContractWhere>;
  stripeID?: Maybe<Scalars['String']['output']>;
  userType: UserType;
};

export type SignatureUserWhereInput = {
  id: Scalars['ID']['input'];
};

export type SignaturesCreateManyInput = {
  createMany: SignatureCreateManyDataInput;
};

export type SignaturesWhereInput = {
  contract?: InputMaybe<SignatureContractWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerSignature?: InputMaybe<CustomerSignatureWhereInput>;
  hellosignSignatureId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  signer?: InputMaybe<SignatureUserWhereInput>;
  signingDate?: InputMaybe<Scalars['DateTime']['input']>;
  some?: InputMaybe<SignaturesWhereInput>;
  type?: InputMaybe<SignatureType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SigningUrl = {
  __typename?: 'SigningUrl';
  url: Scalars['String']['output'];
};

export type SortOrderInequalityInput = {
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
};

export enum SortOrderType {
  Asc = 'asc',
  Desc = 'desc'
}

export type Source = {
  __typename?: 'Source';
  account_holder_name?: Maybe<Scalars['String']['output']>;
  account_holder_type?: Maybe<Scalars['String']['output']>;
  address_zip?: Maybe<Scalars['String']['output']>;
  address_zip_check?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  customer?: Maybe<Scalars['String']['output']>;
  cvc_check?: Maybe<Scalars['String']['output']>;
  dynamic_last4?: Maybe<Scalars['String']['output']>;
  exp_month?: Maybe<Scalars['Int']['output']>;
  exp_year?: Maybe<Scalars['Int']['output']>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  funding?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  metadata: Array<ReturningMetaData>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tokenization_method?: Maybe<Scalars['String']['output']>;
};

export type SourceOrRedirect = RedirectUrl | Source;

export type Sources = {
  __typename?: 'Sources';
  data?: Maybe<Array<Maybe<Source>>>;
  has_more?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  total_count?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SourcesWithDefaultSource = {
  __typename?: 'SourcesWithDefaultSource';
  data?: Maybe<Array<Source>>;
  default_source?: Maybe<Scalars['String']['output']>;
  has_more?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  total_count?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Sponsor = {
  __typename?: 'Sponsor';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resources?: Maybe<Array<Resource>>;
  sponsorLink: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  verifiedLogo: Scalars['Boolean']['output'];
};


export type SponsorResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ResourceWhereInput>;
};

export type SponsorOrderByInput = {
  createdAt?: InputMaybe<SortOrderType>;
  description?: InputMaybe<SortOrderType>;
  id?: InputMaybe<SortOrderType>;
  name?: InputMaybe<SortOrderType>;
  sponsorLink?: InputMaybe<SortOrderType>;
  updatedAt?: InputMaybe<SortOrderType>;
  verifiedLogo?: InputMaybe<SortOrderType>;
};

export type SponsorWhereInput = {
  AND?: InputMaybe<Array<SponsorWhereInput>>;
  NOT?: InputMaybe<Array<SponsorWhereInput>>;
  OR?: InputMaybe<Array<SponsorWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<SponsorWhereResourcesInput>;
  sponsorLink?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verifiedLogo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SponsorWhereResourcesInput = {
  every?: InputMaybe<ResourceWhereInput>;
  none?: InputMaybe<ResourceWhereInput>;
  some?: InputMaybe<ResourceWhereInput>;
};

export type SponsorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export { State };

export type StripeBankAccountCreationInput = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  source: StripeBankAccountSourceInput;
};

export type StripeBankAccountDeletionInput = {
  source: Scalars['String']['input'];
};

export type StripeBankAccountSourceInput = {
  account_holder_name: Scalars['String']['input'];
  account_holder_type: Scalars['String']['input'];
  account_number: Scalars['String']['input'];
  country: Country;
  currency: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  object: BankAccountObject;
  routing_number: Scalars['String']['input'];
};

export type StripeBankAccountUpdateInput = {
  accountUpdates?: InputMaybe<StripeBankAccountUpdates>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
};

export type StripeBankAccountUpdates = {
  account_holder_name?: InputMaybe<Scalars['String']['input']>;
  account_holder_type?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
};

export type StripeCardCreationInput = {
  isDefault: Scalars['Boolean']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type StripeCardUpdateInfoInput = {
  address_zip?: InputMaybe<Scalars['String']['input']>;
  exp_month?: InputMaybe<Scalars['Int']['input']>;
  exp_year?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
};

export type StripeConnectAccount = {
  __typename?: 'StripeConnectAccount';
  business_profile?: Maybe<BusinessProfile>;
  business_type?: Maybe<Scalars['String']['output']>;
  capabilities?: Maybe<AccountCapabilities>;
  charges_enabled?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<CompanyDetails>;
  country?: Maybe<Country>;
  created?: Maybe<Scalars['Int']['output']>;
  default_currency?: Maybe<Scalars['String']['output']>;
  details_submitted?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  external_accounts?: Maybe<Sources>;
  future_requirements?: Maybe<RequirementsDetails>;
  id?: Maybe<Scalars['String']['output']>;
  individual?: Maybe<StripePerson>;
  metadata: Array<ReturningMetaData>;
  object?: Maybe<Scalars['String']['output']>;
  payouts_enabled?: Maybe<Scalars['Boolean']['output']>;
  product_description?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<RequirementsDetails>;
  settings?: Maybe<SettingsDetails>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  support_address?: Maybe<Address>;
  support_email?: Maybe<Scalars['String']['output']>;
  support_phone?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  tos_acceptance?: Maybe<TosAcceptance>;
  type?: Maybe<Scalars['String']['output']>;
};

export type StripeConnectAccountCreationInput = {
  business_type: Scalars['String']['input'];
  company?: InputMaybe<CompanyDetailsInput>;
  country: Country;
  tos_acceptance: StripeTosAcceptanceInput;
};

export type StripeConnectAccountUpdateInput = {
  account_token?: InputMaybe<Scalars['String']['input']>;
  business_profile?: InputMaybe<BusinessProfileInput>;
  business_type?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyDetailsInput>;
  default_currency?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  external_account?: InputMaybe<StripeBankAccountSourceInput>;
  individual?: InputMaybe<StripePersonInput>;
  metadata?: InputMaybe<Array<StripeMetaData>>;
  settings?: InputMaybe<SettingsDetailsInput>;
  tos_acceptance?: InputMaybe<StripeTosAcceptanceInput>;
};

export type StripeCoupon = {
  __typename?: 'StripeCoupon';
  amount_off?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  duration_in_months?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  max_redemptions?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Array<Maybe<ReturningMetaData>>>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  percent_off?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  redeem_by?: Maybe<Scalars['Int']['output']>;
  times_redeemed?: Maybe<Scalars['Int']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeExternalAccountUpdateInput = {
  accountUpdates?: InputMaybe<StripeBankAccountUpdates>;
  newAccountUpdates?: InputMaybe<NewExternalAccountUpdateInput>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  amount_due: Scalars['Int']['output'];
  amount_paid?: Maybe<Scalars['Int']['output']>;
  amount_remaining?: Maybe<Scalars['Int']['output']>;
  application_fee?: Maybe<Scalars['String']['output']>;
  attempt_count?: Maybe<Scalars['Int']['output']>;
  attempted?: Maybe<Scalars['Boolean']['output']>;
  auto_advance?: Maybe<Scalars['Boolean']['output']>;
  billing?: Maybe<Scalars['String']['output']>;
  billing_reason?: Maybe<Scalars['String']['output']>;
  charge?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Int']['output']>;
  default_source?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  due_date?: Maybe<Scalars['Int']['output']>;
  finalized_at?: Maybe<Scalars['Int']['output']>;
  hosted_invoice_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_pdf?: Maybe<Scalars['String']['output']>;
  lines?: Maybe<StripeInvoiceLines>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Array<Maybe<ReturningMetaData>>>;
  next_payment_attempt?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  paid?: Maybe<Scalars['Boolean']['output']>;
  period_end?: Maybe<Scalars['Int']['output']>;
  period_start?: Maybe<Scalars['Int']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  starting_balance?: Maybe<Scalars['Int']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<Scalars['String']['output']>;
  subscription_proration_date?: Maybe<Scalars['Int']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Int']['output']>;
};

export type StripeInvoiceLines = {
  __typename?: 'StripeInvoiceLines';
  data?: Maybe<Array<Maybe<StripeLineItem>>>;
  has_more?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type StripeInvoicesList = {
  __typename?: 'StripeInvoicesList';
  data: Array<Maybe<StripeInvoice>>;
  has_more: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
};

export type StripeLineItem = {
  __typename?: 'StripeLineItem';
  amount?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer: Scalars['String']['output'];
  date?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  invoice?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Array<Maybe<ReturningMetaData>>>;
  object?: Maybe<Scalars['String']['output']>;
};

export type StripeMetaData = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum StripeOnboardingFormType {
  AccountOnboarding = 'account_onboarding',
  AccountUpdate = 'account_update'
}

export type StripeOnboardingInput = {
  return_url?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StripeOnboardingFormType>;
};

export type StripeOnboardingUrl = {
  __typename?: 'StripeOnboardingURL';
  url: Scalars['String']['output'];
};

export type StripePaymentOrSetupIntentStatus = {
  autoPayRemainder?: InputMaybe<Scalars['Boolean']['input']>;
  status: Scalars['String']['input'];
  stripePaymentIntentID?: InputMaybe<Scalars['String']['input']>;
  stripeSetupIntentID?: InputMaybe<Scalars['String']['input']>;
};

export type StripePerson = {
  __typename?: 'StripePerson';
  account?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  created?: Maybe<Scalars['Int']['output']>;
  dob?: Maybe<Dob>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  id_number_provided?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  maiden_name?: Maybe<Scalars['String']['output']>;
  metadata: Array<ReturningMetaData>;
  object?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Relationship>;
  requirements?: Maybe<RequirementsDetails>;
  ssn_last_4_provided?: Maybe<Scalars['Boolean']['output']>;
  verification?: Maybe<PersonVerificationDetails>;
};

export type StripePersonInput = {
  address?: InputMaybe<AddressInput>;
  dob?: InputMaybe<DobInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  maiden_name?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Array<StripeMetaData>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<RelationshipInput>;
  ssn_last_4?: InputMaybe<Scalars['String']['input']>;
  useOrgAddress?: InputMaybe<Scalars['Boolean']['input']>;
  useOrgPhone?: InputMaybe<Scalars['Boolean']['input']>;
  verification?: InputMaybe<PersonVerificationDetailsInput>;
};

export type StripePlan = {
  __typename?: 'StripePlan';
  active?: Maybe<Scalars['Boolean']['output']>;
  aggregate_usage?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  billing_scheme?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<StripePlanInterval>;
  interval_count?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Array<Maybe<ReturningMetaData>>>;
  nickname?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  stripePlanID?: Maybe<Scalars['String']['output']>;
  trial_period_days?: Maybe<Scalars['Int']['output']>;
  usage_type?: Maybe<Scalars['String']['output']>;
};

export type StripePlanCreationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  interval: Scalars['String']['input'];
  interval_count: Scalars['Int']['input'];
  metadata?: InputMaybe<Array<InputMaybe<StripeMetaData>>>;
  product?: InputMaybe<PlanProduct>;
  trial_period_days?: InputMaybe<Scalars['Int']['input']>;
};

export enum StripePlanInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type StripePlanRetrievalInput = {
  planID: Scalars['String']['input'];
};

export enum StripeSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type StripeTosAcceptanceInput = {
  date?: InputMaybe<Scalars['Int']['input']>;
};

export type StripeUser = {
  __typename?: 'StripeUser';
  account_balance?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  default_source?: Maybe<Scalars['String']['output']>;
  delinquent?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata: Array<ReturningMetaData>;
  object?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Sources>;
  subscriptions?: Maybe<SubscriptionsList>;
};

export type StripeVendorCardCreationInput = {
  isDefault: Scalars['Boolean']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  organizationID: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type StripeVendorSubscriptionChangeInput = {
  planID: Scalars['String']['input'];
};

export type StripeVendorSubscriptionCreationInput = {
  subscriptionPlanID: Scalars['String']['input'];
};

export type SubmitInvoicePaymentInput = {
  gratuityAmount?: InputMaybe<Scalars['Int']['input']>;
  paymentInstallments: Array<SubmitInvoicePaymentInstallmentsInput>;
  paymentMethodToken?: InputMaybe<Scalars['String']['input']>;
  receiptEmails: Array<Scalars['String']['input']>;
  stripePaymentMethod?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitInvoicePaymentInstallmentsInput = {
  amountDue: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  eventCreated: Event;
  inquiryChanged: Inquiry;
};

export type SubscriptionItems = {
  __typename?: 'SubscriptionItems';
  data?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  object?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionObject = {
  __typename?: 'SubscriptionObject';
  application_fee_percent?: Maybe<Scalars['Int']['output']>;
  billing?: Maybe<Scalars['String']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Int']['output']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']['output']>;
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  current_period_end?: Maybe<Scalars['Int']['output']>;
  current_period_start?: Maybe<Scalars['Int']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  days_until_due?: Maybe<Scalars['Int']['output']>;
  default_source?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  ended_at?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  items?: Maybe<SubscriptionItems>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata: Array<ReturningMetaData>;
  object?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<StripePlan>;
  quantity?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<StripeSubscriptionStatus>;
  subscriptionPlanID?: Maybe<Scalars['String']['output']>;
  trial_end?: Maybe<Scalars['Int']['output']>;
  trial_start?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionObjectOrRedirect = RedirectUrl | SubscriptionObject;

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  created?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  metadata: Array<ReturningMetaData>;
  object?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<StripePlan>;
  quantity?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'IncompleteExpired',
  PastDue = 'PastDue',
  Trialing = 'Trialing',
  Unpaid = 'Unpaid'
}

export type SubscriptionsList = {
  __typename?: 'SubscriptionsList';
  data: Array<SubscriptionObject>;
  has_more?: Maybe<Scalars['Boolean']['output']>;
  object: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SuperAdminCapability = {
  __typename?: 'SuperAdminCapability';
  capabilities?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type SuperAdminUser = {
  __typename?: 'SuperAdminUser';
  createdAt: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SuperAdminUserWhereInput = {
  AND?: InputMaybe<Array<SuperAdminUserWhereInput>>;
  NOT?: InputMaybe<Array<SuperAdminUserWhereInput>>;
  OR?: InputMaybe<Array<SuperAdminUserWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserWhereInput>;
};

export type TosAcceptance = {
  __typename?: 'TOSAcceptance';
  date: Scalars['Int']['output'];
  ip: Scalars['String']['output'];
  user_agent: Scalars['String']['output'];
};

export type TestingGroup = {
  __typename?: 'TestingGroup';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  featureFlags: Array<Maybe<FeatureFlag>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizations: Array<Maybe<Organization>>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<Maybe<User>>;
};

export type TestingGroupWhereInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlags?: InputMaybe<FeatureFlagWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<OrganizationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserWhereInput>;
};

export type TestingGroupWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  hasMore: Scalars['Boolean']['output'];
  transactionHistoryItems: Array<TransactionHistoryItem>;
};

export type TransactionHistoryChargeOrRefund = {
  __typename?: 'TransactionHistoryChargeOrRefund';
  amount: Scalars['Int']['output'];
  clientUser?: Maybe<ReducedClientUser>;
  contact?: Maybe<ReducedContact>;
  date: Scalars['Int']['output'];
  guestUser?: Maybe<ReducedGuestUser>;
  id: Scalars['String']['output'];
  invoice?: Maybe<ReducedInvoice>;
  metadata?: Maybe<Array<ReturningStripeMetadata>>;
  paymentInstallment?: Maybe<ReducedPaymentInstallment>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TransactionHistoryItem = {
  __typename?: 'TransactionHistoryItem';
  payout: TransactionHistoryPayout;
  transactions: Array<Maybe<TransactionHistoryChargeOrRefund>>;
};

export type TransactionHistoryPayout = {
  __typename?: 'TransactionHistoryPayout';
  amount: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TransactionHistoryUrl = {
  __typename?: 'TransactionHistoryUrl';
  url: Scalars['String']['output'];
};

export type UpdateContactStatusDataInput = {
  status: ContactStatus;
};

export type UpdateCustomerStripeCardWhereInput = {
  source: Scalars['String']['input'];
};

export type UpdateDocumentNameInput = {
  name: Scalars['String']['input'];
};

export type UpdateFolderNameInput = {
  name: Scalars['String']['input'];
};

export type UpdatePaidInstallmentInput = {
  offlinePaymentType?: InputMaybe<OfflinePaymentType>;
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentNote?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRefundedInstallmentInput = {
  amountRefunded?: InputMaybe<Scalars['Int']['input']>;
  refundDate?: InputMaybe<Scalars['DateTime']['input']>;
  refundNote?: InputMaybe<Scalars['String']['input']>;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  uploadURL?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  AND?: Maybe<Array<User>>;
  NOT?: Maybe<Array<User>>;
  OR?: Maybe<Array<User>>;
  acceptedTerms?: Maybe<Scalars['DateTime']['output']>;
  blocked: Scalars['Boolean']['output'];
  clientUser?: Maybe<ClientUser>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  events?: Maybe<Array<Event>>;
  eventsLastViewedAt: Scalars['DateTime']['output'];
  groups?: Maybe<Array<TestingGroup>>;
  guestUser?: Maybe<GuestUser>;
  id: Scalars['ID']['output'];
  ipAddressWhereTermsAccepted?: Maybe<Scalars['String']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  notificationPreference?: Maybe<NotificationsPreference>;
  onboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  orgUser?: Maybe<OrgUser>;
  passWordLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  passwordResetToken?: Maybe<Scalars['String']['output']>;
  passwordResetTokenExpiresAt?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Referral>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  superAdmin?: Maybe<SuperAdminUser>;
  updatedAt: Scalars['DateTime']['output'];
  userAgentWhereTermsAccepted?: Maybe<Scalars['String']['output']>;
  userType: UserType;
};


export type UserContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ContactOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};

export type UserByEmailUniqueInput = {
  email: Scalars['ID']['input'];
};

export type UserByIdUniqueInput = {
  id: Scalars['ID']['input'];
};

export type UserForSignature = {
  __typename?: 'UserForSignature';
  id: Scalars['ID']['output'];
};

export type UserLoginInput = {
  email: Scalars['String']['input'];
  mfaToken?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  targetUserEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UserTestingGroupWhereInput = {
  every?: InputMaybe<TestingGroupWhereInput>;
  none?: InputMaybe<TestingGroupWhereInput>;
  some?: InputMaybe<TestingGroupWhereInput>;
};

export enum UserType {
  ClientUser = 'ClientUser',
  GuestUser = 'GuestUser',
  OrgUser = 'OrgUser',
  SuperAdmin = 'SuperAdmin'
}

export type UserUpdateOneRequiredWithoutClientUserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserUpdateOneRequiredWithoutOrgUserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserUpdateSelfInput = {
  eventsLastViewedAt: Scalars['DateTime']['input'];
};

export type UserWhereContactsInput = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  acceptedTerms?: InputMaybe<Scalars['DateTime']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  clientUser?: InputMaybe<ClientUserWhereInput>;
  contacts?: InputMaybe<UserWhereContactsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
  every?: InputMaybe<UserWhereInput>;
  groups?: InputMaybe<UserTestingGroupWhereInput>;
  guestUser?: InputMaybe<GuestUserWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ipAddressWhereTermsAccepted?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeenAt?: InputMaybe<Scalars['DateTime']['input']>;
  none?: InputMaybe<UserWhereInput>;
  notificationPreference?: InputMaybe<NotificationsPreference>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  orgUser?: InputMaybe<OrgUserWhereInput>;
  passWordLastUpdated?: InputMaybe<Scalars['DateTime']['input']>;
  passwordResetToken?: InputMaybe<Scalars['String']['input']>;
  passwordResetTokenExpiresAt?: InputMaybe<Scalars['String']['input']>;
  referral?: InputMaybe<ReferralWhereInput>;
  some?: InputMaybe<UserWhereInput>;
  superAdmin?: InputMaybe<SuperAdminUserWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgentWhereTermsAccepted?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<UserType>;
};

export type UserWhereInputForGetInvoicesForCustomer = {
  clientUser?: InputMaybe<ClientUserForSearchFilterInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type VendorFeeStructureInput = {
  achAppFeePercentage?: InputMaybe<Scalars['Decimal']['input']>;
  cardAppFeePercentage?: InputMaybe<Scalars['Decimal']['input']>;
  cardConvenienceFeePercentage?: InputMaybe<Scalars['Decimal']['input']>;
};

export type VendorInputForGetContractsForCustomer = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
};

export type VendorInputForGetContractsForVendor = {
  name_contains?: InputMaybe<Scalars['String']['input']>;
};

export type VerificationDocument = {
  __typename?: 'VerificationDocument';
  back?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  details_code?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type VerificationDocumentInput = {
  back?: InputMaybe<Scalars['String']['input']>;
  front?: InputMaybe<Scalars['String']['input']>;
};

export type VerificationToken = {
  __typename?: 'VerificationToken';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user: User;
  verificationToken: Scalars['String']['output'];
};

export type VerificationTokenWhereInput = {
  AND?: InputMaybe<Array<VerificationTokenWhereInput>>;
  NOT?: InputMaybe<Array<VerificationTokenWhereInput>>;
  OR?: InputMaybe<Array<VerificationTokenWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<UserWhereInput>;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};

export type VerificationTokenWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};

export type ContactForPayQuickPayment = {
  id: Scalars['ID']['input'];
};

export type QuickPaymentForPayQuickPaymentInput = {
  amount: Scalars['Int']['input'];
  contact: ContactForPayQuickPayment;
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};
