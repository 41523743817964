import { useEffect, useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { showError } from '../blocks/Toast';
import {
	useNewEventsSubscription_GetAuthedUserQuery,
	useNewEventsSubscription_UpdateSelfMutation,
	useNewEventsSubscription_GetEventsWhereQuery,
	useNewEventsSubscription_EventCreatedSubscription,
} from './__generated__/useNewEventsSubscription';
import { FlattenedUser } from '../types/user';
import { UserType } from '../types/generated';
import { useSelector } from 'react-redux';
import rpcShared from '@rockpapercoin/rpc-shared';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newEventsSubscription_GetAuthedUser = gql`
	query newEventsSubscription_GetAuthedUser($where: UserWhereUniqueInput!) {
		getAuthedUser(where: $where) {
			id
			userType
			eventsLastViewedAt
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newEventsSubscription_UpdateSelf = gql`
	mutation newEventsSubscription_UpdateSelf($data: UserUpdateSelfInput!) {
		updateSelf(data: $data) {
			id
			eventsLastViewedAt
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newEventsSubscription_GetEventsWhere = gql`
	query newEventsSubscription_GetEventsWhere($where: EventWhereInput!) {
		getEventsWhere(where: $where) {
			id
			category
			createdAt
			consumingUsers {
				id
			}
			dismissingUsers {
				id
			}
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newEventsSubscription_EventCreated = gql`
	subscription newEventsSubscription_EventCreated {
		eventCreated {
			id
		}
	}
`;

export const useNewEventsSubscription = () => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const [ badgeContent, setBadgeContent ] = useState<number>();

	const [ updateSelf ] = useNewEventsSubscription_UpdateSelfMutation();

	const reduxUser = user?.isLoggedIn ? user : undefined;

	const authedUserQuery = useNewEventsSubscription_GetAuthedUserQuery( {
		variables: { where: { id: reduxUser?.user.id } },
		skip: !reduxUser,
	} );

	useEffect( () => {
		if ( authedUserQuery.error ) {
			showError( authedUserQuery.error );
		}
	}, [ authedUserQuery ] );

	const authenticatedUser = authedUserQuery.data?.getAuthedUser;
	const skip = !authenticatedUser;

	const {
		data: query,
		error: queryError,
		refetch,
	} = useNewEventsSubscription_GetEventsWhereQuery( {
		variables: {
			where: {
				createdAt: { gt: authenticatedUser?.eventsLastViewedAt || '' },
				consumingUsers: { some: { id: authenticatedUser?.id } },
				dismissingUsers: { every: { NOT: [ { id: authenticatedUser?.id } ] } },
				NOT: [
					{
						category: {
							in: rpcShared.events.feed.getEventFeedEventsNotHandled(
								( authenticatedUser?.userType || 'GuestUser' ) as UserType
							),
						},
					},
				],
			},
		},
		skip,
	} );

	useEffect( () => {
		const queryValue = query?.getEventsWhere?.length;
		if ( typeof queryValue === 'number' ) {
			setBadgeContent( queryValue );
		}
	}, [ query ] );

	const { data: subscription, error: subscriptionError } =
		useNewEventsSubscription_EventCreatedSubscription( {
			skip,
		} );

	useEffect( () => {
		const updateAfterEventCreation = async () => {
			await refetch();
			const subscriptionValue = query?.getEventsWhere?.filter(
				( event ) =>
					authenticatedUser &&
					dayjs( event.createdAt ).isAfter(
						dayjs( authenticatedUser.eventsLastViewedAt )
					)
			).length;
			if ( typeof subscriptionValue === 'number' ) {
				setBadgeContent( subscriptionValue );
			}
		};
		updateAfterEventCreation();
	}, [
		authenticatedUser,
		query?.getEventsWhere,
		refetch,
		subscription
	] );

	useEffect( () => {
		if ( queryError ) {
			showError( queryError );
		}
	}, [ queryError ] );

	useEffect( () => {
		if ( subscriptionError ) {
			showError( subscriptionError );
		}
	}, [ subscriptionError ] );

	const reset = useCallback( async () => {
		const response = await updateSelf( {
			variables: {
				data: { eventsLastViewedAt: new Date() },
			},
		} );
		if ( response?.errors ) {
			response.errors.forEach( ( error ) => showError( error ) );
		} else if ( response?.data ) {
			setBadgeContent( 0 );
		}
	}, [ updateSelf ] );

	return {
		badgeContent,
		reset,
	};
};
