import React from 'react';
import Link from 'next/link';
import styles from './SideNavLinkItem.module.scss';
import SideNavButtonItem from '../SideNavButtonItem/SideNavButtonItem';
import { CustomMenuLinkItemType } from '../../CustomMenuItem/CustomMenuItemComponent';
import { useNewLeadsSubscription } from '../../../hooks/useNewLeadsSubscription';
import { MenuItemsIds } from '../../../config/useGetNavigationItems';

export default function SideNavLinkItem( {
	menuItem,
}: {
	menuItem: CustomMenuLinkItemType;
} ) {
	const { badgeContent } = useNewLeadsSubscription(
		menuItem.id !== MenuItemsIds.Leads
	);

	return (
		<Link href={ menuItem.href } className={ styles.linkWrap } passHref>
			<SideNavButtonItem menuItem={ menuItem } badgeContent={ badgeContent } />
		</Link>
	);
}
