import * as nookies from 'nookies';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
dayjs.extend( utc );
dayjs.extend( tz );
dayjs.extend( dayOfYear );
import currency from 'currency.js';
import Link from 'next/link';
import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import * as mathFunctions from './math';
import rpcShared from '@rockpapercoin/rpc-shared';
const { regex: Regex } = rpcShared;
import Globals from '../Globals';
import A from '../../elements/Anchor';
import { cloneDeep } from 'lodash';
import API from '../API';
import axios from 'axios';
import React from 'react';
import { getDetailLink } from './link';
import errorReporting from '../ErrorReporting';
export * from './link'; // A neet-o ES8 way of exporting an import directly!
import store from '../../redux/store';
import { logout } from '../../redux/actions';
import { isDevelopment } from '../constants';
import { DocumentOwnerType, SubscriptionStatus } from '../../types/generated';
import { getCookie } from './getCookie';
export * from './getCookie';
export * from './setCookie';
export * from './bundle';
export const math = mathFunctions;

/**
 * Takes sort object and parses it into nested
 * sort query if key is in dot notation
 * Examples:
 * constructSortQuery ( { orgUser: 'desc' } ) => [ { orgUser: 'desc' } ]
 * constructSortQuery ( { 'orgUsers.clientUsers': 'desc' } ) => [ { orgUser: { clientUsers: 'desc' } } ]
 * constructSortQuery ( { 'orgUsers.clientUsers._count': 10 } ) => [ { orgUser: { clientUsers: {  _count: 10 } } } ]
 * constructSortQuery ( [ { isPastDue: 'desc' }, { title: 'desc' } ] ) => [ { isPastDue: 'desc' }, { title: 'desc' } ]
 * @param  { Object|Array } query Key and value of sort query
 * @return { Array } non nest or nest object if sort query
 */
export const constructSortQuery = function( query ) {
	if ( !query ) return undefined;

	const fixDotField = ( value, keys ) => {
		let index = 0;
		return keys.reverse().reduce( ( res, itt ) => {
			let obj = {};
			if ( index === 0 ) {
				obj = { [ itt ]: value };
				index++;
				return obj;
			}

			obj = { [ itt ]: res };
			index++;
			return obj;
		}, {} );
	};

	const objectReducer = ( acc, [ key, val ] ) => {
		if ( key.indexOf( '.' ) > 0 ) {
			return {
				...acc,
				...fixDotField( val, key.split( '.' ) ),
			};
		}
		return {
			...acc,
			[ key ]: val,
		};
	};

	const arrayReducer = ( acc, obj ) => [
		...acc,
		...( Array.isArray( obj )
			? obj.arrayReducer( arrayReducer, acc )
			: [ Object.entries( obj ).reduce( objectReducer, {} ) ] ),
	];

	const result = Array.isArray( query )
		? query.reduce( arrayReducer, [] )
		: [ Object.entries( query ).reduce( objectReducer, {} ) ];

	return result;
};

/**
 * returns the end of the affair
 *
 * @param { string } creationDate ISO string
 * @returns a dayjs object
 */
export const getEndOfTrial = ( creationDate ) => {
	return dayjs( creationDate ).dayOfYear(
		dayjs( creationDate ).dayOfYear() + Globals.trialPeriodDays
	);
};

/**
 * Determines whether org is within trial period
 *
 * @param { string } creationDate ISO string
 * @returns { boolean }
 */
export const orgIsWithinTrialPeriod = ( creationDate ) => {
	const endOfTrial = getEndOfTrial( creationDate );
	return dayjs().isBefore( endOfTrial );
};

export const hasIncompleteOrPastDueSubscription = ( user ) => {
	if ( !user || !user.organization || !user.organization.subscription ) {
		return false;
	}
	const subscription = user.organization.subscription;
	const statuses = [ SubscriptionStatus.PastDue, SubscriptionStatus.Incomplete ];
	return subscription.status && statuses.includes( subscription.status );
};

/**
 * Check whether or not org user has active subscription or
 * is in trial period
 * @param  { Object }  user User object
 * @return { Boolean } Boolean if user has subscription
 */
export const hasActiveSubscription = function( user, ignoreTrial = false ) {
	if ( !user || !user.organization || !user.organization.createdAt ) {
		return false;
	}
	const withInTrial = orgIsWithinTrialPeriod( user.organization.createdAt );
	const subscription = user.organization.subscription;
	const validSubStates = [
		SubscriptionStatus.Trialing,
		SubscriptionStatus.Active,
		SubscriptionStatus.PastDue,
	];

	if ( withInTrial && !ignoreTrial ) {
		return true;
	}
	if ( subscription?.status && validSubStates.includes( subscription.status ) ) {
		return true;
	}
	return false;
};

/**
 * Allows for an element to be dragged for a portion of its height until it reaches
 * a limit then runs a callback method. Limit is 1/3 or 1/4 of the elements height
 * depending on its orientation
 * @param  { Object }   el 				HTML element to be dragged - defaults to document.body
 * @param  { Function } callback 	callback method after drag limit is reached
 * @return {[type]}            [description]
 */
export const verticalDrag = async function( el = document.body, callback ) {
	const bounds = el.getBoundingClientRect();
	let limit = bounds.height / 3;
	let start = 0;
	let end = 0;

	el.addEventListener( 'touchstart', ( e ) => ( start = e.touches[ 0 ].clientY ) );

	el.addEventListener( 'touchmove', ( e ) => {
		end = e.touches[ 0 ].clientY;
		if ( end - start > 0 ) el.style.transform = `translateY(${ end - start }px)`;
		if ( end - start < 0 ) el.style.transform = 'translateY(0px)';
	} );

	el.addEventListener( 'touchend', () => {
		let orientation = null;
		if ( window.screen && window.screen.orientation ) {
			orientation = window.screen.orientation;
		}

		const diff = end - start;
		if (
			orientation &&
			( orientation.type === 'landscape-primary' ||
				orientation.type === 'landscape-secondary' )
		) {
			limit = bounds.height / 4;
		}

		if ( diff < limit ) {
			el.style.transform = 'translateY(0px)';
		}

		if ( diff >= limit ) {
			callback( start, end );
			setTimeout( () => ( el.style.transform = 'translateY(0px)' ), 500 );
		}
	} );
};

/**
 * [description]
 * @param  { Object }		clientUser - user object of type client
 * @param  { Object }  	file - event listner return type of files[0]
 * @param  { String }  	fileSource - LocalStorage or GoogleDrive
 * @param  { String }  	fileSourceID - the ID of the document
 * @param  { Boolean } 	sharedWithCustomer - is document shared with client
 * @param  { Array }		sharedWithContacts - array of org users document is shared with
 * @param  { Function } errorsCallback - method error handler
 * @param  { String }  	parentFolder - the ID of the parent folder
 * @return { Object } 	newly created document
 */
export const createDocument = async ( {
	clientUser = null,
	file = null,
	fileSource = 'LocalStorage',
	fileSourceID = null,
	sharedWithCustomer = false,
	sharedWithContacts = [],
	errorsCallback,
	parentFolder = undefined,
} ) => {
	if ( !file ) return null;

	const args = {
		file,
		fileSourceID,
		fileSource,
		sharedWithCustomer,
		sharedWithContacts,
		type: 'Internal',
	};

	if ( clientUser ) {
		args.type = 'Sharable';
		args.clientUser = clientUser;
	}

	if ( parentFolder ) {
		args.parentFolder = { connect: { id: parentFolder } };
	}

	const { data, errors, recordUploadErrors } = await API.createDocument( args );

	if ( recordUploadErrors ) {
		recordUploadErrors.map( ( error ) => {
			if ( error.message && errorsCallback ) {
				errorsCallback( error.message );
			}
		} );
	}

	if ( errors ) {
		errors.map( ( error ) => {
			if ( error.message && errorsCallback ) {
				errorsCallback( error.message );
			}
		} );
	}
	if ( data && data.recordDocumentUploaded ) {
		return data.recordDocumentUploaded;
	}
	return false;
};

/** @typedef {{ user?: { userType?: 'ClientUser'|'OrgUser'|'GuestUser'|'SuperAdmin' }, firstName?: string, lastName?: string }} POTENTIAL_ORG_USER */
/**
 * Checks if a provided user object is an org user.
 *
 * @param { POTENTIAL_ORG_USER | USER_FLATTENED_POSSIBILITIES } potentialOrgUser
 */
export const isOrgUser = ( potentialOrgUser ) => {
	if ( potentialOrgUser.user && potentialOrgUser.user.userType ) {
		return potentialOrgUser.user.userType === 'OrgUser';
	} else {
		return (
			( Object.prototype.hasOwnProperty.call( potentialOrgUser, 'firstName' ) ||
				Object.prototype.hasOwnProperty.call( potentialOrgUser, 'lastName' ) ) &&
			!Object.prototype.hasOwnProperty.call( potentialOrgUser, 'customer' ) // rule out contacts
		);
	}
};

/** @typedef {{ user?: { userType?: 'ClientUser'|'OrgUser'|'GuestUser'|'SuperAdmin' }, firstNameOne?: string | null, lastNameOne?: string | null, firstNameTwo?: string | null, lastNameTwo?: string | null }} POTENTIAL_CLIENT_USER */
/**
 * Checks if a provided user object is a client user.
 *
 * @param {POTENTIAL_CLIENT_USER} potentialClient - User object
 *
 * @returns {Boolean}
 */
export const isClient = ( potentialClient ) => {
	if ( potentialClient.user && potentialClient.user.userType ) {
		return potentialClient.user.userType === 'ClientUser';
	} else
		return (
			Object.prototype.hasOwnProperty.call( potentialClient, 'firstNameOne' ) ||
			Object.prototype.hasOwnProperty.call( potentialClient, 'firstNameTwo' ) ||
			Object.prototype.hasOwnProperty.call( potentialClient, 'lastNameOne' ) ||
			Object.prototype.hasOwnProperty.call( potentialClient, 'lastNameTwo' )
		);
};

/** @typedef {{ orgType?: string }} POTENTIAL_ORGANIZTION */
/** @typedef {{ paymentInstallmentPlan?: string }} POTENTIAL_INVOICE */
/** @typedef {{ vendorWillSign?: string }} POTENTIAL_INVOICE */
/** @typedef {{ fileSource?: string }} POTENTIAL_DOCUMENT */
/** @typedef {POTENTIAL_ORG_USER|POTENTIAL_CLIENT_USER|POTENTIAL_ORGANIZTION|POTENTIAL_INVOICE|POTENTIAL_DOCUMENT} POTENTIAL_OBJECT */
/**
 * Returns the type of the API object. For use in url construction and such.
 *
 * @param {POTENTIAL_OBJECT} obj - The object to check.
 * @returns {'user' | 'org' | 'contract' | 'invoice' | 'document' | 'proposal' | null | undefined}
 */
export const getObjectType = ( obj ) => {
	if ( !obj ) {
		return null;
	}
	if (
		Object.prototype.hasOwnProperty.call( obj, 'user' ) ||
		isClient( obj ) ||
		isOrgUser( obj )
	) {
		return 'user';
	} else if ( Object.prototype.hasOwnProperty.call( obj, 'services' ) ) {
		return 'org';
	} else if ( Object.prototype.hasOwnProperty.call( obj, 'gratuityAmount' ) ) {
		return 'invoice';
	} else if ( Object.prototype.hasOwnProperty.call( obj, 'signatures' ) ) {
		return 'contract';
	} else if ( Object.prototype.hasOwnProperty.call( obj, 'fileSource' ) ) {
		return 'document';
	} else if ( Object.prototype.hasOwnProperty.call( obj, 'proposalTemplate' ) ) {
		return 'proposal';
	} else if ( Object.prototype.hasOwnProperty.call( obj, 'parentFolder' ) ) {
		return 'folder';
	}
};

/**
 * Get username(s) of a user.
 *
 * @param {Record<string,any> | null} [userFromArgs] - User object.
 * @param {boolean} full - optional, returns full names.
 * @param {boolean} separate - optional, returns an array of names instead of concat.
 *
 * @returns {*} usernames, depending on optional parameter "separate", as a string or array.
 */
export const getUsername = ( userFromArgs, full = true, separate = false ) => {
	if ( !userFromArgs ) {
		return '';
	}
	const user =
		userFromArgs?.clientUser || userFromArgs?.orgUser
			? {
				...( userFromArgs.clientUser || userFromArgs.orgUser ),
				user: userFromArgs,
			  }
			: userFromArgs;
	if ( getObjectType( user ) !== 'user' ) {
		// eslint-disable-next-line
		console.error('GetUsername failure. `user` not a user object.');
		return '';
	}
	if ( isClient( user ) ) {
		let name = user.firstNameOne;
		if ( full && user.lastNameOne ) name += ' ' + user.lastNameOne;
		if ( user.firstNameTwo ) {
			name += ' & ' + user.firstNameTwo;
			if ( full && user.lastNameTwo ) name += ' ' + user.lastNameTwo;
		}
		if ( separate ) {
			name = [];
			if ( user.firstNameOne )
				name.push( `${ user.firstNameOne } ${ user.lastNameOne }` );
			if ( user.firstNameTwo )
				name.push( `${ user.firstNameTwo } ${ user.lastNameTwo }` );
		}
		return name;
	} else {
		return `${ user.firstName }${
			full ? ( user.lastName ? ' ' + user.lastName : '' ) : ''
		}`;
	}
};

/**
 * Compiles user data and sends it to Intercom
 * @param  { Object } data user props
 */
export const intercomData = ( data, settings = window.intercomSettings ) => {
	if ( !window ) return {}; // only run client-side
	if ( !window.Intercom || !window.INTERCOM_APP_ID ) return {};
	if ( !settings || ( settings && !settings.setData ) ) return {};
	if ( !data || ( data && !data.id ) ) return {};

	const { image, organization, phone, promo, user, userType } = data;

	const userObj = {
		user_id: user.id,
		name: getUsername( data ),
		email: user.email,
		userType: userType || user.userType,
		phone: phone,
		avatar: {
			type: 'avatar',
			image_url: image,
		},
	};

	if ( promo ) userObj.promo = promo;

	if ( organization ) {
		const company = {
			company_id: organization.id,
			name: organization.name,
			phone: organization.phone,
			website: organization.website,
			address:
				organization.addressLine1 +
				' ' +
				organization.addressLine2 +
				', ' +
				organization.city +
				', ' +
				organization.state +
				' ' +
				organization.postalCode,
			services: organization.services.map( ( service ) => service.name ).join( ',' ),
			orgType: organization.orgType,
		};

		if ( !userObj.phone ) userObj.phone = organization.phone;

		const { addressLine1, addressLine2, city, state, postalCode } =
			organization;
		if ( addressLine1 ) company.address = addressLine1;
		if ( addressLine2 ) company.address += ' ' + addressLine2;
		if ( city ) company.address += ', ' + city;
		if ( state ) company.address += ', ' + state;
		if ( postalCode ) company.address += ' ' + postalCode;

		userObj.company = company;
	}

	if ( window.Intercom && window.INTERCOM_APP_ID ) {
		window.Intercom( 'update', {
			app_id: window.INTERCOM_APP_ID,
			...userObj,
		} );
	}
};

/**
 * Tests user agent for mobile device reported by browser
 * @return { Boolean } Boolean based on device query
 */
export const isMobileDevice = function() {
	if ( !window ) {
		// only run client-side
		return false;
	}
	return {
		Android: function() {
			return window.navigator.userAgent.match( /Android/i );
		},
		BlackBerry: function() {
			return window.navigator.userAgent.match( /BlackBerry/i );
		},
		iOS: function() {
			return window.navigator.userAgent.match( /iPhone|iPad|iPod/i );
		},
		Opera: function() {
			return window.navigator.userAgent.match( /Opera Mini/i );
		},
		Windows: function() {
			return (
				window.navigator.userAgent.match( /IEMobile/i ) ||
				window.navigator.userAgent.match( /WPDesktop/i )
			);
		},
		any: function() {
			return (
				this.Android() ||
				this.BlackBerry() ||
				this.iOS() ||
				this.Opera() ||
				this.Windows()
			);
		},
	};
};

/**
 * Get cookie string, depending on the current environment
 *
 * @param {import('next').NextPageContext.req} [req] - NextJS request object. Will only be available during SSR.
 *
 * @returns {string | undefined} cookieString - Full cookie string.
 */
export const getCookieString = ( req ) => {
	if ( req ) {
		return req.headers.cookie;
	}
	return typeof document !== 'undefined' && document?.cookie;
};

/**
 * Destroys a cookie.
 *
 * @param {String} cookieName - The name of the cookie to unset
 * @param {Object} [ctx] - NextJS context object. Required to set cookies in a server-side environment.
 *
 * @returns {void}
 */
export const unsetCookie = ( cookieName, ctx ) => {
	if ( typeof window !== 'undefined' ) {
		document.cookie = `${ cookieName }=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
	} else if ( ctx ) {
		nookies.destroyCookie( ctx, cookieName );
	}
};

/** @typedef {{ id: string, userType: 'ClientUser'|'OrgUser'|'GuestUser'|'SuperAdmin', isAdmin: boolean, isSuperAdmin: boolean, isOwner: boolean, isLoggedIn: true, groups?: string[], user: { id: string, userType: 'ClientUser'|'OrgUser'|'GuestUser'|'SuperAdmin' } }} USER_FLATTENED */
/**
 * takes some values from the inner user and spreads them
 *
 * @param { { user: import('./apollo').RecursivePartial<import('../../types/generated').AuthedUser> } | undefined } user
 * @param { import('./apollo').RecursivePartial<import('../../types/generated').ReducedUserGroup>[] | undefined } groups
 * @returns {import('../../types/user').FlattenedUser}
 */
export const flattenUser = ( user, groups ) => {
	user.userType = user.user?.userType;

	user.isAdmin = user.user?.userType === 'OrgUser' && user.isAdmin === true;

	user.isSuperAdmin = user.user?.userType === 'SuperAdmin';
	user.isOwner = user.user?.userType === 'OrgUser' && user.isOwner;
	user.isLoggedIn = true;
	if ( groups ) {
		user.groups = groups || [];
	}
	return user;
};

/*
 * Change url to file for edited image from base64
 *
 * @param {string} url - Url of the file.
 * @param {string} filename - A file name
 * @param {string} mimeType - A file mimeType
 *
 * @returns {File} File
 */
export const dataURIToFile = ( url, filename, mimeType ) => {
	return fetch( url )
		.then( function( res ) {
			return res.arrayBuffer();
		} )
		.then( function( buf ) {
			return new File( [ buf ], filename, { type: mimeType } );
		} );
};

/**
 * Returns the file extension from a url which meets the following format:
 *
 *
 * @param { String } - {someUrl}.{fileExtension}
 *
 * @returns { String } - file extension (without the ".")
 */
export const getFileExtensionFromUrl = function( fileUrl ) {
	if ( !fileUrl ) {
		return '';
	}
	if ( fileUrl.indexOf( 'response-content-disposition' ) > 0 ) {
		/* spell-checker: disable */
		/* pattern looks like this:	(response-content-disposition in URL)
		   https://rpc-sub-app-private-dev.s3.us-west-2.amazonaws.com
			 /WADE/vendors/ckfsg21s86yav0d90rhotntaf/internal/cl0ie24zk2499ekfzd64rxts4
			 ?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD
			 &X-Amz-Credential=AKIASF3THCSOFWKX2EPK%2F20220308%2Fus-west-2%2Fs3%2Faws4_request
			 &X-Amz-Date=20220308T171914Z&X-Amz-Expires=60&X-Amz-Signature=cfe55a73ca02d142b79
			 33ccd6ffb910a208efcfb96a1de19523cd4ba580994fa&X-Amz-SignedHeaders=host
			 &response-content-disposition=attachment%3B%20filename%3Dtestpdf.pdf&x-id=GetObject */
		/* spell-checker: enable */
		const fileUrlPotentialQuery = fileUrl.split(
			'response-content-disposition'
		)[ 1 ];
		const fileUrlNoQuery = fileUrlPotentialQuery.indexOf( '&' )
			? fileUrlPotentialQuery.split( '&' )[ 0 ]
			: fileUrlPotentialQuery;
		/* fileUrlNoQuery now equals attachment%3B%20filename%3Dtestpdf.pdf */
		const lastIndexOfPeriod = fileUrlNoQuery.lastIndexOf( '.' );
		return fileUrlNoQuery.slice( lastIndexOfPeriod + 1 ).toLowerCase();
	} else {
		/* spell-checker: disable */
		/* pattern looks like this: (no response-content-disposition in URL)
		   https://rpc-sub-app-private-dev.s3.us-west-2.amazonaws.com/WADE/vendors/
			 ckfsg21s86yav0d90rhotntaf/clients/cl0idd0he0384wwfz22kss94c/cl0idf5e08192wwfzndp7pxkh.pdf
			 ?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD
			 &X-Amz-Credential=AKIASF3THCSOFWKX2EPK%2F20220308%2Fus-west-2%2Fs3%2Faws4_request
			 &X-Amz-Date=20220308T172646Z&X-Amz-Expires=60&X-Amz-Signature=67ff868dbf2cd71b3aafc16c6b
			 3dd2d8ba520acb4e34ac24076c9febbb8b9edb&X-Amz-SignedHeaders=host&x-id=GetObject */
		/* spell-checker: enable */
		const fileUrlNoQuery =
			fileUrl.indexOf( '?' ) > 0 ? fileUrl.split( '?' )[ 0 ] : fileUrl;
		const lastIndexOfPeriod = fileUrlNoQuery.lastIndexOf( '.' );
		return fileUrlNoQuery.slice( lastIndexOfPeriod + 1 ).toLowerCase();
	}
};

/**
 * Returns the file extension from a url which meets the following format:
 *
 *
 * @param { String } [fileUrl]
 * @todo replace getFileExtensionFromUrl with this method at some point
 */
export const getFileExtensionFromUrlSimplified = function( fileUrl ) {
	if ( !fileUrl ) {
		return '';
	}
	let rest = fileUrl;
	const fileUrlParts = [];
	/* If response-content-disposition exists, then the filename (and extension) are specified thereafter, so lets
	check it first.  */
	if ( rest.indexOf( 'response-content-disposition' ) > -1 ) {
		const split = fileUrl.split( 'response-content-disposition' );
		fileUrlParts.push( split[ 1 ] );
		rest = split[ 0 ];
	}
	/* The type suffix is not always after the response-content-disposition (when it exists), so make sure to check
	the rest of the string. */
	if ( rest.indexOf( '?' ) > -1 ) {
		const split = rest.split( '?' );
		fileUrlParts.push( split[ 0 ] );
		fileUrlParts.push( split[ 1 ] );
	}
	/* The filename can appear before and after a questionmark, so check both places and return on the first
	occurrence we find. */
	for ( const fileUrlPart of fileUrlParts ) {
		const filenameNoSlashes = fileUrlPart.split( '/' ).slice( -1 ).join( '' );
		const lastIndexOfPeriod = filenameNoSlashes.lastIndexOf( '.' );
		if ( lastIndexOfPeriod > 0 ) {
			const possibleFilenameType = filenameNoSlashes
				.slice( lastIndexOfPeriod + 1 )
				.toLowerCase();
			const filenameType = possibleFilenameType.replace( /[^\w].*/, '' );
			return filenameType;
		}
	}
};

/**
 * Gets the mime type of a file based on the file name.
 *
 * @param {String} fileName - A file name.
 *
 * @returns {String} mime type
 */
export const fileNameToMimeType = function( fileName = '' ) {
	const extension = getFileExtensionFromUrl( fileName );

	switch ( extension ) {
		case 'stream':
			return 'application/octet-stream';
		case 'jpeg':
		case 'jpg':
			return 'image/jpeg';
		case 'png':
			return 'image/png';
		case 'gif':
			return 'image/gif';
		case 'pdf':
			return 'application/pdf';
		case 'xls':
			return 'application/vnd.ms-excel';
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
	}
};

/**
 * Gets a file's type. Either by reading the mime type, or falling back to the file extension.
 *
 * @param {File} file
 *
 * @returns {String} mime type- Ex: 'image/jpg'
 */
export const getFileType = ( { type, name } ) => {
	return type || fileNameToMimeType( name );
};

/** @typedef { { id: string, email: string, userID: string, userType: 'ClientUser', isAdmin: false, isSuperAdmin: false, isOwner: false, isLoggedIn: true, groups: [], impersonatingUser: null } } AuthUser_ClientUser */
/** @typedef { { id: string, email: string, userID: string, userType: 'OrgUser', isAdmin: boolean, isSuperAdmin: false, isOwner: boolean, isLoggedIn: true, groups: [], impersonatingUser: null } } AuthUser_OrgUser */

/**
 * Get the currently logged in user and auth token from the cookies.
 *
 * @param {string} cookieString - Full cookie string.
 *
 * @returns { [ string, AuthUser_ClientUser | AuthUser_OrgUser ] } auth - [authToken, userObject].
 */
export const getInfoFromCookie = ( cookieString ) => {
	const decodedCookie = decodeURIComponent( cookieString );
	const token = getCookie( 'Authorization=', decodedCookie );
	let user = getCookie( 'CurrentUser=', decodedCookie );
	try {
		if ( user ) user = JSON.parse( user );
		return [ token, user ];
	} catch ( error ) {
		// If we get data in the CurrentUser cookie that is not JSON, it'll get caught and logged
		errorReporting.captureErrorInSentry( error, undefined, {
			cookieString,
			decodedCookie,
		} );
		/* only pass true to do redirect if we're client-side because we don't have the request
		headers here, and no window otherwise */
		const doRedirect = typeof window !== 'undefined';
		store.dispatch( logout( doRedirect ) );
		return [];
	}
};

/**
 * Constructs URL query parameters from a dictionary object.
 *
 * @param {Object} params - A dictionary of url parameters to stringify.
 *
 * @returns {String}
 */
export const constructURLParams = ( params ) => {
	if ( isEmpty( params ) ) {
		return '';
	}
	let paramString = '?';
	const queries = [];
	if ( params && Object.entries( params ).length ) {
		for ( const key in params ) {
			queries.push( `${ key }=${ encodeURI( params[ key ] ) }` );
		}

		// paramString += !paramString.includes( '?' ) ? '?' : '&';

		paramString += `${ queries.join( '&' ) }`;
	}

	return paramString;
};

/**
 * Client redirection method for use in "getInitialProps"
 *
 * @param { Object | null | ServerResponse } res - NextJS response object. Will only be available during SSR.
 * @param { Object | String } href - A URL object or a path string
 */
export const Redirect = ( res, href ) => {
	if ( res ) {
		// redirect server-side, only if we aren't already redirecing
		if ( !res.headersSent ) {
			const paramString = constructURLParams( href?.query );
			const redirectLocation =
				typeof href === 'string' ? href : href.as || href.pathname; // pathname will have to be a legitimate path if there is no "as"

			res.writeHead( 302, {
				Location: redirectLocation + paramString,
			} );
			res.end();
		}
	} else {
		Router.push( href, href.as, { shallow: false } );
	}
};

/**
 * Routes the user to a new page after a delay.
 *
 * @param { Object | String } href - A path name string or a URL object
 * @param {Int} [delay] - Milliseconds to wait before rerouting the user.
 *
 */
export const routeWithDelay = ( href, delay = 1000 ) => {
	setTimeout( () => {
		Router.push( href );
	}, delay );
};

/**
 * Generate link to document based on viewer
 * @param  { Object } viewer - user object
 * @param  { Object } doc    - document object
 * @return { Object } returns object for Next Link consumption
 */
export const getDocumentLink = ( viewer, doc ) => {
	let linkProps = {
		href: '',
		as: '',
	};

	if ( !viewer || !doc ) {
		return linkProps;
	}

	if ( isOrgUser( viewer ) ) {
		linkProps = {
			href: '/customerFolders/[client]/document/[document]',
			as: '/customerFolders/' + doc.clientUser.id + '/document/' + doc.id,
		};
	}

	if ( isClient( viewer ) ) {
		linkProps = {
			href: '/myFolder/document/[document]',
			as: '/myFolder/document/' + doc.id,
		};
	}

	return linkProps;
};

/**
 * Create a simple link with a NextJS Link tag.
 *
 * @param {{
 *   link?: import('./link').LinkPropsType | undefined,
 *   text: React.ReactNode | string,
 *   className?: string,
 *   idName?: string
 * }} args
 *
 */
export const toSimpleLink = ( { link: linkProps, text, className, idName } ) => {
	const mailToLinkRegex = /^mailto:/;
	const optionalProps = {};
	if ( className ) optionalProps.className = [ 'legacyLink', className ].join( ' ' );
	if ( idName ) optionalProps.id = idName;
	if ( linkProps?.href && mailToLinkRegex.test( linkProps.href ) ) {
		return (
			<A href={ linkProps.href } target='_blank' { ...optionalProps }>
				{ text }
			</A>
		);
	}

	return (
		<Link className='legacyLink' { ...linkProps } { ...optionalProps }>
			{ text }
		</Link>
	);
};

/**
 * Returns a document name with the extension sliced off.
 *
 * @param { object } document
 * @param { string } document.name
 * @returns { string }
 */
export const getDocumentName = ( document ) => {
	if ( !document ) return '';
	if ( document.name.lastIndexOf( '.' ) !== -1 ) {
		return document.name.slice( 0, document.name.lastIndexOf( '.' ) );
	}
	return document.name;
};

/**
 * Create a link for a given object which has a detail page. Requires the object to have a readable identifier.
 *
 * @param {Object} object - An invoice, contract, organization, or user (client | orgUser) object;
 * @param {Object} [params] - Optional Url parameters to add to the link
 *
 */
export const linkObject = ( object, params ) => {
	let linkText = '';
	switch ( getObjectType( object ) ) {
		case 'user':
			linkText = getUsername( object );
			break;

		case 'org':
			if ( !Object.prototype.hasOwnProperty.call( object, 'name' ) ) {
				console.warn(
					'An organization is being linked with no human-readable identifier to display.' +
						`No \`name\` on org: ${ JSON.stringify( object ) }`
				);
			}
			linkText = object.name;
			break;

		case 'invoice':
			if ( !Object.prototype.hasOwnProperty.call( object, 'title' ) ) {
				console.warn(
					'An invoice is being linked with no human-readable identifier to display.' +
						`No \`title\` or invoice: ${ JSON.stringify( object ) }`
				);
			}
			linkText = object.title || 'invoice';
			break;

		case 'contract':
			if ( !Object.prototype.hasOwnProperty.call( object, 'title' ) ) {
				console.warn(
					'A contract is being linked with no human-readable identifier to display.' +
						`No \`title\` on contract: ${ JSON.stringify( object ) }`
				);
			}
			linkText = object.title || 'contract';
			break;

		case 'proposal':
			if ( !Object.prototype.hasOwnProperty.call( object, 'title' ) ) {
				console.warn(
					'A proposal is being linked with no human-readable identifier to display.' +
						`No \`title\` on proposal: ${ JSON.stringify( object ) }`
				);
			}
			linkText = object.title || 'proposal';
			break;

		case 'document':
			if ( !Object.prototype.hasOwnProperty.call( object, 'name' ) ) {
				console.warn(
					'A contract is being linked with no human-readable identifier to display.' +
						`No \`title\` on contract: ${ JSON.stringify( object ) }`
				);
			}
			linkText = getDocumentName( object ) || 'document';
			break;
		default:
			break;
	}

	return toSimpleLink( {
		link: getDetailLink( { object, params } ),
		text: linkText,
	} );
};

/** @typedef {{ status?: 'Pending'|'Inactive'|'Active'|'Cancelled'|'Completed'|'Budget'|'Unresponsive'|'Other'|'Spam'|'Duplicate'|'NotAvailable', firstName?: string | null, lastName?: string | null, customer: { email: string } }} POTENTIAL_CONTACT */
/**
 * Checks if a provided user object is a contact user.
 * Not a perfect test, but can differentiate from OrgUser by status.
 *
 * @param {POTENTIAL_CONTACT} potentialContact - User object
 *
 * @returns {boolean}
 */
export const isContact = ( potentialContact ) => {
	// we'll have to remember to update this status array here if it changes in the backend...
	return (
		[
			'Pending',
			'Inactive',
			'Active',
			'Cancelled',
			'Completed',
			'Budget',
			'Unresponsive',
			'Spam',
			'Duplicate',
			'NotAvailable',
			'Other',
		].includes( potentialContact.status ) &&
		( Object.prototype.hasOwnProperty.call( potentialContact, 'firstName' ) ||
			Object.prototype.hasOwnProperty.call( potentialContact, 'lastName' ) )
	);
};

/**
 * Checks if the provided user is an orgUser and admin
 *
 * @param { Record<string,any> } potentialOrgUser
 */
export const isOrgAdmin = ( potentialOrgUser ) => {
	if ( !isOrgUser( potentialOrgUser ) ) {
		return false;
	}
	if ( potentialOrgUser.isAdmin ) {
		return true;
	}
	return false;
};

/**
 * Checks if the user is a client's assigned planner or an admin of the client's planner's organization.
 *
 * @param { Object } planner - The planner to check the role of
 * @param { Object } clientUser - The client for which to check
 *
 * @returns { Boolean }
 */
export const isAssignedPlannerOrAdmin = ( planner, clientUser ) => {
	if ( !clientUser ) {
		return false;
	}

	if (
		planner.user?.userType === 'OrgUser' &&
		clientUser.assignedPlanner &&
		clientUser.assignedPlanner.id === planner.id
	) {
		return true;
	}

	if (
		planner.isAdmin &&
		clientUser.assignedPlanner &&
		planner.organization.id === clientUser.assignedPlanner.organization.id
	) {
		return true;
	}

	return false;
};

/**
 * Get the file name of a file uploaded to S3.
 *
 * @params {string} url - Url of the file.
 *
 * @returns { string | undefined } filename, including extension.
 */
export const getFilename = ( url ) => {
	if ( !( typeof url === 'string' ) ) return;

	let filename = url.substring( url.lastIndexOf( '/' ) + 1 );

	// also handle other file separators
	filename =
		url.lastIndexOf( '\\' ) > -1
			? url.substring( url.lastIndexOf( '\\' ) + 1 )
			: filename;

	const startOfUrlParameters = filename.indexOf( '?' );
	if ( startOfUrlParameters !== -1 ) {
		filename = filename.substring( 0, startOfUrlParameters );
	}

	return decodeURI( filename );
};

/** @typedef {POTENTIAL_ORG_USER|POTENTIAL_CLIENT_USER} CONTACT_USER */
/** @typedef {{ id: string}} CONTACT_ORGANIZATION */
/** @typedef {CONTACT_ORGANIZATION|CONTACT_USER} CONTACT_TARGET */
/**
 * Gets the organization id and client id relevant to either retrieve, update, or create a contact.
 *
 * @param {CONTACT_USER} currentUser - The user making a contact request
 * @param {POTENTIAL_OBJECT} target - Either a User or Organization
 *
 * @returns { String[] } [ clientID, orgID ]
 */
export const getContactIDs = ( currentUser, target ) => {
	let clientID, orgID;
	if ( isClient( target ) ) {
		clientID = target.id;
	} else if ( isClient( currentUser ) ) {
		clientID = currentUser.id;
	}

	if ( isOrgUser( target ) ) {
		orgID = target.organization.id;
	} else if ( isOrgUser( currentUser ) ) {
		orgID = currentUser.organization.id;
	} else if ( getObjectType( target ) === 'org' ) {
		orgID = target.id;
	}

	if ( !( clientID || orgID ) ) {
		console.error( 'Could not determine contact IDs' );
	}

	return [ clientID, orgID ];
};

/**
 * Get username of a contact
 *
 * @param {POTENTIAL_CONTACT} contact
 * @returns {string | undefined}
 */
export const getContactName = ( contact ) => {
	if ( isContact( contact ) ) {
		const name = [];
		if ( typeof contact.firstName === 'string' && contact.firstName.length > 0 ) {
			name.push( contact.firstName );
		}
		if ( typeof contact.lastName === 'string' && contact.lastName.length > 0 ) {
			name.push( contact.lastName );
		}
		// if there's no first or last name, use email address
		if ( name.length < 1 ) {
			return contact.customer.email;
		}
		// otherwise just return a name based of of first/last
		return name.join( ' ' );
	}
	return undefined;
};

/**
 * Gets the preferred name to display for a payment source.
 *
 * @param { Record<string, any> } source - The payment source to check.
 * @param {String} [source.nickname] - A possible name to return.
 * @param {String} [source.brand] - A possible name to return.
 * @param {String} [source.bank_name] - A possible name to return.
 * @param {String} source.object - Either "bank_account" or "card"
 *
 * @returns {String} name - nickname || brand || bank_name || object
 */
export const getPaymentMethodName = ( source ) => {
	if ( typeof source !== 'object' ) {
		console.error(
			`Cannot get payment method name for method: ${ JSON.stringify( source ) }`
		);
		return '';
	}

	const nickName = source?.metadata?.find(
		( datum ) => datum.key === 'nickname'
	)?.value;
	return (
		nickName ||
		source.brand ||
		source.bank_name ||
		( source.object === 'card' ? 'CARD' : 'ACH' )
	);
};

export const isValidDate = ( dateObject ) => {
	if ( !dateObject ) return false;

	if ( typeof dateObject === 'string' || typeof dateObject === 'number' )
		dateObject = new Date( dateObject );

	if ( dateObject.getTime() === new Date( 0 ).getTime() ) return false;

	return true;
};

/**
 * Converts a date to a readable standard format
 *
 * @param { String | Date | undefined | null } date - A valid date string or Date object.
 * @param { String } [noDateString] - The string to represent invalid or non-existent dates.
 *
 * @returns { String } A date of format "MM.DD.YYYY"
 */
export function getFormattedDate( date, noDateString = '' ) {
	if ( !isValidDate( date ) ) {
		return noDateString;
	}

	if ( date ) {
		return dayjs( date ).format( 'M.D.YYYY' );
	}

	return noDateString;
}

/**
 * Formats an ISO String date as such: "M.D.YYYY at h:mm A"
 *
 * @param { String | Date | undefined | null } date - A valid date string or Date object.
 * @param { String } [format] - The format to return the date in. See https://day.js.org/docs/en/display/format
 *
 * @returns { String } A date of format "M.D.YYYY at h:mm A"
 */
export function getFormattedDateWithTimestamp(
	date,
	format = 'M.D.YYYY [at] h:mm A'
) {
	// sometimes we get a format like '1671063485000'
	// sometimes we get a format like '2022-12-13T22:46:38.000Z'
	return dayjs( isNaN( date ) ? date : Number( date ) ).format( format );
}

/**
 * Converts a date string or Date object into an HTML Input-friendly string value.
 * Returns MM/DD/YYYY"
 * @param { { Date | string } } date - Date to be transformed
 */
export const toDateValue = ( date ) => {
	if ( !date ) return date;

	const dateObject = new Date( date );

	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

	return new Intl.DateTimeFormat( 'en-US', options ).format( dateObject );
};

/**
 * Converts an amount into a formatted currency, by default from cents.
 *
 * @param { String | Number } amount - An amount to convert to currency display
 * @param { import('currency.js').Options } [options] - Additional options to be passed into the currency call. Note that passing
 * in a value here overrides the default "fromCents: true" option, so make sure to add it to your options
 * object if you need it.
 *
 * See https://github.com/scurker/currency.js/#options
 *
 * @returns { String } A formatted currency string
 */
export function getFormattedCurrency( amount, options = { fromCents: true } ) {
	if ( amount !== undefined && amount !== null ) {
		return currency( amount, options ).format();
	}

	return '';
}

/**
 * Multiplies a dollar amount to cents.
 *
 * @param {String} input - Dollar amount(as string) to convert to a value in cents.
 *
 * @returns {Int} result - cent value.
 */
export const parseCentsFromDollars = ( input ) => {
	let dollarString = '0';
	if ( input ) {
		dollarString = input;
	}
	const parsedFloat = parseFloat( parseFloat( dollarString ).toFixed( 2 ) );

	const cents = math.multiply( parsedFloat, 100 );

	return cents;
};

export const convertInvoiceToDollars = ( invoice ) => {
	const {
		lineItems,
		discountAmount,
		amountOutstanding,
		gratuityAmount,
		paymentInstallments,
	} = invoice;

	return {
		...invoice,
		lineItems: lineItems.map( ( item ) => ( {
			...item,
			amount: getFormattedCurrency( item.amount ),
		} ) ),
		paymentInstallments: paymentInstallments.map( ( installment ) => ( {
			...installment,
			amountDue: getFormattedCurrency( installment.amountDue ),
			gratuityPaid: getFormattedCurrency( installment.gratuityPaid ),
			convenienceFee: getFormattedCurrency( installment.convenienceFee ),
			customAmount: getFormattedCurrency( installment.customAmount ),
			baseAmountDue: getFormattedCurrency(
				installment.amountDue + installment.gratuityPaid
			),
			expectedAmount: getFormattedCurrency( installment.amountDue ),
			amountPaid: getFormattedCurrency( installment.amountPaid ),
		} ) ),
		convenienceFeePaid: getFormattedCurrency(
			invoice.paymentInstallments.reduce( ( amount, current ) => {
				if (
					[
						'Pending',
						'FullyPaid',
						'FullyPaidPartiallyRefunded',
						'Refunded',
					].includes( current.status )
				) {
					return amount + current.convenienceFee;
				}
				return amount;
			}, 0 )
		),
		gratuityAmount: getFormattedCurrency( gratuityAmount ),
		discountAmount: getFormattedCurrency( discountAmount ),
		amountOutstanding: getFormattedCurrency( amountOutstanding ),
		amountRefunded: getFormattedCurrency(
			paymentInstallments.reduce( ( accumulator, { amountRefunded } ) => {
				return accumulator + amountRefunded;
			}, 0 )
		),
		amountDue: getFormattedCurrency(
			paymentInstallments.reduce(
				( accumulator, { status, amountDue, convenienceFee } ) => {
					if ( status !== 'Unpaid' ) return accumulator;
					return accumulator + amountDue + convenienceFee;
				},
				0
			)
		),
	};
};

export const convertInvoiceDates = ( invoice ) => {
	const localInvoice = invoice;
	localInvoice.sentAt = new Date( invoice.sentAt );
	localInvoice.paymentInstallments = localInvoice.paymentInstallments.map(
		( installment ) => {
			if ( installment.dueDate ) {
				installment.dueDate = new Date( installment.dueDate );
			}
			return installment;
		}
	);

	let invoiceDueDate =
		localInvoice.paymentInstallments[
			localInvoice.paymentInstallments.length - 1
		].dueDate || new Date();

	dueDateLoop: for (
		let i = 0;
		i < localInvoice.paymentInstallments.length;
		i++
	) {
		const { status, dueDate } = localInvoice.paymentInstallments[ i ];
		if ( status === 'Unpaid' ) {
			if ( dueDate ) {
				invoiceDueDate = dueDate;
			}
			break dueDateLoop;
		}
	}
	localInvoice.dueDate = invoiceDueDate;

	return localInvoice;
};

/**
 * Returns whether or not an invoice has unpaid payment installments.
 *
 * @param {Object} invoice
 *
 * @returns {Boolean}
 */
export const invoiceHasPayableInstallments = ( invoice ) => {
	if ( !invoice.paymentInstallments && process.env.NODE_ENV !== 'production' ) {
		console.warn(
			`Attempting to judge if invoice is payable with no payment installments to check: Invoice: ${ invoice.invoice }`
		);
	}

	const payableInstallments = [];

	for ( const installment of invoice.paymentInstallments ) {
		if (
			installment.status === 'Unpaid' ||
			installment.status === 'PartiallyPaid'
		) {
			payableInstallments.push( installment );
		}
	}

	return payableInstallments.length > 0;
};

/**
 * Returns whether an invoice has an unpaid installment with a scheduled payment date
 *
 * @param { Object } invoice
 */
export const invoiceHasAutoPay = ( invoice ) => {
	if ( !invoiceHasPayableInstallments( invoice ) ) {
		return false;
	}
	return !!invoice.paymentInstallments.find(
		( installment ) =>
			installment.status === 'Unpaid' && installment.scheduledPaymentDate
	);
};

/**
 * Convert an invoice object's fields into more useful data types. DateTime => Date, cents => dollars
 *
 * @param {object} invoice - Invoice object from the back-end.
 *
 * @returns {object} localInvoice
 */
export const convertInvoice = ( invoice ) => {
	let localInvoice = invoice;

	if ( localInvoice.lineItems ) {
		const invoiceToDollars = convertInvoiceToDollars( localInvoice );
		invoiceToDollars.lineItems.map( ( item ) => {
			if ( !item.rate && !item.quantity && item.amount ) {
				item.quantity = 1;
				item.rate = item.amount;
			}

			return item;
		} );
		localInvoice = invoiceToDollars;
	}

	localInvoice = convertInvoiceDates( localInvoice );
	localInvoice.hasPayableInstallments =
		invoiceHasPayableInstallments( localInvoice );
	localInvoice.autopay = invoiceHasAutoPay( localInvoice );

	return localInvoice;
};

/**
 * Convert a contract object's fields into more useful data types.
 *
 * @param {object} contract - Contract object from the back-end.
 *
 * @returns {object} localContract
 */
export const convertContract = ( contract ) => {
	const localContract = { ...contract };

	if ( localContract.dateSent )
		localContract.dateSent = new Date( localContract.dateSent );
	if ( localContract.dueDate )
		localContract.dueDate = new Date( localContract.dueDate );
	if ( localContract.eventDate )
		localContract.eventDate = new Date( localContract.eventDate );
	if ( localContract.contractSourceFile )
		localContract.filename = getFilename( localContract.contractSourceFile );

	return localContract;
};

/**
 * Flattens metadata from a stripe object into the object as normal properties.
 *
 * @param {Object} stripeObject - Object from stripe.
 * @param {Object[]} stripeObject.metadata - Objects with properties "Key" and "Value"
 *
 * @returns {Object} localStripeObject - Object with flattened metadata.
 */
export const flattenStripeMetadata = ( stripeObject ) => {
	if ( !stripeObject ) return null;

	/* using cloneDeep here in case the input is read-only, like it is with Apollo responses,
	so we can add values lower-down to "flatten" meta data */
	const localStripeObject = cloneDeep( stripeObject );

	if ( !localStripeObject || !localStripeObject.metadata )
		return localStripeObject;

	localStripeObject.metadata.forEach( ( { key, value } ) => {
		localStripeObject[ key ] = value;
	} );

	return localStripeObject;
};

/**
 * Decides which source to use for user state between app state and the cookies.
 *
 * @param {object} state - App state.
 * @param {object} ownProps - Component props
 *
 * @returns {SUPERADMIN | ORGUSER | CLIENTUSER | GUESTUSER | undefined} Logged in user object.
 */
export const setUserState = ( state, ownProps ) =>
	state.user && state.user.isLoggedIn
		? state.user
		: ownProps.user || state.user;

/**
 * Strips separators from a string.
 *
 * @param {String} str - A string
 *
 * @returns {String} The string without the separators
 */
export function stripSeparators( str ) {
	return str.replace( Regex.commonSeparators, '' );
}

/**
 * Performs a transformation on all primitive values within an array or object.
 *
 * @param {*} data
 * @param {function} transformFunction - A function which takes a primitive value and returns any type.
 *
 * @returns {*}
 */
export function recursivelyTransform( data, transformFunction ) {
	let transformedData = data;
	if ( Array.isArray( data ) ) {
		// transform every item in an array
		transformedData = data.map( ( item ) =>
			recursivelyTransform( item, transformFunction )
		);
	} else if ( typeof data === 'object' ) {
		if ( !( data instanceof File || data instanceof Date ) ) {
			for ( const field in data ) {
				// transform every field on an object
				transformedData[ field ] = recursivelyTransform(
					data[ field ],
					transformFunction
				);
			}
		}
	} else {
		// transform all primitive types
		transformedData = transformFunction( data );
	}

	return transformedData;
}

/**
 * Deletes null properties, empty strings, and empty objects within another object and returns a copy of the new object.
 *
 * @param { Object } object - The object to scrub
 *
 * @returns { Object } A copy of the object with its nulls and empties removed
 */
export function deleteEmpties( object ) {
	if ( Array.isArray( object ) ) {
		const newArray = object.map( ( entry ) => deleteEmpties( entry ) );
		return newArray;
	}
	const strippedObj = Object.keys( object )
		.filter( ( key ) => object[ key ] !== null && object[ key ] !== '' )
		.reduce(
			( newObject, key ) =>
				typeof object[ key ] === 'object'
					? { ...newObject, [ key ]: deleteEmpties( object[ key ] ) }
					: { ...newObject, [ key ]: object[ key ] },
			{}
		);
	for ( const key in strippedObj ) {
		if ( !strippedObj[ key ] || typeof strippedObj[ key ] !== 'object' ) continue;

		deleteEmpties( strippedObj[ key ] );

		if ( Object.keys( strippedObj[ key ] ).length === 0 ) {
			delete strippedObj[ key ];
		}
	}
	return strippedObj;
}

/**
 * Strips all surrounding white space in all strings within the data provided.
 *
 * @param {*} data
 *
 * @returns {*}
 */
export function stripAllSurroundingWhitespace( data ) {
	return recursivelyTransform( data, ( field ) => {
		if ( typeof field === 'string' ) {
			return field.trim();
		}

		return field;
	} );
}

/*
 * Field validations
 */
export const validateFields = ( fields ) => {
	/** @type { { email?: string, password?: string } } */
	const err = {};
	const processedFields = stripAllSurroundingWhitespace( fields );

	Object.entries( processedFields ).forEach( ( field ) => {
		// Emails:
		if ( field[ 0 ] == 'loginEmail' ) {
			if ( !field[ 1 ] ) err.email = 'Whoops! Please enter your email address.';
			else if ( !Regex.emailAddress.test( field[ 1 ].toLowerCase() ) )
				err.email =
					'Something isn’t right. Please double check your email format.';
		}

		// Passwords:
		else if ( field[ 0 ] == 'loginPassword' && !field[ 1 ] )
			err.password = 'Please enter your password';
	} );

	const res = {
		needsValidation: err,
		validated: Object.entries( err ).length === 0,
	};
	return res;
};

/**
 *
 * @param { array | string } testArrayOrString - a string or array of strings
 * to check if one of the following is included
 * @param { array } possibleIncludes an array of strings to confirm
 * if they are included in the above
 *
 * @returns { boolean }
 */
export const includesAnyOf = ( testArrayOrString, possibleIncludes ) => {
	let response = false;
	possibleIncludes.forEach( ( item ) => {
		if ( testArrayOrString.includes( item ) ) {
			response = true;
		}
	} );
	return response;
};

/**
 * Converts the values of social media inputs into the correct format for storage in DB
 *
 * @param {Object} socials - an object containing social platforms and values
 * @param { string | undefined | null } socials.facebook - the facebook field value
 * @param { string | undefined | null } socials.twitter - the twitter field value
 * @param { string | undefined | null } socials.pinterest - the pinterest field value
 * @param { string | undefined | null } socials.instagram - the instagram field value
 *
 * @returns { facebook: string, twitter: string, pinterest: string, instagram: string }
 */
export const getFormattedSocials = ( socials ) => {
	const formattedSocials = {};

	for ( const platform in socials ) {
		const urlPattern = Regex.generateRegexForSocialMediaPlatformUrl( platform );
		if ( socials[ platform ] ) {
			const handle = socials[ platform ].replace( urlPattern, '' );

			!handle
				? ( formattedSocials[ platform ] = '' )
				: ( formattedSocials[
					platform
				  ] = `https://www.${ platform }.com/${ handle }` );
		} else {
			formattedSocials[ platform ] = '';
		}
	}

	return formattedSocials;
};

/**
 * returns a legible concatenation of first and last name
 *
 * @param { String } firstName
 * @param { String } lastName
 * @param { Boolean } uppercase whether the result should be in caps
 *
 * @returns { String }
 */
export const composeFullName = ( firstName, lastName, uppercase = false ) => {
	let returnable = firstName || '';
	if ( firstName && lastName ) {
		returnable += ` ${ lastName }`;
	}
	if ( uppercase ) {
		returnable = returnable.toUpperCase();
	}
	return returnable;
};

/**
 * combines any number of arrays and removes all duplicates
 *
 * @param { any[] } arrays an array of arrays
 *
 * @returns { Array }
 */
export const combineAndDeDupeArrays = ( arrays ) => {
	if ( Array.isArray( arrays ) ) {
		let combined = [];
		combined = combined.concat( ...arrays );
		const set = new Set( combined );
		return [ ...set ];
	} else {
		throw new Error( `Expected ${ arrays } to be of type Array` );
	}
};

/**
 * applies console log interceptors to a specific axios instance
 *
 * @param { Object } axiosInstance - an instance of the Axios package created using Axios.create();
 */
export const applyRequestLogInterceptorsToAxiosInstance = ( axiosInstance ) => {
	axiosInstance.interceptors.request.use( ( req ) => {
		if ( typeof window !== 'undefined' && isDevelopment ) {
			console.log( 'req:', req.data );
		}
		return req;
	} );
	axiosInstance.interceptors.response.use( ( res ) => {
		if ( typeof window !== 'undefined' && isDevelopment ) {
			console.log( 'res:', res.data );
		}
		return res;
	} );
};

/**
 * Gets the appropriate names to use to address a *non-onboarded* client on a given event object.
 * This will return names from either an invitation or a guest invoice or contract
 *
 * @param { Contact } contact - A contact object.
 *
 * @returns {String[]} - [ first, last ]
 */
const getGuestUserNameFromContact = ( contact ) => {
	if ( contact?.firstName && contact?.lastName ) {
		return [ contact.firstName, contact.lastName ];
	}

	let first, last;
	const { invoices, invitation, contracts } = contact;

	if ( invoices || contracts ) {
		const documents = [ ...( invoices ? invoices : [] ), ...( contracts ? contracts : [] ), ];
		// filter out client invoices
		const guestDocuments = documents
			.filter(
				( document ) =>
					document.contact?.customer?.userType === 'GuestUser' &&
					document.contact?.firstName &&
					document.contact?.lastName
			)
			// sort by createdAt DESCENDING
			.sort( ( a, b ) => a.createdAt - b.createdAt );

		// check the most recent guest invoice if there is one
		if ( guestDocuments.length > 0 ) {
			const mostRecentDocument = guestDocuments[ 0 ];
			first = mostRecentDocument.contact.firstName;
			last = mostRecentDocument.contact.lastName;
			return [ first, last ];
		}
	}

	// check for invitation
	if ( invitation ) {
		first = invitation.recipientFirstName;
		last = invitation.recipientLastName;
		return [ first, last ];
	}
	if ( !invitation && contact.customer ) {
		if ( contact.customer.email ) return [ contact.customer.email, '' ];
		if ( contact.customer.guestUser?.user?.email )
			return [ contact.customer.guestUser.user.email, '' ];
		return contact.customer.email;
	}
};

/**
 * Accepts an array of contacts and updates any contact with a guest user
 * to include that guest user's first and last name.
 *
 * @param { Object[] } contacts - Contacts to update
 *
 * @returns { Object[] } An array of contacts updated with guest user names if possible.
 */
export const updateContactsWithGuestNames = ( contacts ) => {
	return contacts.map( ( contact ) => {
		if ( contact.customer?.guestUser ) {
			const [ first, last ] = getGuestUserNameFromContact( contact );
			contact.customer.guestUser.firstName = first;
			contact.customer.guestUser.lastName = last;
		}
		return contact;
	} );
};

/**
 * Checks to see whether an invoice history should be shown
 *
 * @param { String } createdDate - an ISO string representing the date the invoice was created
 *
 * @returns { Boolean }
 */
export const showInvoiceHistory = ( createdDate ) => {
	const invoiceCreatedDate = new Date( createdDate ).getTime();
	const cutoffDate = new Date( 'April 3, 2020' ).getTime();
	return invoiceCreatedDate > cutoffDate;
};

/**
 * Gets the current version of the app from the package file
 *
 * @returns { String } an app version, e.g "1.26.2"
 */
export const getVersion = () => {
	const { version } = require( '../../../package.json' );
	return version;
};

/**
 * Returns a formatted string representing the payment source's expiration date
 * if that exists, otherwise, the text 'N/A'
 *
 * @param { Record<string, any> } source a stripe object representing a payment method
 * @param { Int } source.exp_month the month the source expires
 * @param { Int } source.exp_year the year the source expires
 *
 * @returns { String }
 */
export const getSourceExpirationText = ( source ) => {
	const { exp_year, exp_month } = source;
	if ( exp_year && exp_month ) {
		return dayjs( new Date( exp_year, exp_month - 1, 1 ) ).format( 'MM.YYYY' );
	}
	return 'N/A';
};

/**
 * Adds an https protocol to a string, if none exists
 *
 * @param { String } website
 *
 * @returns { String | Null }
 */
export const formatWebsite = ( website ) => {
	if ( !website ) {
		return null;
	} // eslint-disable-next-line
	if (website.search(/^http[s]?\:\/\//) == -1) {
		website = 'https://' + website;
	}
	return website;
};

/**
 * Set default payment methods based on client user invoice or guest user invoice
 * to be used by react-select component
 * @param { Array } sources - Available payment methods from client user
 * @param { string } cardPaymentSelectorText
 * @return { Object } returns client user default payment sources or guest user default payment sources
 */
export const availablePaymentMethods = ( sources, cardPaymentSelectorText ) => {
	if ( sources.length !== 0 ) {
		return sources.map( ( source ) => {
			let type = source.bank_name ? source.bank_name : 'ACH';
			if ( source.object === 'card' ) type = source.brand;

			let label = `${ type } ${ source.last4 }`;
			if ( source.nickname ) label = `${ source.nickname } (${ source.last4 })`;

			if ( source.object === 'card' ) label = label += cardPaymentSelectorText;

			return {
				value: source.id,
				label,
			};
		} );
	}

	return [
		{
			value: 'cc',
			label: `Credit Card${ cardPaymentSelectorText }`,
		},
		{
			value: 'ach',
			label: 'ACH/BANK',
		},
	];
};

/**
 * Set default payment method based on client user invoice or guest user invoice
 * to be used by react-select component
 * @param  { Array } sources - Available payment methods from client user
 * @param  { String } defaultSource - ID of client payment method
 * @return { Object } returns client user default payment source or guest user default payment source
 */
export const currentPaymentMethod = ( sources, defaultSource, form ) => {
	const source = sources.find( ( key ) => key.id === defaultSource );

	if ( source ) {
		const { nickname, bank_name, brand } = source;
		const label = `${ nickname || bank_name || brand } (${ source.last4 })`;
		return {
			value: source.id,
			label,
		};
	}
	if ( !form?.method ) {
		return { value: null };
	}
	return {
		value: 'cc',
		label: 'Credit Card',
	};
};

/**
 * @param { {
 *   customer: { guestUser?: object | null, clientUser?: { lastNameOne: string, lastNameTwo: string } | null }
 * } | undefined } contact - a contact
 * @param { string | undefined } lastName - contract or invoice
 * @returns { string } a name
 */
export const getRecipientNameForDocumentTitle = ( contact, lastName ) => {
	// really, why else would this function exist?
	if ( lastName ) {
		return lastName;
	}

	// If we have no contact or contact.customer, return what we DO know at this point
	if ( !contact ) return lastName || '';

	if ( contact.lastName ) {
		return contact.lastName;
	}

	if ( !contact.customer ) return lastName || '';

	// If the customer has onboarded, show that info
	if ( contact.customer.clientUser ) {
		return `${ contact.customer.clientUser.lastNameOne } - ${ contact.customer.clientUser.lastNameTwo }`;
	}

	// If the customer has NOT onboarded, show THAT info
	if ( contact.customer.guestUser ) {
		const [ , last ] = getGuestUserNameFromContact( contact );
		return last;
	}

	// We probably can't get here, but just to cover our bases also return what we know here
	return lastName || '';
};

/**
 *
 * @param { boolean } [allDocuments]
 * @param { Record<string, any> } [contact]
 * @param { Record<string, any> } [user]
 * @returns { Record<string, any> & { OR: Array<any> } }
 */
export const getDocumentsQuery = ( allDocuments = false, contact, user ) => {
	const where = { OR: [] };

	if ( isOrgUser( user ) ) {
		where.OR.push( {
			ownerType: 'Organization',
			orgUser: { id: user.id },
			OR: [ { status: 'Pending' }, { status: 'Available' } ],
		} );

		if ( contact ) {
			where.clientUser = { id: contact.customer.clientUser.id };
		}

		if (
			isAssignedPlannerOrAdmin( user, contact.customer.clientUser ) &&
			contact?.customer?.clientUser?.plannerOrgHasDocumentsPermissions &&
			allDocuments
		) {
			where.OR.push( {
				status: 'Available',
				sharedWithCustomer: true,
			} );
		}

		if ( user.isAdmin ) {
			where.OR.push( {
				ownerType: 'Organization',
				organization: { id: user.organization.id },
				status: 'Available',
			} );
		}

		if ( allDocuments && contact ) {
			where.OR.push( {
				status: 'Available',
				sharedWithContacts: {
					some: { id: contact.id },
				},
			} );
		}
	}

	if ( isClient( user ) ) {
		where.clientUser = { id: user.id };

		where.OR.push( {
			sharedWithCustomer: true,
			status: 'Available',
		} );
	}

	return where;
};

/**
 * @function getDocumentOwnerName
 * Returns the name of the owner of the document, based on its ownerType
 *
 * @param { {
 *   ownerType: DocumentOwnerType,
 *   organization?: { name: string } | null,
 *   customer?: {
 *     clientUser?: { firstNameOne?: string | null, lastNameOne?: string | null } | null,
 *     orgUser?: { firstName: string, lastName: string } | null,
 *     email: string
 *   } | null
 * } } [document]
 * @returns { string } the name of the document's owner
 */
export const getDocumentOwnerName = ( document ) => {
	if ( !document ) {
		return 'Owner Unavailable';
	}
	if ( document && document.ownerType === DocumentOwnerType.Organization ) {
		return document.organization.name;
	}

	return getUsername( document.customer );
};

/**
 * Given a document with a fileType this function will return the appropriate icon type for it.
 *
 * @param { object } document
 * @param { string } [document.fileType]
 *
 * @returns { string }
 */
export const getIconTypeForDocument = ( document, isMuiTableIcon = false ) => {
	if ( !document || !document.fileType ) {
		return isMuiTableIcon ? 'file-folder' : 'folder';
	}
	switch ( document.fileType ) {
		case 'AVI':
		case 'MP4':
		case 'MPEG':
		case 'WEBM':
			return isMuiTableIcon ? 'file-video' : 'video';
		case 'BMP':
		case 'GIF':
		case 'JPG':
		case 'PNG':
		case 'SVG':
		case 'TIF':
		case 'JPEG':
			return isMuiTableIcon ? 'file-image' : 'picture';
		case 'MP3':
			return isMuiTableIcon ? 'file-audio' : 'mp3';
		case 'CSV':
		case 'XLS':
		case 'XLSX':
			return isMuiTableIcon ? 'file-table' : 'sheet';
		case 'DOC':
		case 'DOCX':
			return isMuiTableIcon ? 'file-text' : 'text';
		case 'PPT':
		case 'PPTX':
		case 'ODP':
			return isMuiTableIcon ? 'file-presentation' : 'slide';
		case 'PDF':
			return isMuiTableIcon ? 'file-pdf' : 'pdf';
		default:
			return 'file';
	}
};

/**
 * Given a document with a fileSource this function will return the appropriate icon type for it.
 *
 * @param { object } document
 * @param { string } document.fileSource
 *
 * @returns { string }
 */
export const getDocumentSourceLabel = ( document ) => {
	switch ( document.fileSource ) {
		case 'LocalStorage':
			return 'Upload';
		case 'GoogleDrive':
			return 'Google drive';
		default:
			return '-';
	}
};

/**
 * Uploads a document to s3.
 * If upload is unsuccessful, it will attempt 2 additional times.
 *
 * @param { {
 *   uploadURL: string,
 *   file: File,
 *   headers: Record<string, any>,
 *   failCounter?: number
 * } } args
 */

export const uploadDocument = async ( {
	uploadURL,
	file,
	headers,
	failCounter = 0,
} ) => {
	const axiosInstance = axios.create();
	applyRequestLogInterceptorsToAxiosInstance( axiosInstance );

	const uploadResponse = await axiosInstance.put( uploadURL, file, { headers } );
	if ( uploadResponse.status !== 200 ) {
		if ( failCounter < 3 ) {
			failCounter++;
			uploadDocument( { uploadURL, file, axiosInstance, failCounter } );
		} else {
			return { failure: { message: 'Document has failed to upload' } };
		}
	} else {
		return { success: { message: 'Document has uploaded' } };
	}
};

/**
 *
 * @param { array } featureFlags a possibly empty array of feature flags
 * @param { string } term - the name of the flag sought
 * @returns { boolean } whether a flag of that name exists & is active
 */
export const flagExistsAndIsActive = ( featureFlags, term ) => {
	if ( !featureFlags || !featureFlags.length || !term ) {
		return false;
	}
	return featureFlags.some(
		( flag ) => flag?.category === term && flag?.active === true
	);
};

/**
 *
 * @param { string } creationDate
 * @returns the count of days between two objects
 */
export const getRemainingDaysInTrial = ( creationDate ) => {
	const endOfTrial = getEndOfTrial( creationDate );
	return endOfTrial.diff( dayjs(), 'day' );
};

/**
 * determines appropriate extension for a google download file type
 *
 * @param { string } mimeType
 * @returns { string } extension
 */
export const getFileExtensionFromMimeType = ( mimeType ) => {
	switch ( mimeType ) {
		case 'video/x-msvideo':
			return 'avi';
		case 'image/bmp':
			return 'bmp';
		case 'text/csv':
			return 'csv';
		case 'application/msword':
			return 'doc';
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return 'docx';
		case 'image/gif':
			return 'gif';
		case 'image/jpeg':
			return 'jpg';
		case 'audio/mpeg':
			return 'mp3';
		case 'video/mp4':
			return 'mp4';
		case 'video/mpeg':
			return 'mpeg';
		case 'application/vnd.oasis.opendocument.presentation':
			return 'odp';
		case 'image/png':
			return 'png';
		case 'application/pdf':
			return 'pdf';
		case 'application/vnd.ms-powerpoint':
			return 'ppt';
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			return 'pptx';
		case 'image/svg+xml':
			return 'svg';
		case 'image/tiff':
			return 'tif';
		case 'video/webm':
			return 'webm';
		case 'application/vnd.ms-excel':
			return 'xls';
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return 'xlsx';
		default:
			return false;
	}
};

/**
 * Determines the appropriate conversion type for a google native file
 *
 * @param { string } mimeType
 * @returns { string } mimeType
 */
export const getConversionFileTypeForGoogleExport = ( mimeType ) => {
	switch ( mimeType ) {
		case 'application/vnd.google-apps.spreadsheet':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'application/vnd.google-apps.document':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		case 'application/vnd.google-apps.presentation':
			return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		default:
			return false;
	}
};

/**
 * Determine the plan that an organization has
 *
 * @param { object } organization
 * @returns { Promise<string> } plan
 */
export const getOrganizationPlan = async ( organization ) => {
	if ( organization && !organization.subscription ) {
		organization.subscription = await API.getVendorStripeSubscription();
	}

	if ( hasActiveSubscription( { organization }, true ) ) {
		if ( organization?.subscription?.status === 'Trialing' ) {
			// Trialing
			return 'Trialing';
		}

		// Annual
		if ( organization?.subscription?.subscriptionPlan?.period === 'Annual' ) {
			return 'Annual';
		}

		// Monthly
		if ( organization?.subscription?.subscriptionPlan?.period === 'Monthly' ) {
			return 'Monthly';
		}
	}

	// Canceled
	if ( organization?.subscription?.status === 'Canceled' ) {
		return 'Canceled';
	}

	// None
	return 'None';
};

/**
 * Supports plaid accounts for non production environments
 *
 *
 * @param { import('react-plaid-link').PlaidLinkOnSuccessMetadata } metadata
 * @param { string } metadata.account_id
 * @param { array } metadata.accounts
 * @returns { string | null }
 */
export const getPlaidAccountFromMetadata = ( metadata ) => {
	if ( metadata.account_id ) {
		// this should be the case when working with a plaid account
		// that is enabled for production
		return metadata.account_id;
	} else if (
		// but if you're not using such an account
		// oauth won't work
		// so we need to use the generic plaid accounts
		// see here: https://plaid.com/docs/link/oauth/#request-production-access-from-plaid
		metadata.accounts &&
		metadata.accounts.length &&
		metadata.accounts.some( ( account ) => account.name === 'Plaid Checking' )
	) {
		const { id } = metadata.accounts.find(
			( account ) => account.name === 'Plaid Checking'
		);
		return id;
	}
	return null;
};

// if this doesn't do the job, there's always https://www.npmjs.com/package/camelize
export const camelize = ( str ) =>
	str
		.replace( /(?:^\w|[A-Z]|\b\w)/g, ( word, index ) =>
			index === 0 ? word.toLowerCase() : word.toUpperCase()
		)
		.replace( /\s+/g, '' );

/**
 * requests a stripe connect onboarding url and redirects the user to it
 *
 * @param { boolean } hasStripeVerificationsNeeds
 * @returns { Promise } redirects to stripe hosted form
 */
export const goToConnectOnboarding = async ( hasStripeVerificationsNeeds ) => {
	const return_url = `${ window.location.origin }/wallet?returnFromStripe=true`;
	const type = hasStripeVerificationsNeeds
		? 'account_onboarding'
		: 'account_update';

	const res = await API.getConnectOnboardingUrl( {
		type,
		return_url,
	} );
	if ( res.url ) {
		window.location = res.url;
	}
};

/**
 * Utility for InvoiceView and ContractView relating to the Client Permissions Modal rules
 * @param {{ id: string, user: { userType: string } | undefined, assignedPlanner?: Object | undefined }} user
 * @param {{
 *   id: string,
 *   services?: { id: string; name: string; }[] | null | undefined
 * } | undefined | null } organization
 * @returns
 */
export const userShouldSeeClientPlannerPermissionsModal = async (
	user,
	organization
) => {
	const NoClientOrganizationHistory = Object.freeze( {
		hasSignedContracts: false,
		hasPayedInvoices: false,
	} );
	/**
	 * Helper function initially for use in userShouldSeeClientPlannerPermissionsModal
	 * @param {string} userToFetchForId
	 * @param {string} organizationId
	 * @returns {Promise<{ hasSignedContracts: boolean, hasPayedInvoices: boolean }>}
	 */
	const getClientOrganizationHistory = async (
		userToFetchForId,
		organizationId
	) => {
		const contact = {
			customer: { id: userToFetchForId },
			vendor: { id: organizationId },
		};
		const [
			// eslint-disable-next-line array-element-newline
			contractsResponse,
			invoicesResponse,
		] = await Promise.all( [
			API.getContractsForCustomer( {
				userToFetchFor: { user: { id: userToFetchForId } },
				vendor: { id: organizationId },
			} ),
			API.getInvoicesWhere( { contact } ),
		] );
		// make sure to shield against contracts/invoices not existing and if they are nulls as well
		const hasSignedContracts =
			'contracts' in contractsResponse &&
			Array.isArray( contractsResponse.contracts ) &&
			contractsResponse.contracts.some(
				( contract ) => contract.status === 'Signed'
			);
		const hasPayedInvoices =
			'invoices' in invoicesResponse &&
			Array.isArray( invoicesResponse.invoices ) &&
			invoicesResponse.invoices.some( ( invoice ) =>
				[
					'FullyPaidPartiallyRefunded',
					'PartiallyPaid',
					'FullyPaid'
				].includes(
					invoice.status
				)
			);
		return {
			hasSignedContracts,
			hasPayedInvoices,
		};
	};
	if ( user && isClient( user ) && !user.assignedPlanner ) {
		const clientHasOrganizationHistory = organization
			? await getClientOrganizationHistory( user.user.id, organization.id )
			: NoClientOrganizationHistory;
		const organizationIsPlanner =
			organization &&
			Array.isArray( organization.services ) &&
			organization.services.some( ( obj ) => obj.name === 'Event Planner' );
		return (
			organizationIsPlanner &&
			!clientHasOrganizationHistory.hasSignedContracts &&
			!clientHasOrganizationHistory.hasPayedInvoices
		);
	}
	return false;
};

/** @typedef {{ facebook?: string, instagram?: string, pinterest?: string, twitter?: string }} SOCIAL */
/** @typedef {{ addressLine1?: string, addressLine2?: string, city?: string, state?: string, postalCode?: string }} CLIENTADDRESS */
/** @typedef {{ venue?: string, weddingDate?: Date| string, phone?: string, website?: string } & SOCIAL & CLIENTADDRESS} CLIENTLINKS */
/** @typedef {{ id: string, name: string, services: string[], defaultOrgUser: { id: string }, adminUsers: ORGUSER[] }} ORGANIZATION */
/** @typedef {{ id: string, email: string }} GUESTUSER */
/** @typedef {{ id: string, user: { userType: 'SuperAdmin', email: string, id: string, isVerified: boolean }}} SUPERADMINUSER */
/** @typedef {{ id: string, user: { userType: 'OrgUser', email: string, id: string, isVerified: boolean }, organization: ORGANIZATION, contacts: import('../API/contacts/index').CONTACT[] }} ORGUSER */
/** @typedef {{ id: string, user: { userType: 'ClientUser', email: string, id: string, isVerified: boolean }, assignedPlanner?: ORGUSER } & CLIENTLINKS} CLIENTUSER */
/** @typedef {USER_FLATTENED & GUESTUSER} USER_FLATTENED_GUESTUSER */
/** @typedef {USER_FLATTENED & SUPERADMINUSER} USER_FLATTENED_SUPERADMINUSER */
/** @typedef {USER_FLATTENED & ORGUSER} USER_FLATTENED_ORGUSER */
/** @typedef {USER_FLATTENED & CLIENTUSER} USER_FLATTENED_CLIENTUSER */
/** @typedef {USER_FLATTENED_GUESTUSER | USER_FLATTENED_SUPERADMINUSER | USER_FLATTENED_ORGUSER | USER_FLATTENED_CLIENTUSER} USER_FLATTENED_POSSIBILITIES */
/**
 * @param { IncomingMessage } req
 * @returns {Promise<USER_FLATTENED_POSSIBILITIES | undefined>}
 */
export const getUserServerSide = async ( req ) => {
	API.setAuthToken( '' ); // first "blank" out the token, to cover when it's cached
	const cookieString = getCookieString( req );
	if ( cookieString ) {
		const [ token, userCookie ] = getInfoFromCookie( cookieString );
		if ( token ) {
			API.setAuthToken( token );
		}
		if ( typeof userCookie === 'object' && userCookie.email ) {
			const response = await API.getAuthedUser( userCookie.email );
			if ( !( 'errors' in response ) ) {
				const { user, groups } = response;
				return flattenUser( user, groups );
			}
		}
	}
};

/**
 * Helper to iterate over anything and return a copy with strings truncated to specified length
 * There is a maxDepth param to put a backstop up against run-away recursion
 * @param {any} obj
 * @param {{ length?: number, maxDepth?: number }} [options]
 */
export const recurseAnyTruncateStrings = ( obj, options ) => {
	// Set up defaults
	const length = options?.length || 255;
	const maxDepth = options?.maxDepth || 255;
	const childOptions = { length, maxDepth: maxDepth - 1 };
	// start checking input types
	if ( typeof obj === 'string' ) {
		// if its a string, truncate it
		return obj.substring( 0, length - 1 );
	}
	// If its not something we can iterate over, return it
	if (
		typeof obj === 'undefined' ||
		obj === null ||
		!( Array.isArray( obj ) || typeof obj === 'object' )
	) {
		// if its not iterable, like a number, then just return it
		return obj;
	}
	return Object.entries( obj ).reduce(
		( acc, [ key, val ] ) => {
			// we don't want to try and process native JS things
			if ( !Array.isArray( obj ) && Object.prototype.hasOwnProperty( obj, key ) ) {
				return { ...acc, [ key ]: val };
			}
			if ( maxDepth === 0 ) {
				// Have we descended too far?
				return acc;
			} else {
				return Array.isArray( obj )
					? [ ...acc, recurseAnyTruncateStrings( val, childOptions ) ]
					: { ...acc, [ key ]: recurseAnyTruncateStrings( val, childOptions ) };
			}
		},
		Array.isArray( obj ) ? [] : {}
	);
};

/**
 * Enum equivalent for selecting the preferred appearance for a contact's avatar
 */
export const PreferredAppearances = Object.freeze( {
	initials: 'initials',
	image: 'image',
} );

/**
 * Helper to determine, if a contact's status is active,
 * if an image should be shown, or if not the contact's initials.
 * @param { Object } contact
 */
export const preferImageInProfileImageComponent = ( contact ) =>
	contact
		? contact?.hasCustomerIntent
			? PreferredAppearances.image
			: PreferredAppearances.initials
		: undefined;

/**
 * Determines whether to show a date tooltip
 *
 * @param { { customer: { clientUser?: { weddingDate?: string | Date | null } | null }, eventDate?: string | Date | null, status: ContactStatus, hasCustomerIntent: boolean } } contact
 */
export const shouldDisplayEventDateTooltip = ( contact ) => {
	const {
		customer: { clientUser },
		eventDate,
		hasCustomerIntent,
	} = contact;
	const weddingDate = clientUser ? clientUser.weddingDate : null;

	if (
		!hasCustomerIntent ||
		!weddingDate ||
		( hasCustomerIntent &&
			weddingDate &&
			getFormattedDate( weddingDate ) === getFormattedDate( eventDate ) )
	) {
		return false;
	}
	return true;
};
